<template>
  <b-modal
    id="virtual-phone-setup-modal-step-0"
    :hide-header="true"
    :hide-footer="true"
    no-close-on-backdrop
    dialog-class="virtual-phone-setup-dialog"
    body-class="phone-setup-modal-body"
    size="lg"
    footer-class="justify-content-center"
  >
    <div>
      <div>
        <div v-if="waitingOnPhones" class="pb-3">
          <b-row align-h="center" class="pb-0 mb-0">
            <p>Searching our labyrinth for your phones...</p>
          </b-row>
          <ct-centered-spinner />
        </div>
        <div v-else class="virtual-phone-mobile-flex-col">
          <div>
            <p>Let's set up your new phone line! Click <strong>Next</strong> to begin.</p>
          </div>
          <b-row class="virtual-phone-mobile-flex-col" align-h="end">
            <b-button
              class="previous-button mr-1"
              variant="info"
              size="lg"
              @click="closeModal"
            >
              Remind Me Later
            </b-button>
            <b-button
              class="next-button"
              variant="info"
              size="lg"
              @click="next"
            >
              Next
            </b-button>
          </b-row>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'

export default {
  name: "Step0",

  components: { CtCenteredSpinner },
  props: {
    vm: Object,
  },
  data(){
    return {
      phones: [],
      waitingOnPhones: true,
    }
  },

  computed: {
    ...mapGetters('virtualPhones', [
      'selectedAvailableNumber',
      'availableNumbers',
      'phoneName',
      'forwardToNumber',
      'forwardAs',
      'forwardType',
      'virtualPhonesToConfigure',
    ]),
    noVirtualPhonesToConfigure() {
      return this.virtualPhonesToConfigure.length < 1
    },
  },

  async mounted() {
    this.waitingOnPhones = false
    await this.setVirtualPhoneToConfigure()
  },

  methods: {
    ...mapActions('virtualPhones', ['loadVirtualPhones', 'setVirtualPhonesToConfigure', 'setVirtualPhoneToConfigure', 'setVirtualPhoneConfigurationInProgress']),

    async next(){
      await this.setVirtualPhoneConfigurationInProgress(true)
      this.$parent.$bvModal.show('virtual-phone-setup-modal')
      this.$parent.$bvModal.hide('virtual-phone-setup-modal-step-0')
    },
    async closeModal() {
      await this.setVirtualPhoneConfigurationInProgress(false)
      this.$parent.$bvModal.hide('virtual-phone-setup-modal-step-0')
    },
  },
}
</script>
<style lang="scss">
  @media only screen and (max-width: 660px) {
    .virtual-phone-mobile-flex-col {
      width: 100% !important;

      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      row-gap: 1.0em;
    }
  }
</style>
