var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "wordpress-change-password-modal",
    attrs: {
      id: "wordpress-change-password-modal",
      "no-close-on-backdrop": "",
      centered: "",
      title: "Change Password",
    },
    on: { hide: _vm.clearPasswords },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _vm.loading
              ? _c("ct-centered-spinner")
              : _c(
                  "b-container",
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Password", "label-for": "password" } },
                      [
                        _c("validation-provider", {
                          attrs: { name: "password", rules: "required|min:8" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ validate, errors }) {
                                return [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "password",
                                          type: _vm.showPassword
                                            ? "text"
                                            : "password",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.validateField(
                                              validate,
                                              "isPasswordValid"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function ($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        { staticClass: "password-visibility" },
                                        [
                                          _c("fa-icon", {
                                            staticStyle: { cursor: "pointer" },
                                            attrs: {
                                              icon: _vm.showPassword
                                                ? "eye"
                                                : "eye-slash",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleField(
                                                  "showPassword"
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "text-danger small" },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            errors.length > 0 ? errors[0] : ""
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Confirm Password",
                          "label-for": "password",
                        },
                      },
                      [
                        _c("validation-provider", {
                          attrs: {
                            name: "password",
                            rules: `required|passwordConfirmationMatches:${_vm.password}`,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ validate, errors }) {
                                return [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "password",
                                          type: _vm.showPasswordConfirmation
                                            ? "text"
                                            : "password",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.validateField(
                                              validate,
                                              "isPasswordConfirmationValid"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.passwordConfirmation,
                                          callback: function ($$v) {
                                            _vm.passwordConfirmation = $$v
                                          },
                                          expression: "passwordConfirmation",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        { staticClass: "password-visibility" },
                                        [
                                          _c("fa-icon", {
                                            staticStyle: { cursor: "pointer" },
                                            attrs: {
                                              icon: _vm.showPasswordConfirmation
                                                ? "eye"
                                                : "eye-slash",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleField(
                                                  "showPasswordConfirmation"
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "text-danger small" },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            errors.length > 0 ? errors[0] : ""
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "b-button",
              {
                attrs: {
                  variant: "primary",
                  block: "",
                  disabled:
                    !_vm.isPasswordValid ||
                    _vm.loading ||
                    !_vm.isPasswordConfirmationValid,
                },
                on: { click: _vm.confirmWordpressChangePassword },
              },
              [_vm._v("\n      Update Password\n    ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }