var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("span", [_vm._v("Vehicle Registrations")]),
          _vm.loaded
            ? _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.add-vehicle-registration-modal",
                      modifiers: { "add-vehicle-registration-modal": true },
                    },
                  ],
                  attrs: {
                    variant: "primary",
                    "aria-label": "Add Vehicle link",
                  },
                },
                [_c("span", [_vm._v("+")]), _vm._v(" Vehicle\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container-fluid pt-4 ml-0 pl-0 pr-0" },
        [
          _vm.loaded && _vm.vehicleRegistrations <= 0
            ? _c("p", { staticClass: "text-center" }, [
                _vm._v(
                  '\n      Currently, there are no vehicles on record. Click "Add New Vehicle" to register one.\n    '
                ),
              ])
            : _vm._e(),
          _vm.loaded && _vm.vehicleRegistrations
            ? _c(
                "b-tabs",
                {
                  attrs: { pills: "" },
                  model: {
                    value: _vm.currentTab,
                    callback: function ($$v) {
                      _vm.currentTab = $$v
                    },
                    expression: "currentTab",
                  },
                },
                [
                  _vm.currentTab != 0
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "mb-3" }),
                          _vm.loaded
                            ? _c("b-form-input", {
                                staticClass: "mb-3",
                                attrs: { placeholder: "Search vehicles" },
                                model: {
                                  value: _vm.searchQuery,
                                  callback: function ($$v) {
                                    _vm.searchQuery = $$v
                                  },
                                  expression: "searchQuery",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-tab",
                    {
                      key: "pending_orders",
                      attrs: { title: "Pending Orders" },
                    },
                    [_c("vehicle-orders")],
                    1
                  ),
                  _vm._l(_vm.filteredVehicles, function (vehicles) {
                    return _c(
                      "b-tab",
                      { key: vehicles.title, attrs: { title: vehicles.title } },
                      [
                        _c("vehicle-ct-table-renderer", {
                          attrs: {
                            loaded: _vm.loaded,
                            items: vehicles.items,
                            fields: vehicles.fields,
                            "can-renew": vehicles.canRenew,
                          },
                          on: {
                            load: _vm.load,
                            editVehicle: _vm.editVehicle,
                            updateCompleted: _vm.updateCompleted,
                            vehicleRegistrationCompleted:
                              _vm.vehicleRegistrationCompleted,
                            removeVehicle: _vm.removeVehicle,
                            removeCompletedVehicle: _vm.removeCompletedVehicle,
                            updateRemindMe: _vm.updateRemindMe,
                            updateVehicleFailed: _vm.updateVehicleFailed,
                            addService: _vm.addService,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c("add-vehicle-services-modal", {
        attrs: { vehicle: _vm.selectedVehicleToAddService },
      }),
      _c("edit-vehicle-registration-modal", {
        attrs: { vehicle: _vm.selectedVehicleToEdit },
        on: {
          updateComplete: _vm.updateCompleted,
          updateVehicleFailed: _vm.updateVehicleFailed,
        },
      }),
      _c("add-vehicle-registration-modal", {
        on: { completed: _vm.vehicleRegistrationCompleted },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }