import {
  state as BaseState,
  getters as BaseGetters,
  actions as BaseActions,
  mutations as BaseMutations,
} from '@/store/base'

const STATE = {
  ...BaseState,
  namespace: 'accountWhitelistedIp',
  all: [],
}

const GETTERS = {
  ...BaseGetters,
}

const ACTIONS = {
  ...BaseActions,
}

const MUTATIONS = {
  ...BaseMutations,
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
