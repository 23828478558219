<template>
  <b-modal
    v-show="!!selectedDomainRecord"
    id="domain-record-delete-modal"
    ref="domain-record-delete-modal"
    title="Delete Record"
    centered
    hide-header-close
    size="md"
  >
    <ct-centered-spinner v-if="loading" />
    <div v-else v-html="modalText" />
    <template v-slot:modal-footer>
      <b-button variant="default" aria-label="cancel button" @click="cancel">
        Cancel
      </b-button>
      <b-button variant="primary" aria-label="delete button" @click="confirmDelete">
        Delete
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CtCenteredSpinner from '../shared/CtCenteredSpinner'
import { makeToastMixin } from '@/mixins/makeToastMixin.js'
import * as DOMPurify from 'dompurify'

export default {
  name: 'DomainRecordDeleteModal',
  components: {
    CtCenteredSpinner,
  },
  mixins: [makeToastMixin],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
      'selectedDomainRecord',
      'isCpanelHostingAccount',
    ]),
    type() {
      return this.selectedDomainRecord?.type
    },
    title() {
      return this.type === 'nameserver' ?
        'Delete DNS Record' :
        'Delete Nameserver'
    },
    modalText() {
      const text = this.type === 'nameserver' ?
        '<p>Delete nameserver?</p>' :
        '<p><strong>You are about to delete this record.</strong></p><p>We will delete the DNS records from your zone' +
          'file immediately. Most DNS changes take effect within an hour but could take up to 48 ' +
          'hours to update globally.</p>'

      return DOMPurify.sanitize(text, {
        ALLOWED_TAGS: ['p', 'strong'],
      })
    },
  },
  methods: {
    ...mapActions('domains', [
      'deleteDnsRecord',
      'deleteDomainNameserver',
      'setSelectedDomainRecord',
      'fetchDnsRecords',
    ]),
    cancel() {
      this.$bvModal.hide('domain-record-delete-modal')
      this.setSelectedDomainRecord({ record: null })
    },
    async confirmDelete() {
      this.loading = true

      this.type === 'nameserver' ?
        await this.deleteNameserver() :
        await this.deleteRecord()

      this.$bvModal.hide('domain-record-delete-modal')
      this.loading = false
    },
    async deleteRecord() {
      await this.deleteDnsRecord({
        domain: this.domain,
        index: this.domain.records.findIndex((record) => record.name === this.selectedDomainRecord.name && record.type === this.selectedDomainRecord.type && record.data === this.selectedDomainRecord.data),
      })

      this.editMode = false
    },
    async deleteNameserver() {
      await this.deleteDomainNameserver({
        domainId: this.domain.id,
        nameserver: this.selectedDomainRecord.value,
      })
      this.editMode = false
      if(this.isCpanelHostingAccount) {
        this.successToast('Success', 'Deleted nameserver record')
      }
    },
  },
}
</script>
