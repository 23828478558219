<template>
  <div>
    <ct-table
      v-if="sortedKeys?.length"
      :is-paginated="false"
      :fields="accessKeysDefinition.columns"
      :items="sortedKeys"
      :loaded="true"
      :table-definition="accessKeysDefinition"
    >
      <template v-slot:cell(description)="row">
        {{ row.item.description }}
      </template>
      <template v-slot:cell(enabled)="row">
        <fa-icon v-if="!row.item.deletedAt" icon="check" />
      </template>
      <template v-slot:cell(accessKey)="row">
        <span style="word-break: break-all">
          {{ row.item.accessKey }}
          <b-button
            class="p-0 pl-2"
            variant="link"
            size="sm"
            aria-label="Copy to Clipboard"
            @click="copyToClipboard(row.item.accessKey, null, successToast, errorToast)"
          >
            <copy-icon />
          </b-button>
        </span>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          variant="link"
          class="m-0 pl-0 pt-0 pb-0"
          aria-label="Edit button"
          @click="updateAccessKey(row.item)"
        >
          <fa-icon icon="edit" />
          Edit
        </b-button>
        <b-button
          variant="link"
          class="danger m-0 pr-0 pt-0 pb-0"
          aria-label="Delete button"
          @click="destroyAccessKey(row.item.id)"
        >
          <fa-icon icon="trash" />
          Delete
        </b-button>
      </template>
    </ct-table>
    <div v-if="!sortedKeys?.length" class="text-center">
      <api-management-empty-state
        title="There are no api keys"
        text="Click the button below to create your first API key."
        :cta-clicked="createAccessKey"
        cta-text="Create API Key"
      />
    </div>
  </div>
</template>

<script>
import { copyClipboardMixin } from '@/mixins/copyClipboardMixin'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { formatDateString } from '@/common/modules/formatters'

export default {
  name: 'AccountAccessKeyList',
  components: {
    ApiManagementEmptyState: () => import('@/pages/APIManagement/APIManagementEmptyState.vue'),
    CopyIcon: () => import('@images/ui/copy-icon.svg'),
    CtTable: () => import('@/components/shared/CtTable.vue'),
  },
  mixins: [copyClipboardMixin, makeToastMixin],
  props: {
    createAccessKey: { type: Function, required: true },
    destroyAccessKey: { type: Function, required: true },
    updateAccessKey: { type: Function, required: true },
    accountAccessKeys: { type: Array, required: true },
  },
  computed: {
    accessKeysDefinition() {
      return {
        columns: [
          { key: 'description' },
          { key: 'enabled' },
          { key: 'accessKey' },
          { key: 'createdAt', formatter: value => formatDateString(value) },
          { key: 'actions', selectable: true, label: '', headerTitle: 'Actions' },
        ],
      }
    },
    sortedKeys() {
      return (this.accountAccessKeys || []).sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
    },
  },
}
</script>

<style scoped lang="scss">
@import 'api-management-shared-styles';
</style>
