var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("ct-centered-spinner")
    : _c(
        "div",
        [
          _vm.showDomainSearch
            ? _c(
                "div",
                { staticClass: "domain-choice-container" },
                [
                  _vm.domainSuggestions.length
                    ? _c("div", { staticClass: "domain-suggestions" }, [
                        _c("div", { staticClass: "domain-message" }, [
                          _c("p", [
                            _vm._v(
                              "\n            Based on your company name, we’ve scouted the web for available domains and provided a suggestion.\n            Feel free to search yourself or browse some additional options until you find the perfect match.\n          "
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _c("domain-search", {
                    staticClass: "section-margin",
                    attrs: {
                      bus: _vm.bus,
                      "domain-suggestions": _vm.domainSuggestions,
                    },
                    on: {
                      "domain-unavailable": function ($event) {
                        _vm.domainUnavailable = true
                      },
                      "domain-available": function ($event) {
                        _vm.domainUnavailable = false
                      },
                    },
                  }),
                  _c(
                    "div",
                    [
                      _vm.domainUnavailable
                        ? _c("div", { staticClass: "section-margin" }, [
                            _c("span", { staticClass: "inform-text" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.text.unavailableDomain) +
                                  "\n          "
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "unavailable-name-display-container",
                              },
                              [
                                _c("span", { staticClass: "searched-result" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.domainSearch) +
                                      "\n            "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "false-button-container" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "false-button" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.text.fakeButton) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      !_vm.domainAdminSwap
                        ? _c("transfer-service-section", {
                            attrs: {
                              "service-type": "domain",
                              "domain-unavailable": _vm.domainUnavailable,
                            },
                            on: {
                              "show-transfer-modal": function ($event) {
                                return _vm.showTransferModal()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.domainOptionsExist
                    ? _c(
                        "div",
                        { staticClass: "section-margin" },
                        [
                          _c("domain-options", {
                            attrs: {
                              "domain-suggestions": _vm.domainSuggestions,
                              bus: _vm.bus,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.activeDomainTransferRequest
            ? _c("domain-transfer-status")
            : _vm._e(),
          _c("domain-transfer-modal", {
            attrs: { bus: _vm.bus },
            model: {
              value: _vm.showDomainTransferModal,
              callback: function ($$v) {
                _vm.showDomainTransferModal = $$v
              },
              expression: "showDomainTransferModal",
            },
          }),
          _c("standalone-hosting-modal", {
            attrs: { bus: _vm.bus },
            on: {
              "next-slide": function ($event) {
                return _vm.$emit("next-slide")
              },
            },
            model: {
              value: _vm.showStandaloneHostingModal,
              callback: function ($$v) {
                _vm.showStandaloneHostingModal = $$v
              },
              expression: "showStandaloneHostingModal",
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }