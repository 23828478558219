<template>
  <b-container id="invoices">
    <masthead
      id="test"
      title="Invoices"
      :datasource="invoices"
      :filter-options="filterOptions"
      @changed="searchChanged"
    >
      <b-button
        variant="primary"
        size="$mq"
        class="mr-3"
        :disabled="payBtnDisabled"
        @click="payClicked"
      >
        <feather-icon type="dollar-sign" />
        <span v-if="this.$mq !== 'sm'">{{ payBtnText }}</span>
        <span v-if="this.$mq === 'sm'">Pay</span>
      </b-button>
      <b-button
        v-if="this.selection.length <= 1"
        variant="primary"
        :disabled="downloadBtnDisabled"
        :aria-label="downloadBtnText + ' button'"
        @click="downloadClicked()"
      >
        <feather-icon type="download" />
        {{ downloadBtnText }}
      </b-button>
      <b-dropdown
        v-else
        variant="primary"
        :disabled="downloadBtnDisabled"
        :aria-label="downloadBtnText + ' button'"
      >
        <template #button-content>
          <feather-icon type="download" />
          {{ downloadBtnText }}
        </template>
        <b-dropdown-item @click="downloadClicked()">
          Download As Individual PDFs
        </b-dropdown-item>
        <b-dropdown-item @click="downloadClicked(true)">
          Download As Combined PDF
        </b-dropdown-item>
      </b-dropdown>
    </masthead>

    <b-tabs v-model="tabIndex">
      <b-tab>
        <template
          slot="title"
        >
          My Invoices
        </template>
        <ajax-table
          ref="invoicesTable"
          select-mode="multi"
          :selectable="true"
          :table-definition="myInvoices"
          :no-local-sorting="true"
          @selection-changed="selectionEvent"
          @loaded="onLoad"
        >
          <template v-slot:cell(states)="data">
            {{ stateListFormatted(data.item).join(', ') }}
          </template>
          <template v-slot:cell(content)="data">
            {{ getContentNames(data.item).join(', ') }}
          </template>
          <template slot="cell(actions)" slot-scope="row">
            <b-button
              class="mr-1"
              variant="link"
              @click="view(row.item)"
            >
              View
            </b-button>
          </template>
        </ajax-table>
      </b-tab>

      <b-tab>
        <template
          slot="title"
        >
          Invoices Shared With Me
        </template>
        <ajax-table
          ref="invoicesSharedTable"
          select-mode="multi"
          :selectable="true"
          :table-definition="invoiceSharedWithMe"
          :no-local-sorting="true"
          @selection-changed="selectionEvent"
          @loaded="onSharedLoad"
        >
          <template slot="cell(actions)" slot-scope="row">
            <b-button class="mr-1"
                      variant="link"
                      @click="view(row.item)"
            >
              View
            </b-button>
          </template>
        </ajax-table>
      </b-tab>
    </b-tabs>
  </b-container>
</template>
<script>
import { formatFullDate } from '@/common/modules/formatters'
import Masthead from '@/components/shared/Masthead'
import FeatherIcon from '@/components/shared/FeatherIcon'
import { setTimeout } from 'timers'
import AjaxTable from '../components/shared/AjaxTable'
import _ from 'lodash'
import { getDateFilter } from '@/common/modules/dates'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'Invoices',
  components: { AjaxTable, FeatherIcon, Masthead },
  mixins: [makeToastMixin],
  data() {
    return {
      dataCount: null,
      dataSharedCount: null,
      tabIndex: 0,
      query: '',
      select: null,
      selection: [],
      currentInvoices: [],
      invoices: [],
      sharedInvoices: [],
      paymentForm: {
        selectedPayableId: null,
        tosChecked: false,
        customPaymentAddress: false,
      },
      bus: new Vue(),
      appliedFilters: [],
    }
  },
  computed: {
    ...mapGetters('account', ['accountType']),
    ...mapGetters('jurisdictions', ['findById']),
    filterOptions() {
      return {
        radioGroups: [
          {
            key: 'lock_status', filters: [
              { key: 'paid', scope: 'by_status', value: 'paid', label: 'Paid' },
              { key: 'unpaid', scope: 'by_status', value: 'unpaid', label: 'Unpaid' },
            ],
          },
        ],
        dateRadioGroup: {
          filters: [
            getDateFilter('PAST_1_WEEK'),
            getDateFilter('PAST_1_MONTH'),
            getDateFilter('PAST_3_MONTHS'),
            getDateFilter('PAST_6_MONTHS'),
          ],
          custom: true,
          default: this.isWholesale() ? getDateFilter('PAST_3_MONTHS') : {},
        },
      }
    },
    myInvoices() {
      return {
        columns: [
          { key: 'company_name', label: 'Company', sortable: true },
          {
            key: 'created_at',
            label: 'Invoice Created',
            formatter: value => this.dateTableColumn(value),
            sortable: true,
          },
          {
            key: 'due_date',
            label: 'Due',
            formatter: value => this.dateTableColumn(value),
            sortable: true,
          },
          { key: 'status', label: 'Paid', formatter: value => _.startCase(value), sortable: true },
          {
            key: 'paid_date',
            label: 'Date Paid',
            formatter: value => this.dateTableColumn(value),
            sortable: true,
          },
          { key: 'total', label: 'Total', formatter: value => this.formatPrice(value) },
          { key: 'invoice_number', label: 'Invoice', sortable: true },
          { key: 'states', label: 'States' },
          { key: 'content', label: 'Content' },
          { key: 'actions', label: '' },
        ],
        url: 'client/invoices',
        parameters: {
          search_name: 'search_invoices_page',
          query: this.query,
          filter: this.appliedFilters,
        },
      }
    },
    invoiceSharedWithMe() {
      return {
        columns: [
          { key: 'company_name', label: 'Company', sortable: true },
          {
            key: 'created_at',
            label: 'Invoice Created',
            formatter: value => this.dateTableColumn(value),
            sortable: true,
          },
          {
            key: 'due_date',
            label: 'Due',
            formatter: value => this.dateTableColumn(value),
            sortable: true,
          },
          { key: 'status', label: 'Paid', formatter: value => _.startCase(value), sortable: true },
          {
            key: 'paid_date',
            label: 'Date Paid',
            formatter: value => this.dateTableColumn(value),
            sortable: true,
          },
          { key: 'total', label: 'Total', formatter: value => this.formatPrice(value) },
          { key: 'invoice_number', label: 'Invoice', sortable: true },
          { key: 'actions', label: '' },
        ],
        url: 'client/invoices/shared',
        parameters: {
          search_name: 'search_invoices_page',
          query: this.query,
          filter: this.appliedFilters,
        },
      }
    },
    downloadBtnText() {
      if (this.selection.length > 0 && this.$mq !== 'sm') {
        return `Download ${this.selection.length} Invoice${this.selection.length === 1 ? '' : 's'}`
      } else {
        return 'Download'
      }
    },
    unpaidInvoices() {
      const unpaidIds = this.withStatus('unpaid').map(i => i.id)
      return this.currentInvoices.filter(invoice => unpaidIds.includes(invoice.id))
    },
    payBtnText() {
      if (!this.unpaidInvoices) {
        return 'Pay Invoice'
      }

      const unpaidIds = this.unpaidInvoices.map(invoice => invoice.id)
      const filteredSelection = this.selection.filter(invoice => unpaidIds.includes(invoice.id))
      if (filteredSelection.length > 0) {
        return `Pay ${filteredSelection.length} Unpaid Invoice${
          filteredSelection.length === 1 ? '' : 's'
        }`
      } else {
        return 'Pay Invoice'
      }
    },
    downloadBtnDisabled() {
      return this.selection.length === 0
    },
    payableSelection() {
      const ids = this.unpaidInvoices.map(i => i.id)
      return this.selection.filter(item => ids.includes(item.id)).map(i => i.id)
    },
    payBtnDisabled() {
      return this.payableSelection.length === 0
    },
  },
  watch: {
    tabIndex: function(index) {
      this.currentInvoices = index === 0 ? this.invoices : this.sharedInvoices
    },
  },
  beforeMount() {
    this.appliedFilters = this.defaultFilters()
  },
  async mounted() {
    while(this.dataCount == null || this.dataSharedCount == null) {
      await new Promise( r => setTimeout(r, 200) )
    }
    this.tabIndex = this.dataCount === 0 && this.dataSharedCount > 0 ? 1:0
  },
  methods: {
    ...mapActions('invoices', ['bulkDownload', 'downloadCombinedInvoices', 'checkForCombinedPdf']),
    stateListFormatted(invoice) {
      const states = new Set()

      if (invoice.invoice_line_items.length) {
        invoice.invoice_line_items.forEach(item => {
          if (item.jurisdiction_id){
            states.add(this.findById(item.jurisdiction_id).abbreviation)
          }
        })
      }

      return Array.from(states.values()).sort()
    },
    getContentNames(invoice){
      const labels = new Set()
      const getProductType = (product) => {
        switch (product.kind) {
          case 'registered_agent_product':
            return 'RA'
          case 'filing_product':
            return 'FILING'
        }

        switch (product.category) {
          case 'mail-forwarding':
          case 'call-forwarding':
            return 'VO'
        }
      }

      invoice.invoice_line_items.forEach(item => {
        if (item.product && getProductType(item.product)) {
          labels.add(getProductType(item.product))
        }
      })

      return Array.from(labels.values()).sort()
    },
    formatPrice(value) {
      return this.$options.filters.currency(value)
    },
    selectionEvent(selection) {
      this.selection = selection
    },
    dateTableColumn(date) {
      return !date || date === 'Invalid Date' ? '---' : this.formatDate(date)
    },
    withStatus(status = 'unpaid') {
      if (!this.currentInvoices) {
        return []
      }
      return this.currentInvoices.filter(
        invoices => invoices.status.toLocaleLowerCase() === status.toLocaleLowerCase()
      )
    },
    async reloadData() {
      this.$refs.invoicesTable.reload()
      this.$refs.invoicesSharedTable.reload()
    },
    formatDate: formatFullDate,
    collapseIdFor(invoiceId) {
      return `v-b-toggle.${invoiceId}`
    },
    toggleCollapseFor(invoiceId) {
      this.$root.$emit('bv::toggle::collapse', this.collapseIdFor(invoiceId))
    },
    contains(id) {
      return this.selection.filter(item => item.id === id).length > 0
    },
    payClicked() {
      this.$router.push({
        name: 'payInvoices',
        query: { invoiceIds: this.payableSelection.join(',') },
      })
    },
    async downloadClicked(combine_pdf) {
      const ids = this.selection.map(invoice => invoice.id)
      if (ids.length === 0) {
        return
      }

      let fetchedURLs

      try {
        if (ids.length === 1 || !combine_pdf) {
          await this.fetchIndividualURLs(ids)
        } else {
          fetchedURLs = await this.fetchCombinedPDF(ids)
          const uuid = fetchedURLs.id
          this.pollForPDF(uuid)
        }
      } catch (error) {
        this.errorToast('Download Failed', 'There was an error processing your request.')
      }
    },
    async fetchIndividualURLs(ids) {
      try {
        // We are investigating slowness that may be linked to downloading several invoices at a time.
        // Here, we download them sequentially to see how this impacts server performance
        for (const id of ids) {
          const data = await this.bulkDownload([id])
          const fetchedURLs = data?.result || []
          const interval = 1000

          fetchedURLs.forEach((value, index) => {
            const url = value.url
            setTimeout(() => {
              window.location = url
            }, interval * (index + 1))
          })
        }
      } catch (error) {
        this.errorToast("Download Failed", "Failed to download all invoices.")
      }
    },
    async fetchCombinedPDF(ids) {
      try {
        const data = await this.downloadCombinedInvoices(ids)

        return data?.result || null
      } catch (error) {
        this.errorToast("Download Failed", "Failed to download combined invoices.")
      }
    },
    pollForPDF(id) {
      const interval = 10000
      const maxRetries = 30
      let retries = 0

      this.successToast('Combining Invoices', 'Will automatically download when complete')

      const pollTimeout = setInterval(async () => {
        try {
          const data = await this.checkForCombinedPdf(id)
          const pdf = data.result

          if (pdf.pdf_url) {
            window.location = pdf.pdf_url
            this.handleFailedPDFs(pdf.failed_pdfs)
            clearInterval(pollTimeout)
          } else if (retries >= maxRetries) {
            clearInterval(pollTimeout)
            this.errorToast('Combining Invoices', `PDF generation for UUID ${id} timed out.`)
          } else {
            retries++
          }
        } catch (error) {
          clearInterval(pollTimeout)
        }
      }, interval)
    },
    handleFailedPDFs(failedPdfs) {
      if (failedPdfs.length > 0) {
        const failedPdfsList = failedPdfs.map(failed => failed['invoice_number']).join(', ')
        this.warningToast('Combining Invoices', `The following invoices failed to combine: ${failedPdfsList}. Please download them individually.`)
      }
    },
    view(item) {
      this.$router.push({ name: 'invoice', params: { id: item.id } })
    },
    searchChanged(searchObject) {
      this.query = searchObject.query
      this.appliedFilters = searchObject.filters
    },
    onLoad(tableData) {
      this.invoices = tableData
      this.dataCount = tableData.length
      this.currentInvoices = this.invoices
    },
    onSharedLoad(tableData) {
      this.sharedInvoices = tableData
      this.dataSharedCount = tableData.length
    },
    isWholesale() {
      return this.accountType === 'wholesale-registered-agent'
    },
    defaultFilters() {
      let filters = []
      if (this.isWholesale()) {
        filters = [
          { key: 'PAST_3_MONTHS', scope: 'created_at_gte', value: getDateFilter('PAST_3_MONTHS').startTime, label: 'Past 3 Months' },
          { key: 'PAST_3_MONTHS', scope: 'created_at_lte', value: getDateFilter('PAST_3_MONTHS').endTime, label: 'Past 3 Months' },
        ]
      }

      return filters
    },
  },
}
</script>

<style lang="scss" scoped>
#invoices {
  max-width: 1200px;
}

.fixed-btn-group {
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 1000;
}

.modal {
  z-index: 1001 !important;
}

#payInvoiceModal h2 {
  text-align: center !important;
}

.page-item.disabled .page-link span {
  border-color: white !important;
}
</style>
