<template>
  <div class="domain-admin-hosting">
    <!-- Heading ---------------------------------------------------------------------------------->
    <h4>
      <span>Domain Hosting<span v-if="subtitle" class="subtitle"> {{ subtitle }}</span></span>
    </h4>

    <div class="d-flex">
      <b-button
        class="mr-3"
        variant="primary"
        :disabled="!wpStatus.allow_reinstall || reinstallingWordpress || (domainHostingInfo?.platform === 'pterodactyl')"
        @click="performWordpressReinstall()"
      >
        Re-install Wordpress
      </b-button>
      <b-button
        variant="primary"
        :disabled="!domainHostingInfo || domainHostingInfo.platform === 'cpanel'"
        @click="showWpChangePassword()"
      >
        Change WP Pass
      </b-button>
    </div>

    <ct-centered-spinner v-if="reinstallingWordpress" class="loading-spinner mb-lg-5">
      <p class="centered-spinner-text">
        Performing wordpress re-installation...
      </p>
      <p>
        <em>
          Please do not navigate away or refresh the page.
        </em>
      </p>
    </ct-centered-spinner>

    <br>

    <div v-if="!reinstallingWordpress">
      <h5>
        <strong class="mr-1">Wordpress Status:</strong>

        <br>

        <span v-if="wpStatus.status !== 1">
          Status Code:
          <fa-icon :icon="wpStatus.icon" :style="{ color: wpStatus.color }" />
          <b-tooltip target="admin_status_code" placement="top" offset="60" width="25rem">
            <span v-html="wpStatusMessage" />
          </b-tooltip>
          <span id="admin_status_code" class="status-code">
            {{ wpStatus.status }}
            <fa-icon icon="external-link-alt" />
          </span>
        </span>

        <span v-else>
          <ct-centered-spinner class="loading-spinner">
            <p style="width: 25rem;">
              Fetching Domain Hosting Status...
            </p>
            <p>
              <em>
                This can take a minute.
              </em>
            </p>
          </ct-centered-spinner>
        </span>

        <br>

        <b-btn
          v-if="wpStatus.status !== 1"
          variant="primary"
          class="float-left mt-1 mb-1"
          @click="refreshWebsiteView()"
        >
          Refresh
          <b-icon-arrow-clockwise />
        </b-btn>
      </h5>
    </div>
    <iframe
      v-if="wpStatus.status !== 1 && !reinstallingWordpress"
      id="admin-website-view"
      :src="domainUrl"
      width="100%"
      height="450rem"
    />
    <wordpress-change-password-modal
      @hide="hideWpChangePassword"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import * as DOMPurify from 'dompurify'
import WordpressChangePasswordModal from '@/components/Domains/panels/Admin/WordpressChangePasswordModal.vue'

export default {
  name: 'DomainAdminHosting',
  components: {
    WordpressChangePasswordModal,
    CtCenteredSpinner,

  },
  mixins: [makeToastMixin],
  props: {
    subtitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      wpStatus: null,
      domainUrl: "",
      reinstallingWordpress: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
      'domainHostingInfo',
    ]),
    wpStatusMessage() {
      return DOMPurify.sanitize(this.wpStatus.message)
    },
  },
  watch: {
    'domain': {
      async handler(value) {
        if (value) {
          await this.fetchWpStatus()
          this.domainUrl = `https://www.${value.domain_name}`
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('domains', [
      'fetchHostingStatus',
      'reinstallWordpress',
    ]),
    async fetchWpStatus() {
      if (this.domain) {
        this.setLoadingStatus()
        const result = await this.fetchHostingStatus(this.domain.id.toString())
        const hostingStatus = result.hosting_status
        const allowReinstall = result.allow_reinstall

        if (hostingStatus >= 200 && hostingStatus < 300) {
          this.wpStatus = {
            status: hostingStatus,
            allow_reinstall: allowReinstall,
          }

          if (allowReinstall) {
            this.wpStatus.icon = "check-circle"
            this.wpStatus.color = "orange"
            this.wpStatus.message = "<p>Domain hosting is functioning normally but there is an issue with the setup. Reinstall is permitted.</p>"
          } else {
            this.wpStatus.icon = "check-circle"
            this.wpStatus.color = "green"
            this.wpStatus.message = "<p>Domain hosting is functioning normally. Reinstall not permitted without approval.</p>"
          }


        } else if (hostingStatus >= 300 && hostingStatus < 400) {
          this.wpStatus = {
            status: hostingStatus,
            allow_reinstall: allowReinstall,
            icon: "times-circle",
            color: "orange",
            message: "<p>Hosting Redirection:<br>Either the clients account has been suspended or there's a redirection on the WP site.<br>Usually it's a non-issue as long as everything works correctly but reinstalling will fix any issues usually.</p>",
          }
        } else if (hostingStatus >= 400 && hostingStatus < 500) {
          this.wpStatus = {
            status: hostingStatus,
            allow_reinstall: allowReinstall,
            icon: "times-circle",
            color: "red",
            message: "<p>Request Error, hosting is not functioning properly.<br>There is likely an issue with the wordpress setup.<br>Reinstall required.</p>",
          }
        } else {
          this.wpStatus = {
            status: hostingStatus,
            allow_reinstall: allowReinstall,
            icon: "times-circle",
            color: "red",
            message: "<p>Hosting Failure, hosting is not functioning at all and needs to be reset.<br> Reinstall required.</p>",
          }
        }
      } else {
        this.wpStatus = {
          status: 100,
          allow_reinstall: false,
          icon: "check-circle",
          color: "orange",
          message: "<p>Loading status please wait...</p>",
        }
      }
    },
    async performWordpressReinstall() {
      let returnResult = true
      this.reinstallingWordpress = true
      const result = await this.reinstallWordpress(this.domain.id.toString())

      if (result?.data.success) {
        await this.fetchWpStatus()

        this.successToast('Success', 'Successfully reinstalled wordpress.')
        returnResult = true
        this.reinstallingWordpress = false
      } else {
        returnResult = false
        this.errorToast('Error', 'Unable to reinstall wordpress.')
        this.reinstallingWordpress = false
      }
      return returnResult
    },
    showWpChangePassword() {
      this.$bvModal.show('wordpress-change-password-modal')
    },
    hideWpChangePassword() {
      this.$bvModal.hide('wordpress-change-password-modal')
    },
    setLoadingStatus(){
      this.wpStatus = {
        status: 1,
        icon: "spinner",
        color: "orange",
        message: "<p>Loading domain hosting status, please wait...</p>",
      }
    },
    async refreshWebsiteView() {
      await this.fetchWpStatus()
      window.frames['admin-website-view'].src = this.domainUrl
    },
  },
}
</script>

<style lang="scss" scoped>
  h4 {
    padding: unset;
    margin: unset;
    height: 5.5rem;
    font-weight: 900;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .subtitle {
      font-weight: 200;
    }
  }
  .status-code {
    color: blue;
  }
  .centered-spinner-text {
    width: 25rem;
  }
</style>
