var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tagType,
    _vm._b(
      {
        tag: "component",
        staticClass: "step",
        class: { active: _vm.isActive, blocking: _vm.isBlocking },
      },
      "component",
      _vm.tagAttributes,
      false
    ),
    [
      _c(
        "div",
        { staticClass: "icon" },
        [_c("b-img", { attrs: { src: `images/${_vm.icon}.svg` } })],
        1
      ),
      _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "div",
        { staticClass: "details", class: { inSecondHalf: _vm.isInSecondHalf } },
        [
          _c("h5", { staticClass: "quip" }, [_vm._v(_vm._s(_vm.quip))]),
          _c("h4", { staticClass: "blurb" }, [_vm._v(_vm._s(_vm.blurb))]),
          _c("p", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.description)),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }