<template>
  <div>
    <div class="header">
      <span>Vehicle Registrations</span>
      <b-button
        v-if="loaded"
        v-b-modal.add-vehicle-registration-modal
        variant="primary"
        aria-label="Add Vehicle link"
      >
        <span>+</span> Vehicle
      </b-button>
    </div>
    <div class="container-fluid pt-4 ml-0 pl-0 pr-0">
      <p v-if="loaded && vehicleRegistrations <= 0" class="text-center">
        Currently, there are no vehicles on record. Click "Add New Vehicle" to register one.
      </p>
      <b-tabs v-if="loaded && vehicleRegistrations" v-model="currentTab" pills>
        <div v-if="currentTab != 0">
          <div class="mb-3" />
          <b-form-input
            v-if="loaded"
            v-model="searchQuery"
            placeholder="Search vehicles"
            class="mb-3"
          />
        </div>
        <b-tab key="pending_orders" title="Pending Orders">
          <vehicle-orders />
        </b-tab>
        <b-tab v-for="vehicles in filteredVehicles" :key="vehicles.title" :title="vehicles.title">
          <vehicle-ct-table-renderer
            :loaded="loaded"
            :items="vehicles.items"
            :fields="vehicles.fields"
            :can-renew="vehicles.canRenew"
            @load="load"
            @editVehicle="editVehicle"
            @updateCompleted="updateCompleted"
            @vehicleRegistrationCompleted="vehicleRegistrationCompleted"
            @removeVehicle="removeVehicle"
            @removeCompletedVehicle="removeCompletedVehicle"
            @updateRemindMe="updateRemindMe"
            @updateVehicleFailed="updateVehicleFailed"
            @addService="addService"
          />
        </b-tab>
      </b-tabs>
    </div>

    <add-vehicle-services-modal
      :vehicle="selectedVehicleToAddService"
    />
    <edit-vehicle-registration-modal
      :vehicle="selectedVehicleToEdit"
      @updateComplete="updateCompleted"
      @updateVehicleFailed="updateVehicleFailed"
    />
    <add-vehicle-registration-modal @completed="vehicleRegistrationCompleted" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { isEmpty } from 'lodash/lang'
import VehicleOrders from '@/components/Vehicles/VehicleOrders.vue'
import VehicleCtTableRenderer from '@/components/Vehicles/VehicleCtTableRenderer.vue'
import AddVehicleRegistrationModal from '@/components/Vehicles/AddVehicleRegistrationModal'
import EditVehicleRegistrationModal from '@/components/Vehicles/EditVehicleRegistrationModal'
import AddVehicleServicesModal from '@/components/Vehicles/AddVehicleServicesModal.vue'

extend('required', {
  ...required,
  message: '{_field_} is required',
})
extend('registration_period', {
  validate(value) {
    return value
  },
  message: 'Registration Period must be selected',
})
extend('vehicle_identification_number', {
  validate(value) {
    return value.length <= 17
  },
  message: 'This field must be less than 18 characters long',
})
extend('model_year', {
  validate(value) {
    return value.toString().length === 4 && (value > 1900 && value < 2030)
  },
  message: 'Must be a valid year',
})
extend('fuel_type', {
  validate(value) {
    return value !== null
  },
  message: '{_field_} is required',
})
extend('model', {
  validate(value) {
    return value && (value.length > 0 || value.text.length > 0)
  },
  message: '{_field_} is required',
})
extend('make', {
  validate(value) {
    return !isEmpty(value) || value.name.length > 0
  },
  message: '{_field_} is required',
})
extend('date_format', {
  validate(value) {
    return value.text.length > 0
  },
  message: '{_field_} is required',
})
extend('registration_date', {
  validate(value) {
    return value.toString().length > 5
  },
  message: '{_field_} is required',
})
extend('registration_period', {
  validate(value) {
    return value.length > 0
  },
  message: '{_field_} is required',
})
extend('weight', {
  params: ['vehicleType'],
  validate(value, { vehicleType }) {
    if (!value || value <= 0) return false
    if (vehicleType === 'heavy_truck') {
      return value <= 54000
    }
    return true
  },
  message: (field, { vehicleType }) => {
    if (!vehicleType || vehicleType === 'heavy_truck') {
      return 'Weight must be between 0 and 54,000 lbs'
    }
    return 'Weight must be greater than 0'
  },
})
extend('msrp', {
  validate(value) {
    return value >= 1
  },
  message: '{_field_} is required',
})
extend('rv_class', {
  validate(value) {
    return value.length > 0
  },
  message: '{_field_} is required',
})
extend('luxury_tax', {
  validate(value) {
    return value !== null
  },
  message: '{_field_} is required',
})
extend('length', {
  validate(value) {
    return value > 0
  },
  message: '{_field_} must be greater than 0',
})
extend('electric_hybrid_tax', {
  validate(value) {
    return value !== null
  },
  message: '{_field_} is required',
})
extend('mileage', {
  validate(value) {
    return value >= 0
  },
  message: '{_field_} must be greater than 0',
})
extend('vessel_type', {
  validate(value) {
    return value !== null
  },
  message: '{_field_} is required',
})
extend('vessel_material', {
  validate(value) {
    return value !== null
  },
  message: '{_field_} is required',
})
extend('boat_propulsion', {
  validate(value) {
    return value !== null
  },
  message: '{_field_} is required',
})
extend('engine_displacement', {
  validate(value) {
    return value >= 0
  },
  message: '{_field_} must be greater than 0',
})
extend('import_fee', {
  validate(value) {
    return value !== null
  },
  message: '{_field_} is required',
})

export default {
  name: 'VehicleRegistrations',
  components: {
    VehicleOrders,
    VehicleCtTableRenderer,
    AddVehicleRegistrationModal,
    EditVehicleRegistrationModal,
    AddVehicleServicesModal,
  },
  props: {
    tab: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      searchQuery: '',
      appliedFilters: [],
      currentTab: Number.isInteger(this.tab) ? this.tab : 1,
      companies: null,
      loaded: null,
      selectedVehicleToEdit: null,
      selectedVehicleToAddService: null,
      query: '',
      defaultFields: [
        {
          key: 'company_name',
          label: 'Company',
          sortable: true,
        },
        {
          key: 'model_year',
          label: 'Year',
          sortable: true,
        },
        {
          key: 'make',
          label: 'Make',
          sortable: true,
        },
        {
          key: 'model',
          label: 'Model',
          sortable: true,
        },
        {
          key: 'vehicle_identification_number',
          label: 'VIN',
          sortable: false,
        },
        {
          key: 'plate_number',
          label: 'Plate #',
          sortable: true,
        },
        {
          key: 'registration_date',
          label: 'Reg. Date',
          formatter: value => {
            if (value) {
              return new Date(value).toLocaleDateString()
            }
          },
          sortable: true,
        },
        {
          key: 'upcoming_filing_date',
          label: 'Upcoming Renewal',
          formatter: value => {
            if (value) {
              return new Date(value).toLocaleDateString()
            }
          },
          sortable: true,
        },
        {
          key: 'registration_period',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'active_renewal',
          label: 'Renewal',
        },
        {
          key: 'actions',
          label: 'Actions',
          class: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('vehicleRegistration', ['vehicles']),
    vehicleRegistrations() {
      return this.vehicles
    },
    rvFields() {
      const fields = [
        { key: 'rv_class', sortable: false, label: 'RV Class' },
      ]
      let allFields = [...this.defaultFields]
      allFields.splice(3, 0, ...fields)
      return allFields
    },
    boatFields() {
      const fields = [
        { key: 'vessel_type', sortable: false, label: 'Vessel Type' },
        { key: 'vessel_material', sortable: false, label: 'Vessel Material' },
        { key: 'boat_propulsion', sortable: false, label: 'Boat Propulsion' },
      ]
      let allFields = [...this.defaultFields]
      allFields.splice(3, 0, ...fields)
      return allFields
    },
    motorcycleFields() {
      const fields = [
        { key: 'sub_type', sortable: false, label: 'Sub Type' },
      ]
      let allFields = [...this.defaultFields]
      allFields.splice(3, 0, ...fields)
      return allFields
    },
    travelTrailerFields() {
      const fields = [
        { key: 'sub_type', sortable: false, label: 'Sub Type' },
      ]
      let allFields = [...this.defaultFields]
      allFields.splice(3, 0, ...fields)
      return allFields
    },
    cargoTrailerFields() {
      const fields = [
        { key: 'sub_type', sortable: false, label: 'Sub Type' },
      ]
      let allFields = [...this.defaultFields]
      allFields.splice(3, 0, ...fields)
      return allFields
    },
    lightVehicles() {
      return this.vehicleRegistrations.filter(v => v.vehicle_type === 'light_vehicle')
    },
    rvs() {
      return this.vehicleRegistrations.filter(v => v.vehicle_type === 'recreational_vehicle')
    },
    travelTrailers() {
      return this.vehicleRegistrations.filter(v => v.vehicle_subtype === 'travel' && v.vehicle_type === 'trailer')
    },
    cargoTrailers() {
      return this.vehicleRegistrations.filter(v => v.vehicle_subtype === 'cargo' && v.vehicle_type === 'trailer')
    },
    boats() {
      return this.vehicleRegistrations.filter(v => v.vehicle_type === 'boat')
    },
    motorcycles() {
      return this.vehicleRegistrations.filter(v => v.vehicle_type === 'motorcycle')
    },
    heavyTrucks() {
      return this.vehicleRegistrations.filter(v => v.vehicle_type === 'heavy_truck')
    },
    allVehicles() {
      return [
        { title: 'Cars/Trucks', items: this.lightVehicles, fields: this.defaultFields, canRenew: true },
        { title: 'RV', items: this.rvs, fields: this.rvFields, canRenew: true },
        { title: 'Boats', items: this.boats, fields: this.boatFields, canRenew: false },
        { title: 'Motorcycles', items: this.motorcycles, fields: this.motorcycleFields, canRenew: true },
        { title: 'Heavy Trucks', items: this.heavyTrucks, fields: this.defaultFields, canRenew: true },
        { title: 'Travel Trailers', items: this.travelTrailers, fields: this.travelTrailerFields, canRenew: false },
        { title: 'Cargo Trailers', items: this.cargoTrailers, fields: this.cargoTrailerFields, canRenew: false },
      ]
    },
    filteredVehicles() {
      if (!this.searchQuery) return this.allVehicles.filter(type => type.items?.length)

      const searchColumns = ['model_year', 'make', 'model', 'vehicle_identification_number', 'company_name']
      const searchQueryLower = this.searchQuery.toString().toLowerCase()

      const searchedVehicles = this.allVehicles.map(type => ({
        ...type,
        items: type.items.filter(vehicle =>
          searchColumns.some(column =>
            vehicle[column]?.toString().toLowerCase().includes(searchQueryLower)
          )
        )}))

      return searchedVehicles.filter(type => type.items.length)
    },
  },
  async mounted() {
    await this.load()
  },
  methods: {
    ...mapActions('vehicleRegistration', [
      'loadVehicles',
      'removeVehicleRegistration',
      'update',
    ]),
    async load() {
      this.loaded = false
      try {
        await this.loadVehicles()
      } catch (error) {
        this.errorModal("Error loading vehicles")
      }

      this.loaded = true
    },
    async updateRemindMe(vehicle) {
      if (!vehicle.should_notify) {
        try {
          const res = await this.createMsgBoxForConfirmation('Are you sure you want to turn off renewal notifications?')
          if (res) {
            this.loaded = false
            await this.callUpdateRemindMe(vehicle,  false)
            this.loaded = true
          } else {
            vehicle.should_notify = true
          }
        } catch (error) {
          this.errorModal(error.response.data.error.message)
          this.loaded = true
        }
      } else {
        const res = await this.createMsgBoxForConfirmation('Are you sure you want to turn on renewal notifications?')
        if (res) {
          try {
            this.loaded = false
            await this.callUpdateRemindMe(vehicle, true)
            this.loaded = true
          } catch (error) {
            vehicle.should_notify = false
            this.errorModal(error.response.data.error.message)
            this.loaded = true
          }
        } else {
          vehicle.should_notify = false
        }
      }
    },
    editVehicle(vehicle) {
      this.selectedVehicleToEdit = vehicle
    },
    addService(vehicle) {
      this.selectedVehicleToAddService = vehicle
    },
    async createMsgBoxForConfirmation(message) {
      return await this.$bvModal.msgBoxConfirm(
        message, {
          title: 'Remove Vehicle?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        })
    },
    async confirmAndRemoveVehicle(vehicle, confirmationMessage, successMessage) {
      try {
        const res = await this.createMsgBoxForConfirmation(confirmationMessage)

        if (res) {
          this.loaded = false
          await this.removeVehicleRegistration({ id: vehicle.id })
          this.makeToast('primary', 'Success', successMessage)
          this.loaded = true
          await this.load()
        }
      } catch (error) {
        this.errorModal('We can not remove your vehicle at this time.')
        this.loaded = true
      }
    },
    async removeVehicle(vehicle) {
      const confirmationMessage = 'Are you sure you want to remove your vehicle? \n\rThis will place your vehicle in a removed status, removing any renewal services.'
      const successMessage = 'Your Vehicle Has Been Removed!'
      await this.confirmAndRemoveVehicle(vehicle, confirmationMessage, successMessage)
    },
    async removeCompletedVehicle(vehicle) {
      const confirmationMessage = 'Are you sure? \n\rThis will cancel all active services for this vehicle.'
      const successMessage = 'Your Vehicle Has Been Removed!'
      await this.confirmAndRemoveVehicle(vehicle, confirmationMessage, successMessage)
    },
    async vehicleRegistrationCompleted() {
      this.$router.go()
    },
    async updateCompleted() {
      await this.load()
      this.makeToast('primary', 'Success', 'Vehicle Updated')
    },
    async updateVehicleFailed(){
      this.makeToast('danger', 'Failed', 'Vehicle Failed to Update')
    },
    makeToast(variant = null, title, body) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      })
    },
    successModal() {
      this.makeToast(
        'primary',
        'Success',
        'Your Notification Preferences Have Been Saved'
      )
    },
    errorModal(msg) {
      this.makeToast('danger', 'Failure', msg)
    },
    async callUpdateRemindMe(vehicle, should_notify){
      vehicle.should_notify = should_notify
      await this.update({ vehicle })
      this.successModal()
    },
  },
}
</script>


<style lang="scss" scoped>
  $primary: #283d96;
  $primary-dark: darken($primary, 10%);
  $font: #212529;

  @mixin typography($font-size: 1.0rem, $font-weight: 400, $color: $font) {
    font-size: $font-size;
    font-weight: $font-weight;
    color: $color;
  }

  @mixin btn-hover($hover-color: $primary-dark, $color: white) {
    border-color: $hover-color;
    background: $hover-color;
    color: $color;
  }

  @mixin btn-core($padding, $hover-color) {
    @include typography($color: white, $font-weight: 500);
    width: fit-content;
    border: 0.0625rem solid $primary;
    border-radius: 0.3125rem;
    padding: $padding;
    margin: 0;
    outline: 0;
    line-height: 1.1;

    &:hover:not([disabled]) {
      @include btn-hover();
    }
  }

  @mixin btn-default($primary-color, $hover-color, $padding: 0.5rem 1.0rem) {
    background: $primary-color;
    color: white;
    border-color: $primary-color;
    @include btn-core($padding, $hover-color);
  }

  button.btn-primary {
    @include btn-default(
        $primary-color: $primary,
        $hover-color: $primary-dark,
        $padding: 0 1.25rem
    );
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -15px;
    padding: 1rem 30px 1rem 30px;
    border-bottom: 1px solid #dee2e6;

    > span {
      font-size: 2rem;
    }

    button {
      margin: .325rem 0;
      text-wrap: nowrap;
      height: 37.6px;

      span {
        font-size: 1.5rem;
        vertical-align: sub;
      }
    }
  }

  :deep(.nav-pills) {
    .nav-item:first-child {
      margin-right: 0.5rem;
      padding-right: 0.5rem;
      border-right: 1px solid #ced4da;
    }

    a {
      color: #283d96;
    }

    .nav-link.active {
      background: #283d96;
    }
  }

  :deep(tbody .actions .expandable-row) {
    padding-left: 0;
    display: flex;
    flex-wrap: nowrap;
    min-width: 13rem;
  }

  @media (max-width: 735px) {
    .header > span {
      font-size: 1.625rem;
    }
  }
</style>
