<template>
  <b-modal v-model="value"
           v-b-modal.modal-prevent-closing
           modal-class="domain-transfer-modal"
           size="lg"
           no-close-on-backdrop
           hide-footer
  >
    <template #modal-header>
      <div class="ml-2 mr-auto my-auto">Transfer Domain</div>
      <div class="icon-container ml-auto mr-2" @click="closeModal()">
        <font-awesome-svg
          :type="'circle-xmark'"
          :fill="'white'"
          :width="25"
          :height="25"
        />
      </div>
    </template>
    <ul class="info-points mt-1 ml-4">
      <li>
        This step is required to transfer your domain to our registrar.
      </li>
      <li>
        Domain transfer authorization is provided by your current domain registrar.
      </li>
      <li>
        After the transfer is complete, your domain will be managed here.
      </li>
    </ul>
    <form ref="domain-transfer-form">
      <validation-observer ref="observer">
        <b-row class="px-4 mt-4">
          <b-col>
            <label>Domain Name</label>
            <validation-provider name="domainName" rules="required">
              <b-form-input
                v-model="transferRequest.domainName"
                type="text"
                :state="errorState('domainName')"
                @change="makeDirty('domainName')"
              />
              <b-form-invalid-feedback id="domain-error-feedback">
                Valid domain name is required
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="px-4">
          <b-col>
            <label>Authorization Code</label>
            <validation-provider name="authorizationCode" rules="required">
              <b-form-input
                v-model="transferRequest.authorizationCode"
                type="text"
                :state="errorState('authorizationCode')"
                @change="makeDirty('authorizationCode')"
              />
              <b-form-invalid-feedback id="domain-error-feedback">
                Authorization code is required
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="px-4">
          <b-col>
            <span class="text--error">
              {{ errorMessage }}
            </span>
          </b-col>
        </b-row>
        <b-row v-if="costChecked" class="px-4 pb-2">
          <b-col>
            <div v-if="transferPrice > 0">
              <div class="text--error">
                This is a premium TLD and has an additional transfer cost of ${{ transferPrice.toFixed(2) }}
              </div>
              <div class="pt-2">Select your payment method and click "Submit Payment" to start the transfer.</div>
            </div>
            <span v-else>
              There is no additional cost to transfer this domain
            </span>
          </b-col>
        </b-row>
        <b-row v-if="showPaymentSelect">
          <select-payment-method
            :is-billing-active="isBillingActive"
            :set-default-values="true"
            @closeSection="closePaymentSection"
            @togglePanel="openPaymentSection"
          />
        </b-row>
        <b-row class="px-4">
          <b-col />
          <b-col cols="auto">
            <b-button
              class="mr-2"
              variant="outline-danger"
              aria-label="Cancel button"
              :disabled="loading"
              @click="closeModal"
            >
              Cancel
            </b-button>
            <b-button
              :aria-label="`${submitButtonText} button`"
              variant="primary"
              :disabled="loading || isBillingActive"
              @click="submitRequest"
            >
              <b-overlay :show="loading" rounded="sm" :opacity="0" variant="black">
                {{ submitButtonText }}
              </b-overlay>
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </form>
  </b-modal>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import http from '@/http'
import SelectPaymentMethod from '@/components/shared/PaymentModals/SelectPaymentMethod.vue'
import { validDomain } from '@/common'

export default {
  name: 'DomainTransferModal',
  components: {
    SelectPaymentMethod,
    FontAwesomeSvg:      () => import('@/components/shared/FontAwesomeSvg.vue'),
  },
  mixins: [
    makeToastMixin,
  ],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      transferPrice: null,
      domainProduct: null,
      isBillingActive: true,
      transferRequest: {
        domainName: null,
        authorizationCode: null,
      },
      inputDirty: {
        domainName: false,
        authorizationCode: false,
      },
    }
  },
  computed: {
    ...mapGetters('companies', [
      'currentCompany',
    ]),
    ...mapGetters('paymentMethods', [
      'automatedClearingHouses',
      'cards',
      'selectedPaymentMethod',
    ]),
    companyId() {
      return this.$route.params.companyId
    },
    domainValid() {
      return !!this.transferRequest.domainName && this.validDomain().test(this.transferRequest.domainName)
    },
    costChecked() {
      return this.transferPrice !== null
    },
    submitButtonText() {
      if (this.costChecked) return this.transferPrice > 0 ? 'Submit Payment' : 'Submit Transfer'
      return 'Check Domain'
    },
    showPaymentSelect() {
      return this.costChecked && this.transferPrice > 0
    },
  },
  methods: {
    ...mapActions('domains', [
      'submitDomainTransferRequest',
    ]),
    ...mapActions('actionCable', [
      'addRequestCallbackFunction',
    ]),
    makeDirty(field) {
      this.transferPrice = null
      this.errorMessage = null
      this.isBillingActive = false
      this.inputDirty[field] = true
    },
    errorState(field) {
      if (!this.inputDirty[field]) return null
      return field === 'domainName' ? this.domainValid : this.transferRequest[field]?.length > 0
    },
    async validate() {
      for (const field in this.inputDirty) this.inputDirty[field] = true
      return await this.$refs.observer.validate() && this.domainValid
    },
    async submitRequest() {
      this.loading = true
      try {
        if (!await this.validate() || this.errorMessage) {
          this.loading = false
          return
        }
        if (this.costChecked) {
          await this.submitTransferRequest()
        }
        else {
          await this.checkTransferInfo()
          this.inputDirty.authorizationCode = false
        }
      } catch {
        this.errorToast('Submission Error', 'There was an error submitting your request')
        this.loading = false
      }
    },
    async submitTransferRequest() {
      const callbackFunction = (result) => {
        if (result?.success) {
          this.bus.$emit('log-domain-interaction', { name: 'domain-transfer-request' })
          this.successToast('Success', 'Domain transfer request submitted!')
          this.closeModal()
        } else {
          this.errorMessage = "Error while submitting transfer request."
        }
        this.loading = false
      }

      try {
        await this.submitDomainTransferRequest({
          domain_name: this.transferRequest.domainName,
          authorization_code: this.transferRequest.authorizationCode,
          company_id: this.companyId,
          payable_id: this.selectedPaymentMethod?.id,
          afterFunction: callbackFunction,
        })
      } catch {
        this.errorMessage = "Unexpected response. Please try again."
        this.loading = false
      }
    },
    async checkTransferInfo() {
      const callbackFunction = (result) => {
        if (result?.success) {
          this.transferPrice = parseFloat(result.transfer_price)
        } else {
          this.errorMessage = "Error while obtaining transfer info"
        }
        this.loading = false
      }

      const apiResponse = await http.get(`client/domains/domain_transfers/${this.companyId}/info`,
        {
          params: {
              check_domain_info: true,
              domain_name: this.transferRequest.domainName,
              authorization_code: this.transferRequest.authorizationCode,
          },
        })

      if (apiResponse.data?.request_id) {
        await this.addRequestCallbackFunction({
          requestId: apiResponse.data.request_id,
          callbackFunction,
          requestName: "Check Transfer Info",
        })
      } else {
        this.errorMessage = "Unexpected response. Please try again."
        this.loading = false
      }
    },
    closePaymentSection() {
      this.isBillingActive = false
    },
    openPaymentSection() {
      this.isBillingActive = true
    },
    closeModal() {
      this.transferRequest = {
        domainName: null,
        authorizationCode: null,
      }
      this.inputDirty = {
        domainName: false,
        authorizationCode: false,
      }
      this.transferPrice = null
      this.errorMessage = null
      this.$emit('input', false)
    },
    validDomain: validDomain,
  },
}
</script>


<style scoped lang="scss">
.domain-transfer-modal{
  .info-points {
    font-style: italic;
    color: #918F8F;
  }

  .text--error {
    color: #F45151;
  }

  .border-valid {
    border-color: green;
  }

  .border-invalid {
    border-color: #F45151;
  }

  .btn {
    border-radius: 4px;
  }

  .submit-btn {
    width: 200px;
  }

  .error-message {
    color: #F45151;
  }

  .icon-container{
    cursor: pointer;
  }
}
</style>
