<template>
  <b-card id="nav-cart-component" :class="displayIsMobile ? 'cart-mobile' : 'cart-desktop'">
    <div class="cart-title card-title mt-0">
      Order Summary
    </div>

    <template v-if="isStagelineRoute && company">
      <h6 class="company-name">
        {{ company.name }}
      </h6>
    </template>

    <hr>

    <b-card-text class="cart-items">
      <em v-if="cartEmpty">Your cart is empty.</em>

      <div v-else>
        <cart-item-list />
      </div>
    </b-card-text>

    <template v-if="!cartEmpty">
      <hr>

      <div class="d-flex flex-row justify-content-between total">
        <div>
          <strong>Total</strong>
        </div>
        <div>
          <strong>{{ cartItemsTotal | dollars }}</strong>
        </div>
      </div>

      <hr v-if="displayIsMobile">
    </template>

    <div v-if="!hideButton && !isStagelineRoute && cartItems.length > 0" class="cart-button" :class="{ 'float-right': !displayIsMobile }">
      <b-button
        v-if="!processingCheckout"
        class="nav-cart-button"
        variant="primary"
        @click="rootEmit('cart-next-page')"
      >
        {{ buttonText }}
      </b-button>
    </div>

    <b-form-group v-if="isStagelineNonCheckoutRoute && cartItems.length > 0" class="cart-button mt-3">
      <b-button
        v-if="!processingCheckout"
        class="nav-cart-button w-100"
        variant="primary"
        @click="determineCheckoutModal"
      >
        {{ checkoutButtonText }}
      </b-button>
    </b-form-group>

    <ct-centered-spinner v-if="processingCheckout" />

    <collect-account-info :ref="`collectAccountInfoModal-${thisUid}`" @success="determineCheckoutModal" />

    <checkout-modal
      ref="checkout-modal"
      @invoiceHasItemsRequiringAttention="showItemsRequiringAttentionModal"
    />

    <items-requiring-attention-modal ref="items-requiring-attention-modal" :invoice-id="invoiceId" />
  </b-card>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { dollars } from '@/filters'
import CartItemList from '@/components/Checkout/CartItemList'
import CollectAccountInfo from '@/components/CollectAccountInfo'
import { extend } from "vee-validate"
import { required } from "vee-validate/dist/rules"
import CtCenteredSpinner from '../shared/CtCenteredSpinner'
import CheckoutModal from '@/components/StagelineV2/modals/CheckoutModal'
import ItemsRequiringAttentionModal from '@/components/StagelineV2/modals/ItemsRequiringAttentionModal'
import { makeToastMixin } from '@/mixins/makeToastMixin'

extend('required', {
  ...required,
  message: '{_field_} is required',
})

export default {
  name: 'NavCart',

  filters: {
    dollars,
  },

  components: {
    CartItemList,
    CollectAccountInfo,
    CtCenteredSpinner,
    CheckoutModal,
    ItemsRequiringAttentionModal,
  },

  mixins: [makeToastMixin],

  props: {
    hideButton: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Continue Checkout',
    },
  },

  data() {
    return {
      thisUid: this._uid,
      invoiceId: null,
      verifySlide: 'form_the_company__verify_and_file__online_final_verify_checkout',
    }
  },

  computed: {
    ...mapGetters('checkout', [
      'cartItems',
      'cartItemsTotal',
      'cartEmpty',
      'processingCheckout',
      'formationProductInCart',
    ]),
    ...mapGetters('stageline', [
      'company',
      'isStagelineRoute',
      'isStagelineNonCheckoutRoute',
      'currentSlide',
    ]),
    ...mapGetters('account', ['missingContactInformation']),
    checkoutButtonText() {
      if (this.missingContactInformation) {
        return 'Confirm Info'
      }
      if (this.formationProductInCart) {
        return 'Continue to Checkout'
      }
      return 'Checkout'
    },
    displayIsMobile() {
      return this.$mq !== 'lg'
    },
  },

  async mounted() {
    while(this.company == null) {
      await new Promise( r => setTimeout(r, 200) )
    }
  },

  methods: {
    ...mapActions('checkout', ['removeFromCart']),
    ...mapActions('stageline', ['navigateToAndCompleteStageSlidesBeforeEndpoint']),
    rootEmit(event) {
      this.$root.$emit(event)
    },
    async determineCheckoutModal() {
      if (this.missingContactInformation) {
        const ref = `collectAccountInfoModal-${this.thisUid}`
        this.$refs[ref].show()
      } else if (this.formationProductInCart) {
        // No need to re-navigate to the VTO slide if already there
        if (this.currentSlide.name !== this.verifySlide)
          await this.navigateToAndCompleteStageSlidesBeforeEndpoint(this.verifySlide)
        else {
          this.successToast('Info', 'Please verify your order to checkout.')
        }
      } else {
        this.showCheckoutModal()
      }
    },
    showCheckoutModal() {
      this.$refs['checkout-modal'].show()
    },
    showItemsRequiringAttentionModal(invoice) {
      this.invoiceId = invoice.id
      this.$refs['items-requiring-attention-modal'].show()
    },
  },
}
</script>

<style scoped lang="scss">
  #nav-cart-component.cart-desktop {
    border: none !important;

    .cart-title {
      font-size: 22px;
      font-weight: 700;
      margin: 0;
    }
    hr {
      margin: 15px 0;
    }
    .company-name {
      margin: 0 0 15px 0;
      font-size: 1.1em;
      color: $ct-ui-color-15;
    }
    .card-body {
      padding: 1em 2.25em;
      width: 330px;
      .cart-items {
      }
      * {
        white-space: normal;
      }
      small {
        margin-top: 10px;
      }
    }
    .cart-button {
      margin: 20px 0 0 0;
      .nav-cart-button {
        pointer-events: auto;
      }
    }
    .total {
      padding-right: 1.25em;
    }
    hr {
      color: black;
    }
  }

  #nav-cart-component.cart-mobile {
    border: none !important;
    font-weight: 400;
    .cart-title {
      font-size: 22px;
      font-weight: 600;
      margin: 0;
    }
    hr {
      margin: 15px 0;
    }
    .company-name {
      margin: 0 0 15px 0;
      font-size: 1.1rem;
      color: $ct-ui-color-15;
    }
    .card-body {
      padding: 0.75em 1.25em;
      width: 330px;
      .cart-items {
      }
      * {
        white-space: normal;
      }
      small {
        margin-top: 10px;
      }
    }
    .cart-button {
      margin: 20px 0 0 0;
      display: flex;
      justify-content: center;
      .nav-cart-button {
        flex-grow: 1;
        pointer-events: auto;
        padding: 8px 20px;
        background: $primary;
        border-color: $primary;
      }
    }
    .total {
      padding-right: 1.25em;
    }
    hr {
      color: black;
    }
  }

  @media only screen and (max-width: 660px) {
    #nav-cart-component .card-body {
        width: 268px !important;
    }
  }
</style>
