<template>
  <div>
    <ct-table
      v-if="whitelistedIps?.length > 0"
      :is-paginated="false"
      :fields="whitelistedIpsDefinition.columns"
      :items="whitelistedIps"
      :loaded="true"
      :table-definition="whitelistedIpsDefinition"
    >
      <template v-slot:cell(description)="row">
        {{ row.item.description }}
      </template>
      <template v-slot:cell(ipAddress)="row">
        <p style="word-break: break-all">
          {{ row.item.ip }}
          <b-button
            variant="link"
            class="p-0 pl-2"
            size="sm"
            aria-label="Copy to Clipboard"
            @click="copyToClipboard(row.item.ip, null, successToast, errorToast)"
          >
            <copy-icon />
          </b-button>
        </p>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          variant="link"
          class="m-0 m-0 pl-0 pt-0 pb-0"
          aria-lebel="Edit button"
          @click="updateWhitelistedIp(row.item)"
        >
          <fa-icon icon="edit" />
          Edit
        </b-button>
        <b-button
          variant="link"
          class="danger m-0 pr-0 pt-0 pb-0"
          aria-label="Delete button"
          @click="destroyWhitelistedIp(row.item.id)"
        >
          <fa-icon icon="trash" />
          Delete
        </b-button>
      </template>
    </ct-table>
    <div v-if="!whitelistedIps?.length" class="text-center">
      <api-management-empty-state
        title="The list of permitted IPs is empty"
        text="Click the button below to add your first IP address."
        :cta-clicked="createWhitelistedIp"
        cta-text="Add IP"
      />
    </div>
  </div>
</template>

<script>
import { formatDateString } from '@/common/modules/formatters'
import { copyClipboardMixin } from '@/mixins/copyClipboardMixin'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  components: {
    ApiManagementEmptyState: () => import('@/pages/APIManagement/APIManagementEmptyState.vue'),
    CopyIcon: () => import('@images/ui/copy-icon.svg'),
    CtTable: () => import('@/components/shared/CtTable.vue'),
  },
  mixins: [copyClipboardMixin, makeToastMixin],
  props: {
    createWhitelistedIp: { required: true, type: Function },
    destroyWhitelistedIp: { required: true, type: Function },
    updateWhitelistedIp: { required: true, type: Function },
    whitelistedIps: { required: true, type: Array },
  },
  computed: {
    whitelistedIpsDefinition() {
      return {
        columns: [
          { key: 'description' },
          { key: 'ipAddress' },
          { key: 'createdAt', formatter: value => formatDateString(value) },
          { key: 'actions', selectable: true, label: '', headerTitle: 'Actions' },
        ],
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import 'api-management-shared-styles';
</style>
