var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.vehicle
    ? _c(
        "b-modal",
        {
          attrs: {
            id: "add-vehicle-service-modal",
            title: "Add Service",
            centered: "",
            size: "lg",
            "hide-footer": "",
          },
          on: { show: _vm.getFilingProducts, hide: _vm.hide },
        },
        [
          _c("template", { slot: "modal-title" }, [
            _c("h3", { staticClass: "no-padd no-margin" }, [
              _vm._v(
                "\n      Add Services | " +
                  _vm._s(_vm.vehicle.make) +
                  " " +
                  _vm._s(_vm.vehicle.model) +
                  "\n    "
              ),
            ]),
          ]),
          !_vm.loaded ? _c("ct-centered-spinner") : _vm._e(),
          _vm.loaded
            ? _c("div", [
                !_vm.serviceToAdd
                  ? _c(
                      "div",
                      { staticClass: "padding-bottom-1-5rem" },
                      [
                        _vm.vehicleProducts.length === 0
                          ? _c("div", [
                              _c("p", [
                                _vm._v(
                                  "\n          Please complete vehicle registration before adding new products.\n        "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "\n          Ensure the registration contains a "
                                ),
                                _c("em", [_vm._v("VIN")]),
                                _vm._v(".\n        "),
                              ]),
                            ])
                          : _c("div", [
                              _c("h3", [
                                _vm._v(
                                  "\n          Select a product\n        "
                                ),
                              ]),
                            ]),
                        _c(
                          "b-list-group",
                          {
                            staticClass:
                              "w-100 pointer product-categories-list",
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("selection-cards", {
                                  attrs: { items: _vm.vehicleProducts },
                                  on: { chosen: _vm.selectedService },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.serviceToAdd && !_vm.confirmOrder
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "button-container" },
                          [
                            _c(
                              "b-link",
                              {
                                staticClass: "py-0",
                                attrs: {
                                  variant: "link",
                                  "aria-label": "Add Another Product link",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.serviceToAdd = null
                                  },
                                },
                              },
                              [
                                _c("fa-icon", {
                                  attrs: { icon: "chevron-left" },
                                }),
                                _vm._v(
                                  "\n          Back / Add Another Product\n        "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.isOrgPlate()
                          ? _c(
                              "div",
                              [
                                _c(
                                  "h5",
                                  { staticClass: "padding-top-1-5rem" },
                                  [
                                    _vm._v(
                                      "\n          Choose your sponsor plate design\n        "
                                    ),
                                  ]
                                ),
                                _c("p", [
                                  _c("em", [
                                    _vm._v(
                                      "\n            Note: Processing times can take several months depending on current stock of the requested plate.\n          "
                                    ),
                                  ]),
                                ]),
                                _c("multi-select", {
                                  staticClass: "padding-bottom-1-5rem",
                                  attrs: {
                                    options: _vm.vehicleOrgPlates,
                                    label: "plate_name",
                                    "allow-empty": false,
                                    "deselect-label": "",
                                    "select-label": "",
                                    placeholder: "Select one",
                                  },
                                  on: { select: _vm.updateVehicleOrgPlate },
                                  model: {
                                    value: _vm.selectedPlate,
                                    callback: function ($$v) {
                                      _vm.selectedPlate = $$v
                                    },
                                    expression: "selectedPlate",
                                  },
                                }),
                                _vm.selectedPlate
                                  ? _c("filing-checkout", {
                                      attrs: {
                                        company: _vm.currentCompany,
                                        product: _vm.serviceToAdd,
                                      },
                                      on: { "product-added": _vm.productAdded },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("filing-checkout", {
                              attrs: {
                                company: _vm.currentCompany,
                                product: _vm.serviceToAdd,
                              },
                              on: { "product-added": _vm.productAdded },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.confirmOrder
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "button-container" },
                          [
                            _c(
                              "b-link",
                              {
                                staticClass: "py-0",
                                attrs: {
                                  variant: "link",
                                  "aria-label": "Order Another Product link",
                                },
                                on: { click: _vm.resetForm },
                              },
                              [
                                _c("fa-icon", {
                                  attrs: { icon: "chevron-left" },
                                }),
                                _vm._v(
                                  "\n          Back / Order Another Product\n        "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("vehicle-additional-service-confirmation", {
                          attrs: { invoice: _vm.invoice },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _vm.readyToCheckout()
                          ? _c("payment-method-options")
                          : _vm._e(),
                        _c("cart", {
                          attrs: {
                            "processing-checkout": _vm.processingCheckout,
                            "button-text": "Checkout",
                          },
                        }),
                      ],
                      1
                    ),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }