var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "masthead",
        {
          attrs: { title: "Filing Orders" },
          on: { changed: _vm.searchChanged },
        },
        [
          _c("span"),
          _c(
            "b-button",
            {
              staticClass: "rounded",
              attrs: { disabled: !_vm.selection.length > 0, variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.showCancelModal()
                },
              },
            },
            [_vm._v("\n      Cancel Selected\n    ")]
          ),
        ],
        1
      ),
      _c(
        "b-tabs",
        {
          model: {
            value: _vm.tabIndex,
            callback: function ($$v) {
              _vm.tabIndex = $$v
            },
            expression: "tabIndex",
          },
        },
        [
          _c(
            "b-tab",
            [
              _c("template", { slot: "title" }, [
                _vm._v("\n        Pending Orders\n      "),
              ]),
              _c(
                "ajax-table",
                {
                  ref: "filingsTable",
                  attrs: {
                    busy: _vm.isBusy,
                    "table-definition": _vm.tableDefinition,
                    "select-mode": "multi",
                    selectable: true,
                    "selectable-function": _vm.selectableFunction,
                    "no-local-sorting": true,
                    "sticky-header": false,
                    responsive: false,
                    "empty-text":
                      "You're all caught up! Past orders can be viewed by selecting the Finalized Orders tab.",
                  },
                  on: {
                    "update:busy": function ($event) {
                      _vm.isBusy = $event
                    },
                    "selection-changed": _vm.selectionEvent,
                    loaded: _vm.onLoad,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(company)",
                      fn: function (data) {
                        return [
                          _c("div", [_vm._v(_vm._s(data.item.company.name))]),
                        ]
                      },
                    },
                    {
                      key: "cell(jurisdiction)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.getFilingJurisdiction(data.item))
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(estimate)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(_vm.formatEstimate(data.item))),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(type)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _c("div", [_vm._v(_vm._s(data.item.product.name))]),
                            data.item.sub_registration &&
                            data.item.sub_registration.kind === "dba"
                              ? _c("div", [
                                  _c("small", { staticClass: "text-muted" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          data.item.sub_registration.name
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                          _vm.vipFiling(data.item)
                            ? _c("div", { staticClass: "vip-badge-group" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "badge badge-warning vip-badge",
                                  },
                                  [_vm._v("\n              VIP\n            ")]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "row-details",
                      fn: function (data) {
                        return [
                          _vm.isSupportedByTracker(data.item)
                            ? _c("filing-tracker", {
                                attrs: {
                                  status: data.item.status,
                                  "filing-name": data.item.product.filing_name,
                                  estimate: _vm.formatEstimate(data.item),
                                  steps: _vm.trackerSteps,
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(status)",
                      fn: function (data) {
                        return [
                          _vm.isSupportedByTracker(data.item)
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "toggle-tracker",
                                  attrs: {
                                    variant: "link",
                                    "aria-label": "toggle tracker",
                                  },
                                  on: { click: data.toggleDetails },
                                },
                                [
                                  _vm._v("\n            Tracker\n            "),
                                  data.detailsShowing
                                    ? _c("b-icon-chevron-up")
                                    : _c("b-icon-chevron-down"),
                                ],
                                1
                              )
                            : _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.startCase(data.item)) +
                                    "\n          "
                                ),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "cell(actions)",
                      fn: function (data) {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                to: {
                                  name: "filingStatus",
                                  params: {
                                    orderId: data.item.order_id,
                                    orderItemId: data.item.id,
                                  },
                                },
                                variant: "link",
                                "aria-label": "View button",
                              },
                            },
                            [_vm._v("\n            View\n          ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "head(estimate)" }, [
                    _c(
                      "div",
                      { staticClass: "tooltip-header-cell" },
                      [
                        _vm._v("\n            Estimate\n            "),
                        _c("feather-icon", {
                          staticClass: "tooltip-icon",
                          attrs: {
                            id: `estimate-tooltip`,
                            type: "help-circle",
                          },
                        }),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: `estimate-tooltip`,
                              triggers: "hover",
                            },
                          },
                          [
                            _vm._v(
                              "\n              This column represents the estimated time to completion after filing with the state. Actual timelines may vary.\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("template", { slot: "head(status)" }, [
                    _c(
                      "div",
                      { staticClass: "tooltip-header-cell" },
                      [
                        _vm._v("\n            Status\n            "),
                        _c("feather-icon", {
                          staticClass: "tooltip-icon",
                          attrs: { id: `status-tooltip`, type: "help-circle" },
                        }),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: `status-tooltip`,
                              triggers: "hover",
                            },
                          },
                          [
                            _vm._v(
                              "\n              Watch the status of your filing by following along on the tracker. Once your filing is finished, this will move to your Finalized Orders tab above.\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c("cancel-filings-modal", {
                attrs: { bus: _vm.bus },
                on: { completed: _vm.filingsCancelCompleted },
              }),
            ],
            2
          ),
          _c(
            "b-tab",
            [
              _c("template", { slot: "title" }, [
                _vm._v("\n        Finalized Orders\n      "),
              ]),
              _c(
                "ajax-table",
                {
                  ref: "completedFilingsTable",
                  attrs: {
                    busy: _vm.isBusy,
                    "table-definition": _vm.completedTableDefinition,
                    "select-mode": "multi",
                    selectable: false,
                    "no-local-sorting": true,
                    "selectable-function": _vm.selectableFunction,
                    "empty-text": "No completed orders found!",
                  },
                  on: {
                    "update:busy": function ($event) {
                      _vm.isBusy = $event
                    },
                    "selection-changed": _vm.selectionEvent,
                    loaded: _vm.onLoadCompleted,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(company)",
                      fn: function (data) {
                        return [
                          _c("div", [_vm._v(_vm._s(data.item.company.name))]),
                        ]
                      },
                    },
                    {
                      key: "cell(jurisdiction)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.getFilingJurisdiction(data.item))
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(estimate)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(_vm.formatEstimate(data.item))),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(type)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _c("div", [_vm._v(_vm._s(data.item.product.name))]),
                            data.item.sub_registration &&
                            data.item.sub_registration.kind === "dba"
                              ? _c("div", [
                                  _c("small", { staticClass: "text-muted" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          data.item.sub_registration.name
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                          _vm.vipFiling(data.item)
                            ? _c("div", { staticClass: "vip-badge-group" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "badge badge-warning vip-badge",
                                  },
                                  [_vm._v("\n              VIP\n            ")]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(status)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.startCase(data.item)) +
                                "\n          "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(actions)",
                      fn: function (data) {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                to: {
                                  name: "filingStatus",
                                  params: {
                                    orderId: data.item.order_id,
                                    orderItemId: data.item.id,
                                  },
                                },
                                variant: "link",
                                "aria-label": "View button",
                              },
                            },
                            [_vm._v("\n            View\n          ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "head(status)" }, [
                    _c("div", [_vm._v("\n            Status\n          ")]),
                  ]),
                ],
                2
              ),
              _c("cancel-filings-modal", {
                attrs: { bus: _vm.bus },
                on: { completed: _vm.filingsCancelCompleted },
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }