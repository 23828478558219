var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "b-container",
        { staticClass: "websites-page-component" },
        [
          _c("h1", [_vm._v("Websites")]),
          _c(
            "div",
            { staticClass: "main-content" },
            [
              _c(
                "b-row",
                [
                  _c("add-domains", { attrs: { domains: _vm.domains } }),
                  _c("masthead", {
                    staticClass: "websites-masthead-mobile col-lg-6 col-md-12",
                    attrs: { "group-buttons": "" },
                    on: { changed: _vm.searchChanged },
                  }),
                ],
                1
              ),
              _c("domain-list", {
                attrs: {
                  domains: _vm.validDomains,
                  loading: _vm.loading,
                  cards: _vm.cards,
                  "search-string": _vm.query,
                },
                on: {
                  "manage-domain": _vm.manageDomain,
                  "manage-email": _vm.manageEmail,
                  "site-builder": _vm.showSiteBuilderModal,
                  "lock-domain": _vm.showDomainLockModal,
                  "renew-domain": _vm.showDomainRenewModal,
                  "refresh-domains": _vm.refreshDomains,
                },
              }),
            ],
            1
          ),
          _c("domain-lock-modal", {
            attrs: { "selected-domain": _vm.selectedDomain },
            on: {
              cancel: _vm.cancelDomainLockModal,
              ok: _vm.confirmDomainLockModal,
            },
          }),
          _c("domain-renew-modal", {
            attrs: {
              "renew-price": _vm.selectedRenewPrice,
              loading: _vm.loadingRenew,
              "show-payment-select": _vm.showPaymentSelect,
              "selected-payment-method": _vm.selectedPaymentMethod,
              "auto-pay-id": _vm.selectedDomain?.autopay_id,
            },
            on: { renew: _vm.renew, cancel: _vm.hideDomainRenewModal },
          }),
          _c("hosting-login-info-modal", {
            attrs: { "hosting-info": _vm.selectedDomain?.domain_hosting_info },
            on: {
              close: _vm.closeHostingInfoModal,
              "website-login-interaction": _vm.logWebsiteLoginInteraction,
            },
          }),
        ],
        1
      )
    : _c("ct-centered-spinner")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }