<template>
  <div class="cancel-compliance">
    <div class="nav-bar row w-100 d-flex flex-row-reverse align-items-center pr-3">
      <a :href="loginUrl" class="nav-link" aria-label="Sign In Link">
        <b-img
          src="/images/payments/log-in.svg"
          alt="Sign In"
          class="mr-1" />
        Sign In
      </a>
    </div>
    <div class="website-logo">
        <b-img
          v-if="websiteImgSrc"
          :src="websiteImgSrc"
          alt="Website logo"
        />
    </div>
    <div v-if="cancelConfirmed" class="cancel-confirmed">
      <h1 class="cancellation-title">Service Cancellation Requested</h1>
      <div class="cancel-message">
        <p>
          Your cancellation has been processed. <span v-if="canRefund">You’ll receive an email shortly with details about your ${{ calculateRate.toFixed(2) }} refund, which will be credited back to your original payment method.
          <strong>Refunds typically resolve quickly but may take up to 7-10 days to process.</strong></span>
        </p>
        <p>
          We’re sorry you're canceling your compliance service—please know we’re always here if you want us to file your reports again. Whether it’s saving time, reducing costs, or keeping track of filing deadlines, we’re ready to help your business!
        </p>
        <b-button
          class="custom-login-button"
          aria-label="Login Button"
          :href="loginUrl"
        >
          Log In <fa-icon :icon="['fas', 'arrow-right']" />
        </b-button>
      </div>
    </div>
    <div v-else-if="loaded && canCancel" class="cancel-service">
      <div class="header">
        <p class="cancel-service-title">Cancel Service</p>
        <p class="company-name">{{ companyName }}</p>
      </div>

      <div class="service-card">
        <b-row class="header-row">
          <b-col>
            Compliance Service and State Filing
          </b-col>
        </b-row>
        <b-row class="body-row">
          <b-col>
            Jurisdiction
          </b-col>
          <b-col class="text-right">
            {{ jurisdiction }}
          </b-col>
        </b-row>
        <b-row class="body-row">
          <b-col>
            Rate
          </b-col>
          <b-col class="text-right">
            ${{ calculateRate.toFixed(2) }}
          </b-col>
        </b-row>
        <b-row v-if="canRefund" class="body-row refund-row">
          <b-col>
            Refund
          </b-col>
          <b-col class="text-right">
            <b-img :src="cardLogo(invoice.brand)" class="mr-2" alt="Card Logo" />
            **{{ invoice.last4 }}
          </b-col>
        </b-row>
        <b-row :class="['body-row', { 'no-border': selectedReason === 'other' }]">
          <b-col>
            Reason for Cancellation
          </b-col>
          <b-col class="text-right">
            <select v-model="selectedReason" class="form-control select-box" aria-label="Reason for cancellation">
              <option disabled value="">Please select a reason</option>
              <option v-for="reason in reasons" :key="reason" :value="reason">{{ reason }}</option>
            </select>
          </b-col>
          <b-col v-if="selectedReason === 'Other'" cols="12" class="text-box">
            <textarea
              v-model="otherNote"
              class="form-control"
              maxlength="250"
              aria-label="Enter reason for cancellation"
            ></textarea>
            <div class="text-right text-muted">{{ remainingCharacters }} characters left</div>
          </b-col>
        </b-row>
      </div>

      <div class="warning-message">
        <h5>
          Heads up! Cancelling Compliance will result in...
        </h5>
        <ul>
          <li>
            You filing your own reports with the state.
          </li>
          <li>
            Potential fines if you miss the deadline or file incorrectly.
          </li>
          <li>
            One more thing you have to complete.
          </li>
        </ul>
        <span>Our service is hassle free and makes your life easier. <strong>Do you wish to continue?</strong></span>
      </div>

      <div class="action-buttons">
        <b-row>
          <b-col class="text-left">
            <b-button
              class="grey-button-outlined"
              variant="outline-secondary"
              aria-label="Keep Service Button"
              :href="loginUrl">
              Keep Service
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button
              class="custom-cancel-button"
              aria-label="Cancel Service Button"
              @click="confirmCancellation"
            >
              Cancel Service
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>

    <div v-else-if="loaded" class="cancel-confirmed">
      <span>No filings or active compliance service available for refund / cancel</span>
    </div>

    <div v-else class="nav-spinner-container">
      <ct-centered-spinner />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getCardLogo } from '@/common/modules/cc'
import { makeToastMixin } from '@/mixins/makeToastMixin'

const REFUND_STATUSES = [
  'pending',
  'awaiting-client-input',
  'renewal-filed-by-client',
  'not-filed-in-state',
  'new',
]

const characterLimit = 250

const LOGO_BUCKET_URL = "https://corptools-public-ui-assets.s3.us-west-2.amazonaws.com/websites"

export default {
  name: 'CancelService',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner.vue'),
  },
  mixins: [makeToastMixin],
  data() {
    return {
      activeFiling: null,
      loaded: false,
      selectedReason: '',
      reasons: [
        "No Longer Need Compliance Service",
        "Filing Compliance Myself",
        "Other",
      ],
      otherNote: '',
      cancelConfirmed: false,
      complianceService: null,
      companyName: '',
      complianceFilings: [],
      showModal: false,
      invoice: null,
      websiteImgSrc: null,
    }
  },
  computed: {
    calculateRate() {
      return this.activeFiling ? this.activeFiling.price + this.activeFiling.cost : 0
    },
    canRefund() {
      return this.invoice?.status === 'paid' &&
        this.invoice?.last4 &&
        REFUND_STATUSES.includes(this.activeFiling?.status)
    },
    canCancel() {
      return this.activeFiling || this.complianceService?.status === 'active'
    },
    jurisdiction() {
      return this.complianceService?.stateProvinceRegion
    },
    loginUrl() {
      return this.complianceService?.loginUrl || '/'
    },
    remainingCharacters () {
      return characterLimit - this.otherNote.length
    },
  },
  async mounted() {
    this.complianceService = await this.fetchService({
      params: {
        id: this.$route.params.id,
      },
    })
    this.getLogoImgSrc()

    if (!this.complianceService || this.complianceService.type !== 'compliance') {
      this.complianceService = null
      this.loaded = true
      return
    }

    this.complianceFilings = await this.fetchComplianceFilingsGuest({
      params: {
        service_ids: this.complianceService.id,
      },
    })
    this.companyName = this.complianceService?.companyName
    this.activeFiling = this.complianceFilings.find(svc => svc.registrationId === this.complianceService.objectId)
    if (this.activeFiling) {
      this.invoice = await this.fetchInvoiceFromOrderItem(this.activeFiling.id)
    }
    this.loaded = true
  },
  methods: {
    ...mapActions('orderItems', ['fetchComplianceFilingsGuest']),
    ...mapActions('invoices', ['fetchInvoiceFromOrderItem']),
    ...mapActions('services', ['cancelServiceAndFilingsGuest', 'fetchService']),
    async confirmCancellation() {
      this.loaded = false
      const params = this.getCombinedParams()

      const response = await this.cancelServiceAndFilingsGuest(params)

      if (response.success) {
        const cancelResponse = response?.result?.response?.cancel_response
        const refundResponse = response?.result?.response?.refund_response
        if (cancelResponse?.status === 200) {
          this.cancelConfirmed = true
        } else {
          this.errorToast(
            'Error',
            'Error canceling service / filing, please try again or contact customer service'
          )
        }

        if (!refundResponse) {
          this.errorToast(
            'Error',
            'Error processing refund, please try again or contact customer service'
          )
        }
      } else {
        this.errorToast('Error', 'Error canceling service / filing, please try again or contact customer service')
      }
      this.loaded = true
    },
    getCombinedParams() {
      const refundReason = this.getRefundReason()
      const cancellationReason =
        this.selectedReason === 'Other' ? this.otherNote : this.selectedReason

      const params = {
        cancel: {
          invoice_id: this.invoice?.id,
          refund_reason: this.canRefund ? refundReason : null,
          account_id: this.complianceService.accountId,
          service_ids: [this.complianceService.id],
          order_item_ids: this.activeFiling ? [this.activeFiling.id] : [],
          signature: '',
          note: null,
          cancellation_reason_detail: cancellationReason,
          selected_cancellation_reason_id: null,
        },
      }

      return params
    },
    getRefundReason() {
      return this.selectedReason === 'Other'
        ? `(Client Requested) ${this.selectedReason}${this.otherNote ? ` - ${this.otherNote}` : ''}`
        : `(Client Requested) ${this.selectedReason}`
    },
    cardLogo(brand) {
      return getCardLogo(brand)
    },
    getLogoImgSrc(){
      const name = this.complianceService?.url?.replace('www.', '')
      const src = `${LOGO_BUCKET_URL}/${name}.png`
      const img = new Image()
      img.src = src
      img.onload = () => {this.websiteImgSrc = src}
      img.onerror = () => {this.websiteImgSrc = null}
    },
  },
}
</script>

<style scoped>
.website-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
    margin-left: auto;
  }

  img {
    max-height: 85px;
    max-width: 50%;
  }
}

.cancel-compliance {
  position: fixed;
  left: 0 !important;
  right: 0 !important;
  width: 100%;
  height: 100%;
  margin-top: -1.25rem !important;
  overflow-y: auto;
}

.nav-bar {
  background-color: #000864;
  height: 3.5rem;
  margin: 0 !important;
}

.nav-link {
  color: #FFF;
  font-size: 0.9rem;
}

.nav-spinner-container {
  margin-top: 33vh;
}

.cancel-confirmed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 100%;
  max-width: 711px;
  margin: 0 auto;
  background-color: #F7F7FB;

  .cancellation-title {
    width: 100%;
    text-align: left;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .cancel-message {
    width: 100%;
    text-align: left;
    font-weight: 400;
  }

  .custom-login-button {
    background-color: #009FBF !important;
    border-radius: 5px !important;
    border: none;
    color: #FFFFFF;
  }
}

.cancel-service {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  .header {
    width: 100%;
    max-width: 711px;
    margin-top: 0;
    text-align: left;

    .cancel-service-title {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 0;
    }

    .company-name {
      margin-top: 0;
      font-size: 1.5rem;
    }
  }

  .warning-message {
    width: 100%;
    max-width: 711px;
    margin-bottom: 1rem;
    text-align: left;
    font-weight: 400;
  }

  .service-card, .refund-card {
    width: 100%;
    max-width: 711px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .header-row {
    font-weight: bold;
    font-size: 1.25rem;
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 1rem;
    margin: auto;
    align-items: center;
  }

  .body-row {
    border-bottom: 1px solid #ddd;
    width: 95%;
    padding: 1rem 0;
    margin: auto;
    align-items: center;
  }

  .refund-row {
    background-color: #F7F7FB;
  }

  .body-row:last-child {
    border-bottom: none;
  }

  .no-border {
    border-bottom: none;
  }

  .text-box {
    width: 95%;
    padding: 1rem;
    margin: auto;
    align-items: center;
  }

  .text-right {
    text-align: right;
  }

  .text-right.text-muted {
    margin-left: auto;
  }

  .form-control {
    width: 100%;
  }

  .select-box {
    min-height: unset !important;
    min-width: 350px;
    height: 45px;
  }

  .select-box:hover {
    cursor: pointer;
  }

  .action-buttons {
    margin: 2rem;
    width: 100%;
    max-width: 711px;
  }

  .grey-button-outlined {
    background-color: #FFFFFF !important;
    border-color: #CECED2 !important;
    border-radius: 5px;
    color: #4E4E52;
  }
  .grey-button-outlined:hover {
    background-color: #CDCDCD !important;
  }

  .custom-cancel-button {
    background-color: #FFFFFF;
    border-color: #CECED2;
    border-radius: 5px;
    color: #F93F36;
  }

  .custom-cancel-button:hover:not([disabled]) {
    background-color: #CECED2;
  }

  @media (max-width: 768px) {
    .select-box {
      min-width: 50px;
    }
  }
}
</style>
