import * as t from '@/store/mutations'
import http from '@/http'
import Vue from 'vue'
import store from '@/store'

const getDefaultState = () => {
  return {
    domainEmails: [],
    domainEmail: null,
    selectedEmail: null,
  }
}
const STATE = getDefaultState()


const GETTERS = {
  domainEmails: state => state.domainEmails,
  domainEmail: state => state.domainEmail,
  selectedEmail: (state) => state.selectedEmail,
}

const ACTIONS = {
  async fetchDomainEmails({ commit }, { domainId, setMostRecentDomain = false }) {
    const response = await http.get(`client/domains/${domainId}/domain_emails`)

    if (response.data.success ) {
      commit(t.SET_DOMAIN_EMAILS, response.data.response)
      if (setMostRecentDomain && response.data.response.length) {
        commit(t.SET_DOMAIN_EMAIL, response.data.response[0])
      } else if (!response.data.response.length) {
        commit(t.RESET_DOMAIN_EMAIL)
      }
    }

    return response
  },

  async fetchDomainEmail({ commit }, { domainId, domainEmailId }) {
    const response = await http.get(`client/domains/${domainId}/domain_emails/${domainEmailId}`)

    if (response.data.success) {
      commit(t.SET_DOMAIN_EMAIL, response.data.response)
      return response.data.response
    }
  },

  async fetchMagicLink({ _ }, { domainId, domainEmailId }) {
    const response = await http.get(`client/domains/${domainId}/domain_emails_login/${domainEmailId}`)

    if (response.data.success) {
      return response.data.response.url
    }
  },

  async createDomainEmail({ commit, rootGetters }, { domainId, emailUsername, password, afterFunction }) {
    try {
      const response = await http.post(`client/domains/${domainId}/domain_emails`, {
        username: emailUsername,
        password: password,
      })

      if (rootGetters['domains/isCpanelHostingAccount']) {
        if (response.data?.success) {
          const domainEmail = response.data.response
          commit(t.SET_DOMAIN_EMAIL, domainEmail) // Set email as current email.
          commit(t.ADD_DOMAIN_EMAIL, domainEmail) // Add email to list of emails for current domain.
          afterFunction(response.data)
        }
      } else {
        const callbackFunction = function (result) {
          if (result?.success) {
            const domainEmail = result
            commit(t.SET_DOMAIN_EMAIL, domainEmail)
            commit(t.ADD_DOMAIN_EMAIL, domainEmail)
            afterFunction(result)
          }
        }

        await store.dispatch("actionCable/addRequestCallbackFunction", {
          requestId: response.data.request_id,
          callbackFunction,
          requestName: "Create Domain Email",
        })
      }
    } catch (error) {
      return { success: false, error: error }
    }
  },
  async deleteDomainEmail({ commit, rootGetters }, { domainId, id, afterFunction }) {
    const response = await http.delete(`client/domains/${domainId}/domain_emails/${id}`)

    if (rootGetters['domains/isCpanelHostingAccount']) {
      if (response.data?.success) {
        commit(t.DELETE_DOMAIN_EMAIL, id)
        afterFunction(response.data)
      }
    } else {
      const callbackFunction = function (result) {
        if (result?.success) {
          commit(t.DELETE_DOMAIN_EMAIL, id)
          afterFunction(result)
        }
      }

      await store.dispatch("actionCable/addRequestCallbackFunction", {
        requestId: response.data.request_id,
        callbackFunction,
        requestName: "Delete Domain Email",
        showResponseToast: true,
      })
    }
  },
  async sendTestEmail({ _commit }, domainId, domainEmailId) {
    try {
      const response = await http.post(`client/domains/${domainId}/domain_emails_test/${domainEmailId}`)
      return response.data
    } catch (error) {
      return { success: false }
    }
  },
  async changeEmailPassword({ _commit, rootGetters }, { domainId, emailId, password, afterFunction }) {
    const response = await http.patch(`client/domains/${domainId}/domain_emails/${emailId}`, {
      password: password,
      sensitive: ["password"],
    })

    if (rootGetters['domains/isCpanelHostingAccount']) {
      afterFunction(response.data)
      return response
    }
    const callbackFunction = function(result) {
      afterFunction(result)
    }

    await store.dispatch("actionCable/addRequestCallbackFunction", { requestId: response.data.request_id, callbackFunction, requestName: "Update Domain Email Password" })
  },
  setSelectedEmail({ commit, _dispatch }, domain) {
    commit(t.SET_SELECTED_EMAIL, domain)
  },
  resetDomainEmail({ commit }) {
    commit(t.RESET_DOMAIN_EMAIL)
  },
}

const MUTATIONS = {
  [t.RESET_DOMAIN_EMAIL](state) {
    Object.assign(state, getDefaultState())
  },
  [t.SET_DOMAIN_EMAILS](state, domainEmails) {
    state.domainEmails = domainEmails
  },
  [t.SET_DOMAIN_EMAIL](state, domainEmail) {
    state.domainEmail = domainEmail
  },
  [t.ADD_DOMAIN_EMAIL](state, domainEmail) {
    Vue.set(state.domainEmails, state.domainEmails?.length || 0, domainEmail)
  },
  [t.UPDATE_DOMAIN_EMAIL](state, domainEmail) {
    const emailIndex = state.domainEmails.findIndex(email => email.id === domainEmail.id)
    Vue.set(state.domainEmails, emailIndex, domainEmail)
  },
  [t.DELETE_DOMAIN_EMAIL](state, domainEmailId) {
    const emails = state.domainEmails.filter(email => email.id !== domainEmailId)
    Vue.set(state, 'domainEmails', emails)
  },
  [t.SET_SELECTED_EMAIL](state, email) {
    state.selectedEmail = email
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
