var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-4", attrs: { id: "domain-addon-service-offer" } },
    [
      !_vm.settingUpAddonService
        ? _c("div", [
            _c("h4", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.hasUnredeemedVoucherForProductType ? "Setup" : "Get"
                  ) +
                  " " +
                  _vm._s(_vm.offerProductTitle) +
                  "\n    "
              ),
            ]),
            !_vm.hasUnredeemedVoucherForProductType
              ? _c("div", { domProps: { innerHTML: _vm._s(_vm.offerContent) } })
              : _c("div", [
                  _c("p", [
                    _vm._v("\n        You have an unredeemed voucher for "),
                    _c("strong", [_vm._v(_vm._s(_vm.offerProductTitle))]),
                    _vm._v("!\n      "),
                  ]),
                  _vm._m(0),
                ]),
            _c(
              "div",
              [
                _vm.phone() && !_vm.hasUnredeemedVoucherForProductType
                  ? _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("p", [_vm._v("Call us today to learn more!")]),
                        _c("feather-icon", { attrs: { type: "phone" } }),
                        _c("span", {
                          directives: [
                            {
                              name: "phone-number",
                              rawName: "v-phone-number",
                              value: _vm.phone(),
                              expression: "phone()",
                            },
                          ],
                          attrs: { title: "Telephone" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showAddServiceButton() && !_vm.loadingCheckout
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "d-flex align-items-end",
                        attrs: {
                          variant: "primary",
                          "aria-label": "Add Service button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setupCheckoutWithDomainAddonService()
                          },
                        },
                      },
                      [_vm._v("\n        Add Service\n      ")]
                    )
                  : _vm._e(),
                _vm.showSetupServiceButton() && !_vm.settingUpAddonService
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "d-flex align-items-end",
                        attrs: {
                          variant: "primary",
                          "aria-label": "Setup Service button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setup()
                          },
                        },
                      },
                      [_vm._v("\n        Setup Service\n      ")]
                    )
                  : _vm.loadingCheckout
                  ? _c("ct-centered-spinner", {
                      staticClass: "loading-spinner mb-5",
                    })
                  : _vm._e(),
                _vm.showAdditionalOffers || _vm.loadingCheckout
                  ? _c("domain-addon-additional-offers-modal", {
                      attrs: {
                        "domain-addon-service-type": _vm.productType,
                        "domain-addon-service-product":
                          _vm.domainAddonServiceProduct,
                        "domain-addon-other-service-product":
                          _vm.domainAddonOtherServiceProduct,
                        "domain-addon-ssl-service-product":
                          _vm.domainAddonSslServiceProduct,
                        "show-ssl-offer": !_vm.hasActiveSslService,
                      },
                      on: {
                        "continue-to-checkout": function ($event) {
                          return _vm.showDomainAddonCheckoutModal()
                        },
                        hide: function ($event) {
                          return _vm.showDomainAddonCheckoutModal()
                        },
                      },
                    })
                  : _vm._e(),
                !!_vm.domainAddonServiceProduct || _vm.loadingCheckout
                  ? _c("domain-addon-checkout-modal", {
                      attrs: { "domain-addon-service-type": _vm.productType },
                      on: {
                        "setup-addon-service": function ($event) {
                          return _vm.setup()
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _c(
            "div",
            [
              _c("ct-centered-spinner", { staticClass: "loading-spinner" }, [
                _c("p", { staticClass: "loading-text" }, [
                  _vm._v(
                    "\n        Setting up your new " +
                      _vm._s(_vm.getSetupServiceTitle()) +
                      " service, this can take a few minutes...\n      "
                  ),
                ]),
                _c("p", [
                  _vm.isCpanelHostingAccount
                    ? _c("em", [
                        _vm._v(
                          "\n          Please do not navigate away or refresh the page.\n        "
                        ),
                      ])
                    : _c("em", [
                        _vm._v(
                          "\n          Feel free to continue using the website.\n        "
                        ),
                      ]),
                ]),
              ]),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n        Click "),
      _c("strong", [_vm._v("Setup Service")]),
      _vm._v(" to setup your new service!\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }