<template>
  <div class="item-container m-3">
    <b-link
      :class="{ 'badge-border' : isShowBadge}"
      class="dashpanel-link"
      :aria-label="title + ' Link'"
      @click="logNavigation(linkTo, 'dashpanel', 'navigation', interaction)"
    >
      <b-badge
        v-if="isShowBadge"
        variant="danger"
        class="dashpanel-badge d-flex align-items-center justify-content-center"
      >
        {{ simplifiedNumber(badge) }}
      </b-badge>
      <div class="dashpanel-icon d-flex align-items-center justify-content-center mb-4">
        <b-img :src="'/images/dashpanel/' + typeIcons[type]" />
      </div>
      <div class="mb-5 label">
        {{ title }}
      </div>
    </b-link>
  </div>
</template>

<script>
import { simplifiedNumber } from '@/common/modules/strings'
import { logNavigationMixin } from '@/mixins/logNavigationMixin'

export default {
  name: 'DashpanelItem',
  mixins: [logNavigationMixin],
  props: {
    badge: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      typeLinks: {
        'unreadDocuments': 'documents',
        'unpaidInvoices': 'invoices',
        'servicesRequiringAttention': 'services-requiring-attention',
        'pendingFilings': 'pending-filings',
        'pendingVehicleOrders': 'vehicle-registrations/pending-orders',
        'incompleteStagelineStages': 'stageline-v2',
        'hireUs': 'hire-us',
        'faq': 'faq',
        'contactSupport': 'contact-support',
        'formsLibrary': 'make-a-filing/company-selection',
      },
      typeIcons: {
        'unreadDocuments': 'icon-unread-documents.svg',
        'unpaidInvoices': 'icon-unpaid-invoices.svg',
        'servicesRequiringAttention': 'icon-services-requiring-attention.svg',
        'pendingFilings': 'icon-filing-orders.svg',
        'pendingVehicleOrders': 'icon-vehicle-orders.svg',
        'incompleteStagelineStages': 'icon-manage-your-business.svg',
        'hireUs': 'icon-add-services.svg',
        'faq': 'icon-faq.svg',
        'contactSupport': 'icon-contact-support.svg',
        'formsLibrary': 'icon-forms-library.svg',
      },
    }
  },
  computed: {
    isShowBadge() {
      return this.badge > 0
    },
    interaction() {
      return { action: 'visit', to: this.linkTo, title: this.title }
    },
    linkTo() {
      return this.typeLinks[this.type]
    },
  },
  methods: {
    simplifiedNumber: simplifiedNumber,
  },
}
</script>

<style lang="scss" scoped>

.item-container{
  .dashpanel-link{
    border-radius: 4px;
    background-color: #F1F1F2;
    align-items: center;
    display: flex;

    &:hover {
      color: #0057ca !important;
      text-decoration: none;
      background: #f8f8f8;
    }
    .label {
      color: #030404;
    }
    .dashpanel-badge {
      background-color: #FF1800 !important;
      border-radius: 6.25rem;
    }
    .dashpanel-icon {
      background-color: white;
    }

  }

  .badge-border {
    border: 1px solid #F45151 !important;
  }
}

@media only screen and (min-width: 441px) {
  .item-container {
    .dashpanel-link {
      width: 18.75rem;
      height: 18.75rem;
      text-align: center;
      flex-direction: column;
      justify-content: flex-end;

      .dashpanel-icon {
        width: 150px;
        height: 150px;
        border-radius: 50%;
      }

      .dashpanel-badge {
        position: relative;
        top: -2.25rem !important;
        right: -9.25rem !important;
        font-size: 95%;
        min-width: 1.75rem;
        height: 1.75rem;
      }

      .label {
        line-height: 1.3rem;
      }
    }

    .badge-border {
      border: 1px solid #F45151 !important;
    }
  }
}

@media only screen and (max-width: 440px) {
 .item-container{
   width: 100%;
   justify-content: center;
   align-items: center;
   margin: 0 !important;

   .dashpanel-link {
     max-width: 400px;
     height: 80px;
     padding: 0.75rem;
     flex-direction: row; /* Mobile: Arrange icon, title, and badge in a row */
     align-items: center;
     justify-content: space-between;

     .dashpanel-icon {
       width: 60px;
       height: 60px;
       border-radius: 50%;
       margin-bottom: 0 !important;
     }
     .label {
       flex-grow: 1;
       text-align: left;
       font-size: 1rem;
       margin-left: 10px;
       margin-bottom: 0 !important;
     }
     .dashpanel-badge {
       font-size: 0.875rem;
       min-width: 1.5rem;
       height: 1.5rem;
       position: absolute;
       right: 2rem;
     }
     img {
       height: 40px;
     }
   }
 }
}
</style>
