<template>
  <div>
    <ct-centered-spinner v-if="loading" />
    <div v-else-if="noDomains" class="mt-1 text-center">
      No domains found
    </div>
    <template v-else>
      <b-card-group
        v-for="domain in filteredDomains"
        :key="domain.id"
        class="domain-card-group"
      >
        <b-card no-body>
          <b-card-body class="p-3">
            <b-container fluid>
              <b-row class="mb-0 ml-0">
                <b-col class="pl-0">
                  <b-row class="mb-0">
                    <b-col class="pl-0">
                      <h6 class="mt-1 mb-0">
                        <a :href="'https://' + domain.domain_name"
                           target="_blank"
                           :class="autopayClass(domain)"
                           :disabled="isProcessing(domain)"
                        >
                          <div class="text-nowrap">
                            <strong>
                              {{ displayedDomainName(domain) }}
                              <fa-icon icon="external-link-alt" />
                            </strong>
                          </div>
                        </a>
                      </h6>
                    </b-col>
                  </b-row>
                  <b-row class="mb-0">
                    <b-col class="pl-0">
                      <div class="dark-gray">
                        {{ companyNameFromId(domain.company_id) }}
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="!isExternal(domain) && !isProcessing(domain)" class="mb-0">
                    <b-col class="pl-0">
                      <div class="small-font">
                        <strong>Created<br> </strong> {{cardDate(domain.created_at)}}
                      </div>
                    </b-col>
                    <b-col class="pl-0">
                      <div class="small-font">
                        <strong>Expires<br> </strong> {{cardDate(domain.expire_date)}}
                      </div>
                    </b-col>
                    <b-col class="pl-0">
                      <div class="small-font">
                        <strong>Renews<br> </strong> {{cardDate(domain.renew_date)}}
                      </div>
                    </b-col>

                  </b-row>
                  <b-row v-else-if="isExternal(domain)">
                    <b-col class="pl-0">
                      <strong class="suspended">
                        External
                      </strong>
                    </b-col>
                  </b-row>
                  <b-row v-else-if="isProcessing(domain)">
                    <b-col class="pl-0">
                      <strong v-b-popover.hover.top="processingTooltip" class="processing">
                        Processing
                        <fa-icon size="lg" class="processing" icon="spinner" spin />
                      </strong>
                    </b-col>
                  </b-row>
                  <b-row v-if="!isExternal(domain) && !isProcessing(domain)" class="mb-0">
                    <div class="text-left">
                      <strong class="dark-gray small-font"> Auto Pay: </strong> {{ autoPayTableColumn(domain.autopay_id) }}
                      <b-icon-exclamation-triangle-fill v-if="!autoPayTableColumn(domain.autopay_id)"
                              v-b-popover.hover.top="autopayTooltip(domain)"
                              stacked class="warning-icon"
                      />
                    </div>
                  </b-row>
                </b-col>
                <div class="col-xl row-lg row-md row-sm mt-sm-1 mt-md-1 mt-lg-1">
                  <b-row class="mb-0 card-buttons">
                    <b-button
                      v-if="showRenew(domain)"
                      class="action-button"
                      variant="primary"
                      :disabled="isProcessing(domain)"
                      @click="$emit('renew-domain', domain)"
                    >
                      <h6 class="m-0"> Renew </h6>
                    </b-button>
                    <b-button
                      v-if="siteBuilderUrl(domain)"
                      class="action-button"
                      variant="outline-primary"
                      :disabled="isProcessing(domain)"
                      @click="$emit('site-builder', domain)"
                    >
                      <h6 class="m-0">
                        <b-icon icon="window"/>
                        SiteBuilder
                      </h6>
                    </b-button>
                    <b-button
                      class="action-button"
                      variant="outline-primary"
                      :disabled="isProcessing(domain)"
                      @click="$emit('manage-email', domain)"
                    >
                      <h6 class="m-0">
                        <b-icon icon="inbox"/>
                        Manage Email
                      </h6>
                    </b-button>
                    <b-button
                      class="action-button"
                      variant="outline-primary"
                      :disabled="isProcessing(domain)"
                      @click="$emit('manage-domain', domain)"
                    >
                      <h6 class="m-0">
                        <b-icon icon="globe2"/>
                        Manage Site
                      </h6>
                    </b-button>
                    <div v-if="showLock(domain)" class="mr-2" @click="$emit('lock-domain', domain)">
                      <fa-icon v-if="domain.status === 'external'"
                               icon="lock"
                               size="lg"
                               color="#bfbfbf"
                      />
                      <fa-icon v-else-if="domain.locked"
                               icon="lock"
                               size="lg"
                               class="icon-button"
                      />
                      <fa-icon v-else
                               icon="unlock"
                               size="lg"
                               class="icon-button"
                      />
                    </div>
                  </b-row>
                </div>
              </b-row>
            </b-container>
          </b-card-body>
        </b-card>
      </b-card-group>
    </template>
  </div>
</template>
<script>

import { formatDateString } from '@/common/modules/formatters'
import { mapGetters } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'

export default {
  name: 'DomainList',
  components: { CtCenteredSpinner },
  props: {
    domains: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    cards: {
      type: Array,
      default: null,
    },
    searchString: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      maxPeriod: 1, //TODO: might need to hook up to database to get max period based on TLD in the future
      waiting: false,
    }
  },
  computed: {
    ...mapGetters('companies', ['find']),
    ...mapGetters('companies', ['find']),
    displayedDomainName() {
      return domain => domain.domain_name?.length > 35 ? domain.domain_name.substring(0, 35) + '...' : domain.domain_name
    },
    showRenew() {
      return domain => !this.maxRenewable(domain) && (domain.status === 'active' || domain.status === 'suspended')
    },
    maxRenewable() {
      //TODO: might need to calculate differently for end-of-year renewals with beginning-of-year expirations
      return domain => ((new Date(domain.expire_date)).getFullYear() - (new Date()).getFullYear()) >= this.maxPeriod
    },
    noDomains() {
      return this.domains?.length === 0 || this.filteredDomains?.length === 0
    },
    filteredDomains() {
      let res = this.domains
      if (this.searchString) {
        res = this.domains.filter(domain => {
          if (domain.domain_name.toLowerCase().includes(this.searchString.toLowerCase())) return domain
        })
      }
      return res
    },
    autopayTooltip() {
      return domain => `Autopay is not set up. Without it, your domain will not auto-renew. Please set up autopay under the "Services" tab${this.maxRenewable(domain) ? '.' : ', or click "Renew" to renew your domain now.'}`
    },
    showLock(){
      return domain => !this.isExternal(domain) && !this.isDeleted(domain) && !this.isProcessing(domain)
    },
    isDeleted(){
      return domain => domain.status === 'deleted'
    },
    processingTooltip() {
      return `Your domain registration is currently processing and your management panel is unavailable at this time. Please allow a few minutes for us to finish your registration process and setup any addon services.`
    },
    isExternal(){
      return domain => domain.status === 'external'
    },
    isProcessing(){
      return domain => domain.status === 'processing'
    },
  },
  async mounted() {
    if(this.hasProcessingDomain()) {
      setTimeout(() => {
        this.refreshDomains()
      }, 5000)
    }
  },
  methods: {
    hasProcessingDomain() {
      return this.domains.some(item => item.status === 'processing')
    },
    refreshDomains() {
      if (!this.waiting && this.hasProcessingDomain()) {
        this.waiting = true
        setTimeout(() => {
          this.$emit('refresh-domains')
          this.waiting = false
          this.refreshDomains()
        }, 5000)
      }
    },
    companyNameFromId(id) {
      return this.find(id)?.name
    },
    cardDate(date) {
      return date === 'Invalid Date' ? '---' : this.formatDate(date)
    },
    siteBuilderUrl(item) {
      return item.domain_hosting_info?.sitebuilder_url
    },
    autoPayTableColumn(autopay_id) {
      const card = this.cards?.find(card => card.id === autopay_id)
      return card ? `CC ${card.last4}` : null
    },
    autopayClass(item) {
      return item.autopay_id || item.status === 'external' ? 'not-suspended' : 'suspended'
    },
    formatDate: formatDateString,
  },
}
</script>
<style lang="scss" scoped>
  .btn-outline-primary {
    color: #6a6a6a;
    border-color: #6a6a6a;

    &:hover, &:active {
      background-color: #2E3798 !important;
      border-color: #2E3798 !important;
      color: #FFFFFF !important;
    }
  }
  .domain-card-group {
    margin-bottom: 1em;

    button {
      margin: 10px 0;
    }

    card {
      padding: 10px;
    }

    .col-xl {
      padding: 0;
    }

    .card-body {
      padding: 1rem 2rem !important;

      h5 {
        font-size: 1rem;
      }
      .card-buttons{
        justify-content: center;
      }
    }

    .light-gray {
      color: #6A6A6A;
    }

    .small-font {
      font-size: 1rem;

      @media (max-width: 768px) {
        font-size: 11px;
      }
    }

    .action-button {
      padding: 6px;
      margin-right: 8px;
    }

    .icon-button {
      color: #2E3798;

      &:hover {
        color: #232a79;
        cursor: pointer;
      }
    }

    .warning-icon {
      color: #fd7e14;
      width: 18px;
      height: 18px;
    }
  }
</style>
