var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isBannerVisible
        ? _c(
            "alert-banner",
            { staticClass: "boi-banner", attrs: { id: "alert-banner" } },
            [
              _vm._v("\n    Stay informed about the latest updates to\n    "),
              _c(
                "div",
                {
                  staticClass: "pl-1 pr-1",
                  on: {
                    click: function ($event) {
                      return _vm.logBoiBannerInteraction("boi-faqs-page")
                    },
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: "/faq/621379ca-3fb0-40e4-be32-a95a2ef2e11f",
                      },
                    },
                    [_vm._v("\n        BOI filing requirements.\n      ")]
                  ),
                ],
                1
              ),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.boi-confirmation-modal",
                      modifiers: { "boi-confirmation-modal": true },
                    },
                  ],
                  staticClass: "close close-banner",
                  attrs: { type: "button", "aria-label": "Dismiss BOI Banner" },
                },
                [_vm._v("\n      x\n    ")]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "BoiConfirmationModal",
          attrs: {
            id: "boi-confirmation-modal",
            title: "Dismiss BOI Banner",
            centered: "",
            size: "md",
            "hide-footer": "",
          },
        },
        [
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "mb-2 text-center" }, [
              _c("p", [
                _vm._v(
                  "Are you sure you want this banner dismissed from your account?"
                ),
              ]),
            ]),
            _c("br"),
            _c("div", { staticClass: "text-center btn-container" }, [
              _c(
                "button",
                {
                  staticClass: "btn mr-2 btn-secondary",
                  attrs: { variant: "secondary" },
                  on: { click: _vm.closeBoiConfirmationModal },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary text-nowrap",
                  attrs: { variant: "primary" },
                  on: { click: _vm.confirmDismissal },
                },
                [_vm._v("Confirm")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }