import toCamelCase from 'lodash/camelCase'
import genId from 'lodash/uniqueId'
import isFunction from 'lodash/isFunction'
import bindFunc from 'lodash/bind'
import uniqArr from 'lodash/uniq'
import compactArr from 'lodash/compact'

const logicProcessors = {
  // Block: The logic processing block defined in the GT definition for a specified associated field.
  // Callback: The method defined by the 'action' key bound to a callback function.
  _computeComparison(block, callback) {
    const rhsParts = block.field.split('#')
    let rhsPath = rhsParts[0]
    const rhsProp = rhsParts[1] || 'value'

    if (rhsPath.indexOf('self') === 0) {
      rhsPath = this.path + rhsPath.substr(4)
    }

    rhsPath = resolveObjPathDotDot(rhsPath)

    const rhsField = this.findRelativeWithPath(rhsPath)
    const cb = bindFunc(callback, this)

    if (typeof(rhsField) !== 'undefined') {
      let lhs = block.value
      const rhs = objectPropByDots(rhsField, rhsProp)

      if (typeof(lhs) === 'undefined' && typeof(block.valueProp) !== 'undefined') {
        const lhsParts = block.valueProp.split('#')
        let lhsPath = lhsParts[0]
        const lhsProp = lhsParts[1] || 'value'

        if (lhsPath.indexOf('self') === 0) {
          lhsPath = this.path + lhsPath.substr(4)
        }

        lhsPath = resolveObjPathDotDot(lhsPath)

        const valuePropField = this.findRelativeWithPath(lhsPath)

        if (typeof(valuePropField) !== 'undefined') {
          lhs = objectPropByDots(valuePropField, lhsProp)
        }
      }

      const results = {
        eq: (rhs === lhs),
        neq: (rhs !== lhs),
        gt: (rhs > lhs),
        lt: (rhs < lhs),
        gte: (rhs >= lhs),
        lte: (rhs <= lhs),
        notnull: (!!rhs),
        null: (!rhs),
        in: (Array.isArray(lhs) && lhs.indexOf(rhs) > -1),
      }

      // Here we accommodate "onlyIf". This is great for situations where you have multiple fields
      // of the same type (eg: Official) but you only want your action to apply to a subset of them
      // (Member, Manager, etc). Example: "Proceed only if field is Official and title is Manager".
      let shouldProceed = true
      if (block.onlyIf) {
        for (let [name, val] of Object.entries(block.onlyIf)) {
          if (objectPropByDots(this, name) !== val) {
            shouldProceed = false
          }
        }
      }

      if (results.hasOwnProperty(block.comparator) && shouldProceed) {
        cb(results[block.comparator])
      }
    }
  },

  show(block, comparisonResult) {
    this.isVisible = comparisonResult
  },

  hide(block, comparisonResult) {
    this.isVisible = !comparisonResult
  },

  valid(block, comparisonResult) {
    this.isValid = comparisonResult
  },

  invalid(block, comparisonResult) {
    this.isValid = !comparisonResult
  },

  setValue(block, comparisonResult) {
    const originalValueKey = '__originalValue'
    let newVal = this._value

    if (comparisonResult) {
      if (!this.hasOwnProperty(originalValueKey)) {
        this[originalValueKey] = this._value
      }

      let val = block.newValue

      if (typeof(val) === 'undefined') {
        const valuePropField = this.findRelativeWithPath(block.newValueProp)

        if (typeof(valuePropField) !== 'undefined') {
          val = valuePropField.value
        }
      }

      newVal = resolveAtRef(this, val)
    } else {
      if (this.hasOwnProperty(originalValueKey)) {
        newVal = this[originalValueKey]
      }
    }


    this.value = newVal
  },

  setProperty(block, comparisonResult) {
    if (typeof(block.params) === 'object') {
      for (let [name, val] of Object.entries(block.params)) {
        val = resolveAtRef(this, val)

        const originalValueKey = `__originalPropertyValueFor${name}`

        const keyPath = name.split('.')
        const lastKey = keyPath.pop()

        const ref = keyPath.reduce((acc, key) => {
          if (acc) {
            return acc[key]
          }
        }, this)

        if (typeof(ref) !== 'undefined') {
          let newVal = ref[lastKey]

          if (comparisonResult) {
            if (this.hasOwnProperty(originalValueKey)) {
              this[originalValueKey] = ref[lastKey]
            }

            newVal = val
          } else {
            if (this.hasOwnProperty(originalValueKey) && ref[lastKey] === val) {
              newVal = this[originalValueKey]
            }
          }

          if (!Object.is(ref[lastKey], newVal)) {
            this.setPropValue(name === 'value' ? '_value' : name, newVal)
          }
        }
      }
    }
  },
}

function flattenObj(obj, prefix = '', acc = {}) {
  if (typeof(obj) !== 'object') {
    return Object.assign(acc, { [prefix]: obj })
  }

  for (let [key, val] of Object.entries(obj || {})) {
    flattenObj(val, `${prefix}/${key}`, acc)
  }

  return acc
}

function objectPropByDots(obj, dots) {
  return dots.split('.').reduce((acc, key) => {
    if (acc) {
      return acc[key]
    }
  }, obj)
}

function resolveObjPathDotDot(path) {
  return path.split('/').reduce((acc, part) => {
    if (part === '..') {
      acc.pop()
    } else {
      acc.push(part)
    }

    return acc
  }, []).join('/')
}

function resolveAtRef(field, ref) {
  if (typeof(ref) === 'string' && ref.indexOf('@') === 0 && ref.indexOf('@@') !== 0) {
    const otherParts = ref.substr(1).split('#')
    let otherPath = otherParts[0]
    const otherProp = otherParts[1] || 'value'

    if (otherPath.indexOf('self') === 0) {
      otherPath = field.path + otherPath.substr(4)
    }

    otherPath = resolveObjPathDotDot(otherPath)

    const otherField = field.findRelativeWithPath(otherPath)

    if (typeof(otherField) !== 'undefined') {
      return objectPropByDots(otherField, otherProp)
    }
  }

  return ref
}

export class SchemaFormField {
  constructor(definition, pathPrefix, root, parent) {
    this.id = genId()
    this.root = root === true ? this : root
    this.parent = parent

    this.isVisible = true
    this.isValid = true

    // Company Mailing Address and Company Physical Address were not showing their validations
    // and the reason was because in SchemaFormAddressField the hasValidationMessage computed property
    // was not getting refreshed because there was no validationError attached to field on load
    // so it was never checking again when the field changed so the solution here is to
    // just define it on initial render
    this.validationError = null

    this.definition = definition
    this.pathPrefix = pathPrefix || ''

    this.dirty = false

    if (root === true) {
      this.path = ''
    } else {
      this.path = `${this.pathPrefix}/${this.definition.name}`
    }
    if (Array.isArray(this.definition.fields)) {
      this.children = this.definition.fields.map(field => {
        return new SchemaFormField(field, this.path, this.root, this)
      })
    } else {
      this.children = []
    }

    if (this.definition.type === 'hidden') {
      this._value = this.definition.value
      this.isVisible = false
    }

    // Change all keys in the field to camelcase, except for value, change that to _value
    for (let [key, val] of Object.entries(this.definition)) {
      if (key !== 'fields') {
        if (key === 'value') {
          key = '_value'
        } else {
          key = toCamelCase(key)
        }

        this[key] = val
      }
    }
  }

  /**
   * Duplicates SchemaFormField, adds to clones, adds to parents children
   * then rebuilds root path cache and resolves child logic dependencies
   * @param {Integer} [keyIndex=genId()]
   * @returns {SchemaFormField} dup The duplicated SchemaFormField
   */
  duplicate(keyIndex = genId()) {
    if (!Array.isArray(this.clones)) {
      this.clones = []
    }
    const name = `${this.name || this.originalName}__${keyIndex}`

    const dup = new SchemaFormField(
      Object.assign({}, this.definition, { name }),
      this.pathPrefix,
      this.root,
      this.parent
    )

    this.clones.push(dup)
    dup.clonedFrom = this
    dup.originalName = this.originalName || this.name
    dup.value = this.type === 'object' ? {} : null

    const idx = this.parent.children.findIndex(child => child.id === this.id)

    this.parent.children.splice(idx + 1, 0, dup)
    this.root.buildPathCache()
    this.root.resolveChildLogicDependencies()

    return dup
  }

  get canRemove() {
    if (!this.canHaveMultiple) {
      return false
    }

    if (this.clonedFrom) {
      return true
    }

    return false
  }

  remove() {
    if (!this.canRemove) {
      return
    }

    const target = this.id
    const dropFrom = arr => {
      const idx = arr.findIndex(cl => cl.id === target)

      arr.splice(idx, 1)
    }

    if (this.clonedFrom) {
      dropFrom(this.clonedFrom.clones)
    }

    if (this.clones && this.clones.length > 0) {
      this.clones.forEach(cl => {
        if (cl.name !== this.clones[0].name) {
          cl.clonedFrom = this.clones[0].name
        }
      })
    }

    dropFrom(this.parent.children)

    this.onTreeChange()
  }

  set onValueChangeHandler(callback) {
    this._onValueChangeHandler = callback

    if (isFunction(callback)) {
      callback(this.value)
    }
  }

  get value() {
    if (this.children.length > 0) {
      return this.children.reduce((a, f) => Object.assign(a, { [f.name]: f.value }), {})
    } else {
      return this._value
    }
  }

  set value(newVal) {
    this.setPropValue('_value', newVal)
    this.dirty = !!newVal || this.dirty

    if (isFunction(this._onValueChangeHandler)) {
      this._onValueChangeHandler(newVal)
    }
  }

  set onValidation(callback) {
    this._onValidation = callback
  }

  get onValidation() {
    return this._onValidation || (() => {})
  }

  set onTreeChange(callback) {
    this.root._onTreeChange = callback
  }

  get onTreeChange() {
    return this.root._onTreeChange || (() => {})
  }

  setPropValue(prop, newVal) {
    const keyPath = prop.split('.')
    let lastKey = keyPath.pop()

    const ref = keyPath.reduce((acc, key) => {
      if (acc) {
        return acc[key]
      }
    }, this)

    if (newVal && typeof(newVal) === 'object' && this.children) {
      for (let [key, val] of Object.entries(newVal)) {
        const subField = this.children.find(c => c.name === key)

        if (subField) {
          subField.value = val
        }
      }
    }

    ref[lastKey] = newVal

    if (this.root.logicDeps.hasOwnProperty(this.path)) {
      this.root.logicDeps[this.path].forEach(depPath => {
        this.findRelativeWithPath(depPath).processLogic()
      })
    }
  }

  validate() {
    // Let's default this that way we can set it only when something is invalid
    this.isValid = true
    this.validationError = null

    if (!this.isVisible || this.type === 'hidden' || this.name === 'line2') {
      return true
    }

    // Default to empty string if we don't have a value and it's not a boolean.
    if (!this._value && typeof(this._value) !== 'boolean') {
      this._value = ''
    }

    // registered agent gets set to empty string above, which fails validations. Change top level value to empty object
    // so it skips top level validations but still validates children.
    if (this.name === 'registered_agent') this._value = {}

    if (!this.validations) {
      this.validations = {}
    }

    //TODO: Ideally this happens at creation and is in the validations, eventually
    if (this.required) {
      this.validations['^.+?$'] = `This field is required: ${ this.title }`
    } else if (this.required === false) {
      // If we have explicitly asked for a field not to be required, remove any validations
      // that may exist logic conducted in the definition of the glossary term that may have added
      // the require
      delete this.validations['^.+?$']
      delete this.validations['.+']
    }

    if (typeof(this._value) === 'boolean') {
      this.isValid = Boolean(this._value) === this._value
    } else if (typeof(this._value) === 'string' || typeof(this._value) !== 'object') {
      for (let [key, val] of Object.entries(this.validations)) {
        let regex = key
        let msg = val

        // Not sure where this is helpful
        if (typeof(val) === 'object') {
          regex = val.regex
          msg = key
        }

        let match
        // It seems that interesting regex strings that are passed in will need to be converted
        let flags = regex ? regex.replace(/.*\/([gimy]*)$/, '$1') : ''
        this._value = this._value.toString() // need to force a string when matching regex
        if (flags && flags !== regex) {
          let pattern = regex.replace(new RegExp('^/(.*?)/'+flags+'$'), '$1')

          match = this._value.match(new RegExp(pattern, flags))
        } else {
          match = this._value.match(new RegExp(regex))
        }

        this.meta.validationRegexMatch = match

        if (!match && this.isVisible) {
          this.isValid = false
          this.validationError = msg
        }
      }
    }

    /* META CHECKS */
    // TODO: do we want to do these??? or do we want everything stored as regex in the validations
    // Check that number is between two values if set in the meta
    if (this.isValid && this.meta && this.meta.type === 'number') {
      if (this.meta.min_value && (Number(this.value) < Number(this.meta.min_value))) {
        this.isValid = false
        this.validationError = 'Value cannot be less than ' + this.meta.min_value
      } else if (this.meta.max_value && (Number(this.value) > Number(this.meta.max_value))) {
        this.isValid = false
        this.validationError = 'Value cannot be more than ' + this.meta.max_value
      }
    }

    // Check character limit for just plain strings, which means that there meta type is string or
    // meta type is nothing but the top level type is string
    if (this.isValid && this.meta && (this.meta.type === 'string' || (this.meta.type === undefined && this.type === 'string'))) {
      const character_limit = this.meta.character_limit
      if(character_limit && (this.value.length >= Number(character_limit))) {
        this.isValid = false
        this.validationError = 'Character limit is ' + character_limit
      }
    }

    let valid = this.isValid || this.root === this

    this.children.forEach(child => {
      // Send down validations to children from parent if the key exists
      // Handle validations like this
      // {
      //   "country": {
      //     "/^\\b(US|United States)\\b$/i": "It must be a United States address"
      //   },
      //   "^.+?$": "This field is required"
      // }
      if (child.parent.validations[child.name]) {
        child.validations = { ...child.validations, ...child.parent.validations[child.name] }
      }
      valid = child.validate() && valid
    })

    // Loop through the children to gather the error messages
    if (this !== this.root && !valid) {
      const msgs = this.children.map(child => child.validationError)

      if (msgs.length > 0) {
        this.validationError = compactArr(uniqArr(msgs)).join(', ')
      }
    }

    this.onValidation(this, valid)

    if (this.parent) {
      this.parent.onValidation(this, valid)
    }

    this.isValid = valid

    if (this.isValid) {
      this.validationError = null
    }

    return valid
  }

  // Here we apply the logic blocks which can inject some validations and show/hide, etc that will
  // drive you insane unless you know they are happening. Recursion sucks to debug.
  // Example: this logic in the glossary term:
  //"logic": [ {
  //       "note": "When title appears in list, require name",
  //       "field": "self#meta.title",
  //       "value": [
  //         "President",
  //         "Treasurer",
  //         "Secretary",
  //         "Partner",
  //         "Director"
  //       ],
  //       "action": "setProperty",
  //       "params": {
  //         "children.3.validations": {
  //           ".+": "Required"
  //         }
  //       },
  //       "comparator": "in"
  //     }
  // ]
  // would inject that the first_name field(field 3) have the validation requirement
  processLogic() {
    this.logic.forEach(block => {

      if (isFunction(logicProcessors[block.action])) {
        const cb = bindFunc(logicProcessors[block.action], this)

        bindFunc(logicProcessors._computeComparison, this)(block, result => {
          cb(block, result)
        })

        if (block.validate) {
          this.validate()
        }
      }
    })
  }

  findRelativeWithPath(path) {
    return this.root.pathCache[path]
  }

  buildPathCache() {
    const cache = {}

    const visit = field => {
      cache[field.path] = field

      field.children.forEach(child => visit(child))
    }

    visit(this.root)

    this.pathCache = cache
  }

  resolveChildLogicDependencies() {
    const deps = {}
    for (let [path, field] of Object.entries(this.root.pathCache)) {
      if (!Array.isArray(field.logic)) {
        continue
      }

      field.logic.forEach(block => {
        let otherPath = block.field.split('#')[0]

        if (otherPath.indexOf('self') === 0) {
          otherPath = field.path + otherPath.substr(4)
        }

        otherPath = resolveObjPathDotDot(otherPath)

        if (typeof(deps[otherPath]) === 'undefined') {
          deps[otherPath] = []
        }

        deps[otherPath].push(path)
      })
    }

    for (let [key, val] of Object.entries(deps)) {
      deps[key] = uniqArr(val)
    }

    this.logicDeps = deps
  }
}

export class SchemaForm {
  constructor(fields, initialValues = {}) {

    this.subFieldIsRegisteredAgentEmailOrPhone = function(fieldName, subFieldName) {
      return fieldName === 'registered_agent' && ['email_address', 'phone_number'].includes(subFieldName)
    }
    // this passes down the required attribute to children and grandchildren
    // I tried putting it in a function but it's rebellious
    fields.map(field => {
      if (field.fields && Array.isArray(field.fields)) {
        field.fields.map(subfield => {
          subfield.required = 'required' in subfield ?
            subfield.required :
            field.required && subfield.name !== 'line2' && subfield.name !== 'county' && !this.subFieldIsRegisteredAgentEmailOrPhone(field.name, subfield.name)
          if (subfield.fields && Array.isArray(subfield.fields)) {
            subfield.fields.map(subSubfield => {
              subSubfield.required = 'required' in subSubfield ?
                subSubfield.required :
                subfield.required && subSubfield.name !== 'line2' && subfield.name !== 'county'
              return subSubfield
            })
          }
          return subfield
        })
      }
      return field
    })

    this.root = new SchemaFormField({ fields, name: '' }, '', true)
    this.root.form = this

    this.children = this.root.children
    this.root.buildPathCache()
    this.root.resolveChildLogicDependencies()
    this.initialDuplicateMap = {}
    // This is where we use the 'set values(vals)' to fill in any form fields values based off of path and value
    this.values = initialValues
    this.valid = null

  }

  get values() {
    return this.children.reduce((a, f) => Object.assign(a, { [f.name]: f.value }), {})
  }


  set values(vals) {
    // The key is the path that we can set fields with
    for (let [path, val] of Object.entries(flattenObj(vals))) {
      this.set(path, val)
    }
    this.resetDirty()
  }

  set onChildAddedOrRemoved(callback) {
    this.root.onTreeChange = callback
  }

 /**
  * Sets the dirty property to val for all values in this.rootPathCache
  * @param {Boolean} val
  */
  resetDirty(val = false) {
    Object.values(this.root.pathCache).forEach(field => {
      field.dirty = val
    })
  }

  validate() {
    this.resetDirty(true)

    this.valid = this.root.validate()

    return this.valid
  }

  /**
   * @param {*} path path/key in this.root.pathCache
   * @returns value at specified path
   */
  find(path) {
    return this.root.pathCache[path]
  }

 /**
  * @param {string} path Path/Key in this.root.pathCache
  * @param {*} val value to be set in this.root.PathCache
  * This is where we search through our fields and set any values off of the pathing.
  * FYI, we only store one path so if you have multiple fields with the same path, it aint gonna work -- maybe
  */
  set(path, val) {
    const field = this.find(path)

    if (field instanceof SchemaFormField) {
      if (val !== null && val !== undefined && val !== '') {
        // If the field is not a person field, we can just set the value.
        // For person fields, we make sure that updating via path does not overwrite other nested values.
        if (field.meta && field.meta.type !== 'person') {
          field.value = val
        } else {
          let official = {}

          for (let [key, v] of Object.entries(val)) {
            if (v !== undefined && v !== null) {
              official[key] = v
            }
          }
          field.value = { ...field.value, ...official }
        }
      } else if (val === undefined && field.type === 'boolean') {
        field.value = false
      }
    } else {
      this._duplicateFieldAndSetValue(path, val)
      // throw `no field exists at path "${path}"`
    }
  }

  /**
   * Checks to see if field exists with same basekey if we have a flattened duplicate field (/official.director/0/etc...)
   * Then duplicates the basefield via the SchemaFormField and attempts to set appropriate values
   * @param {String} path
   * @param {any} val
   */
  _duplicateFieldAndSetValue(path, val) {
    let [, baseKey, index, ...restOfPath] = path.split('/')
    const possibleField = this.find(`/${baseKey}`)
    const isZeroIndex = parseInt(index) === 0

    if (possibleField instanceof SchemaFormField && possibleField?.canHaveMultiple && Number.isInteger(parseInt(index))) {

      let dup = null
      let newPath = ''

      // first check if we have initialized the array
      if (!this.initialDuplicateMap[baseKey] && !isZeroIndex) {
        this.initialDuplicateMap[baseKey] = []
        dup = possibleField.duplicate(index)
        this.initialDuplicateMap[baseKey].push(index)
        newPath = restOfPath ? dup.path + `/${restOfPath.join('/')}` : dup.path
      } else if (isZeroIndex) {
        newPath = `/${baseKey}/${restOfPath.join('/')}` // should already have the initial field created for the first official in the array
      }
       else if (!this.initialDuplicateMap[baseKey].includes(index)) {
        dup = possibleField.duplicate(index)
        this.initialDuplicateMap[baseKey].push(index)
        newPath = restOfPath ? dup.path + `/${restOfPath.join('/')}` : dup.path
      } else {
        newPath = isZeroIndex ? `/${baseKey}/${restOfPath.join('/')}` : `/${baseKey}__${index}/${restOfPath.join('/')}`
      }
      this.set(newPath, val)
    }
  }
}
