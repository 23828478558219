var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.sortedKeys?.length
        ? _c("ct-table", {
            attrs: {
              "is-paginated": false,
              fields: _vm.accessKeysDefinition.columns,
              items: _vm.sortedKeys,
              loaded: true,
              "table-definition": _vm.accessKeysDefinition,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(description)",
                  fn: function (row) {
                    return [
                      _vm._v(
                        "\n      " + _vm._s(row.item.description) + "\n    "
                      ),
                    ]
                  },
                },
                {
                  key: "cell(enabled)",
                  fn: function (row) {
                    return [
                      !row.item.deletedAt
                        ? _c("fa-icon", { attrs: { icon: "check" } })
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(accessKey)",
                  fn: function (row) {
                    return [
                      _c(
                        "span",
                        { staticStyle: { "word-break": "break-all" } },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(row.item.accessKey) +
                              "\n        "
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "p-0 pl-2",
                              attrs: {
                                variant: "link",
                                size: "sm",
                                "aria-label": "Copy to Clipboard",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.copyToClipboard(
                                    row.item.accessKey,
                                    null,
                                    _vm.successToast,
                                    _vm.errorToast
                                  )
                                },
                              },
                            },
                            [_c("copy-icon")],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(actions)",
                  fn: function (row) {
                    return [
                      _c(
                        "b-button",
                        {
                          staticClass: "m-0 pl-0 pt-0 pb-0",
                          attrs: {
                            variant: "link",
                            "aria-label": "Edit button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.updateAccessKey(row.item)
                            },
                          },
                        },
                        [
                          _c("fa-icon", { attrs: { icon: "edit" } }),
                          _vm._v("\n        Edit\n      "),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "danger m-0 pr-0 pt-0 pb-0",
                          attrs: {
                            variant: "link",
                            "aria-label": "Delete button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.destroyAccessKey(row.item.id)
                            },
                          },
                        },
                        [
                          _c("fa-icon", { attrs: { icon: "trash" } }),
                          _vm._v("\n        Delete\n      "),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              229409306
            ),
          })
        : _vm._e(),
      !_vm.sortedKeys?.length
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("api-management-empty-state", {
                attrs: {
                  title: "There are no api keys",
                  text: "Click the button below to create your first API key.",
                  "cta-clicked": _vm.createAccessKey,
                  "cta-text": "Create API Key",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }