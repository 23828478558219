<template>
  <div class="standalone-hosting-form">
    <form ref="standalone-hosting-form">
      <div v-if="domainAvailable">
        <b-col>
          <div class="text-center">
            <p>Looks like the domain you entered isn't registered.</p>
            <b-button
              class="mr-2"
              variant="outline-danger"
              aria-label="Close button"
              @click="closeModal"
            >
              Exit
            </b-button>
            <b-button
              variant="primary"
              aria-label="Try again button"
              @click="tryAgain"
            >
              Try Again
            </b-button>
          </div>
        </b-col>
      </div>
      <validation-observer v-else ref="observer" v-slot="{ invalid }">
        <b-row class="px-4 mt-4">
          <b-col>
            <label>Domain</label>
            <validation-provider
              v-slot="{ errors, validate, validated }"
              name="domain"
              rules="required|validDomain"
              immediate
            >
              <b-form-input
                v-model="standaloneHostingRequest.domainName"
                type="text"
                :state="validated ? !errors.length : null"
                @input="markDirty('domainName')"
              />
              <b-form-invalid-feedback id="domain-error-feedback">
                Enter your domain in all lowercase without "www", "https", or special characters. (e.g., example.com).
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="px-4">
          <b-col>
            <span class="text--error">
              {{ errorMessage }}
            </span>
          </b-col>
        </b-row>
        <b-row class="px-4 py-3">
          <b-col cols="auto">
            <div>Want to talk to a person about starting?</div>
            <b-checkbox
              v-model="standaloneHostingRequest.requestCall"
              class="text--teal"
              @click.native="inputDirty.requestCall = true"
            >
              Request a call
            </b-checkbox>
          </b-col>
          <b-col />
          <b-col cols="auto">
            <b-button
              v-if="cancellable"
              class="mr-2"
              variant="outline-danger"
              aria-label="Cancel button"
              :disabled="loading"
              @click="closeModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :aria-label="submitText + 'button'"
              :disabled="loading || invalid"
              @click="submitRequest"
            >
              <b-overlay :show="loading" rounded="sm" :opacity="0" variant="black">
                <span>{{ submitText }}</span>
              </b-overlay>
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </form>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'StandaloneHostingForm',
  mixins: [
    makeToastMixin,
  ],
  props: {
    cancellable: {
      type: Boolean,
      default: false,
    },
    bus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      domainAvailable: null,
      standaloneHostingRequest: {
        domainName: '',
        requestCall: false,
      },
      inputDirty: {
        domainName: false,
        requestCall: false,
      },
    }
  },
  computed: {
    ...mapGetters('domains', [
      'standaloneHostingInfo',
      'domainAvailability',
    ]),
    submitText() {
      return this.standaloneHostingRequest.requestCall ? 'Request a Call' : 'Activate Hosting'
    },
    companyId() {
      return this.$route.params.companyId
    },
    formIsPristine(){
      return Object.values(this.inputDirty).every(dirty => !dirty)
    },
  },
  created() {
    if (this.standaloneHostingInfo?.id) this.standaloneHostingRequest = { ...this.standaloneHostingInfo }
  },
  methods: {
    ...mapActions('domains', [
      'submitStandaloneHostingRequest',
      'updateStandaloneHostingRequest',
      'fetchStandaloneHostingRequest',
      'fetchDomainAvailability',
    ]),
    async validate() {
      for (const field in this.inputDirty) this.inputDirty[field] = true
      return await this.$refs.observer.validate()
    },
    markDirty(field) {
      this.inputDirty[field] = true
    },
    async submitRequest() {
      this.loading = true

      try {
        await this.fetchDomainAvailability(this.standaloneHostingRequest.domainName)
        this.domainAvailable = this.domainAvailability.find(d => d.domain === this.standaloneHostingRequest.domainName).available
        if (this.domainAvailable) return
        if (this.standaloneHostingRequest.id && this.formIsPristine) return await this.closeModal()
        if (this.standaloneHostingRequest.requestCall) return await this.submitApiStandaloneHostingRequest()
        if (!await this.validate() || this.errorMessage) return
        await this.submitApiStandaloneHostingRequest()
      } catch {
        this.errorToast('Submission Error', 'There was an error submitting your request.')
      } finally {
        this.loading = false
        if(this.domainAvailable === false) {
          this.$emit('next-slide')
        }
      }
    },
    async submitApiStandaloneHostingRequest() {
      this.errorMessage = null
      const payload = {
        ...this.standaloneHostingRequest,
        companyId: this.companyId,
      }
      const result = this.standaloneHostingRequest.id
        ? await this.updateStandaloneHostingRequest(payload)
        : await this.submitStandaloneHostingRequest(payload)
      if (result.data?.status === 200) {
        await this.bus.$emit('log-domain-interaction', { name: 'standalone-hosting-request' })

        const toastMessage = this.standaloneHostingRequest.requestCall ?
          'Call request submitted!' :
          'Standalone hosting request submitted!'

        await this.fetchStandaloneHostingRequest({ companyId: this.companyId })
        this.successToast('Success', toastMessage)
        await this.closeModal()
      } else {
        this.errorMessage = result.data?.response?.error?.split(':')[1]
      }
    },
    tryAgain() {
      this.standaloneHostingRequest.domainName = ''
      this.domainAvailable = null
    },
    async closeModal() {
      this.standaloneHostingRequest = {
        domainName: '',
        requestCall: false,
      }
      this.inputDirty = {
        domainName: '',
        requestCall: false,
      }
      this.$emit('closeModal')
    },
  },
}
</script>


<style scoped lang='scss'>
.standalone-hosting-form {

  .text--teal {
    color: $ct-ui-color-10;
  }

  .text--error {
    color: #BC2F2F;
  }

  .border-valid {
    border-color: green;
  }

  .border-invalid {
    border-color: #BC2F2F;
  }

  .btn {
    border-radius: 4px;
  }

  .submit-btn {
    width: 200px;
  }

  .error-message {
    color: #BC2F2F;
  }
}
</style>
