import Vue from 'vue'
import * as t from '../mutations'
import http from '@/http'
import { Client, GET, POST } from '@/api/client'
import {
  ACCOUNT_INVOICE_BULK_DOWNLOAD,
  ACCOUNT_PAY_INVOICES,
  ACCOUNT_GUEST_FIND_INVOICE,
  ACCOUNT_GUEST_PAY_INVOICES,
  ACCOUNT_GUEST_INVOICE_PAYMENT,
} from '@/api/v3/endpoints'

import {
  actions as BaseActions,
  getters as BaseGetters,
  mutations as BaseMutations,
  state as BaseState,
} from '@/store/base'

const STATE = {
  ...BaseState,
  namespace: 'invoices',
  invoices: {},
  invoicesLoaded: false,
  error: null,
}

const GETTERS = {
  ...BaseGetters,
  find: state => id => state.invoices[id],
  findAll: state => ids => Object.values(state.invoices).filter(invoice => ids.includes(invoice.id)),
  invoices: state => Object.values(state.invoices),
  invoicesLoaded: state => state.invoicesLoaded,
  error: state => state.error,
}

const ACTIONS = {
  ...BaseActions,
  async loadIfNotAvailableById({ dispatch, getters }, { ids, params }) {
    // Allow either a single element or an array to be passed in
    ids = [].concat(ids)
    for (let id of ids) {
      if (id && !getters.find(id)) {
        await dispatch('loadById', { id, params })
      }
    }
  },

  async loadById({ commit }, { id, params }) {
    commit(t.LOAD_INVOICES_START)

    try {
      const invoice = await http.get(`client/invoices/${id}`, { params })

      commit(t.LOAD_INVOICES_FINISHED, [invoice.data.result])
    } catch (error) {
      commit(t.LOAD_INVOICES_FAILED, error)
    }
  },

  async fetchInvoice({ rootGetters }, { params }) {
    try {
      const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
      return await client.call({
        method: GET,
        path: ACCOUNT_GUEST_FIND_INVOICE,
        params: params,
      })
    } catch (error) {
      return error
    }
  },

  async fetchInvoiceFromOrderItem(_, order_item_id) {
    try {
      const result = await http.get(`client/invoices/invoice_from_order_item/${order_item_id}`, {
        params: { origin: 'client' },
      })
      return result.data.result
    } catch (error) {
      console.log(error)
      return error
    }
  },

  async payLegalRequestUnlockInvoice({ rootGetters, dispatch }, { documentId, payableId }) {
    if (rootGetters['paymentMethods/isSelectedExpired']) {
      return dispatch('expiredCardResponse')
    }

    const response = await http.post('client/invoices/generate_and_pay_legal_request_unlock_invoice', {
      legal_request_id: documentId,
      payable_id: payableId,
    })

    return response.data
  },

  async generateAndPayDocumentUnlockInvoice({ rootGetters, commit, dispatch }, { documentId, payableId }) {
    try {
      if (rootGetters['paymentMethods/isSelectedExpired']) {
        return dispatch('expiredCardResponse')
      }

      const response = await http.post(`client/invoices/generate_and_pay_document_unlock_invoice`,
        { document_id: documentId, payable_id: payableId }
      )

      return response.data
    } catch (error) {
      commit(t.LOAD_INVOICES_FAILED, error)
    }
  },

  async payInvoices({ rootGetters, dispatch }, { ids, payableId, manualRemittance = false, setupAsAutopay = false }) {
    if (rootGetters['paymentMethods/isSelectedExpired']) {
      return dispatch('expiredCardResponse')
    }

    const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    try {
      return await client.call({
        method: POST,
        path: ACCOUNT_PAY_INVOICES,
        params: { ids, payableId, manualRemittance, setupAsAutopay },
      })
    } catch (error) {
      console.error(error)
    }
  },

  async guestPayInvoices({ rootGetters }, { ids, payableId, accountId, email }) {
    const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    try {
      return await client.call({
        method: POST,
        path: ACCOUNT_GUEST_PAY_INVOICES,
        params: { ids, payableId, accountId, email },
      })
    } catch (error) {
      console.error(error)
    }
  },

  async notifyGuestPayment({ rootGetters }, { ids, accountId }) {
    const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    try {
      return await client.call({
        method: POST,
        path: ACCOUNT_GUEST_INVOICE_PAYMENT,
        params: { ids, accountId },
      })
    } catch (error) {
      console.error(error)
    }
  },

  async fetchURLS({ rootGetters }, { ids, origin }) {
    const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])

    try {
      const result = await client.call({
        method: POST,
        path: ACCOUNT_INVOICE_BULK_DOWNLOAD,
        params: { ids, origin },
      })

      return result.data
    } catch (error) {
      console.log(error)
      return error
    }
  },

  async bulkDownload(_, ids) {
    try {
      const result = await http.post('client/invoices/bulk_download', {
      ids: ids,
      origin: 'client',
    })
    return result.data
  } catch (error) {
      console.log(error)
      return error
    }
  },

  async downloadCombinedInvoices(_, ids) {
    try {
      const result = await http.post('client/invoices/download_combined_invoices_pdf', {
        ids: ids,
        origin: 'client',
      })
      return result.data
    } catch (error) {
      console.log(error)
      return error
    }
  },

  async checkForCombinedPdf(_, id) {
    try {
      const result = await http.get(`client/invoices/check_for_combined_pdf/${id}`)
      return result.data
    } catch (error) {
      console.log(error)
      return error
    }
  },

  expiredCardResponse() {
    return { success: false, data: { error: { message: 'Your card is expired. Please select another payment method.' } } }
  },
}

const MUTATIONS = {
  ...BaseMutations,
  [t.LOAD_INVOICES_START](state) {
    state.invoicesLoaded = false
  },

  [t.LOAD_INVOICES_FINISHED](state, invoices) {
    invoices.forEach(c => Vue.set(state.invoices, c.id, c))

    state.invoicesLoaded = true
  },

  [t.LOAD_INVOICES_FAILED](state, error) {
    state.error = error
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
