var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cancel-compliance" }, [
    _c(
      "div",
      {
        staticClass:
          "nav-bar row w-100 d-flex flex-row-reverse align-items-center pr-3",
      },
      [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: { href: _vm.loginUrl, "aria-label": "Sign In Link" },
          },
          [
            _c("b-img", {
              staticClass: "mr-1",
              attrs: { src: "/images/payments/log-in.svg", alt: "Sign In" },
            }),
            _vm._v("\n      Sign In\n    "),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "website-logo" },
      [
        _vm.websiteImgSrc
          ? _c("b-img", {
              attrs: { src: _vm.websiteImgSrc, alt: "Website logo" },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm.cancelConfirmed
      ? _c("div", { staticClass: "cancel-confirmed" }, [
          _c("h1", { staticClass: "cancellation-title" }, [
            _vm._v("Service Cancellation Requested"),
          ]),
          _c(
            "div",
            { staticClass: "cancel-message" },
            [
              _c("p", [
                _vm._v("\n        Your cancellation has been processed. "),
                _vm.canRefund
                  ? _c("span", [
                      _vm._v(
                        "You’ll receive an email shortly with details about your $" +
                          _vm._s(_vm.calculateRate.toFixed(2)) +
                          " refund, which will be credited back to your original payment method.\n        "
                      ),
                      _c("strong", [
                        _vm._v(
                          "Refunds typically resolve quickly but may take up to 7-10 days to process."
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("p", [
                _vm._v(
                  "\n        We’re sorry you're canceling your compliance service—please know we’re always here if you want us to file your reports again. Whether it’s saving time, reducing costs, or keeping track of filing deadlines, we’re ready to help your business!\n      "
                ),
              ]),
              _c(
                "b-button",
                {
                  staticClass: "custom-login-button",
                  attrs: { "aria-label": "Login Button", href: _vm.loginUrl },
                },
                [
                  _vm._v("\n        Log In "),
                  _c("fa-icon", { attrs: { icon: ["fas", "arrow-right"] } }),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm.loaded && _vm.canCancel
      ? _c("div", { staticClass: "cancel-service" }, [
          _c("div", { staticClass: "header" }, [
            _c("p", { staticClass: "cancel-service-title" }, [
              _vm._v("Cancel Service"),
            ]),
            _c("p", { staticClass: "company-name" }, [
              _vm._v(_vm._s(_vm.companyName)),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "service-card" },
            [
              _c(
                "b-row",
                { staticClass: "header-row" },
                [
                  _c("b-col", [
                    _vm._v(
                      "\n          Compliance Service and State Filing\n        "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "body-row" },
                [
                  _c("b-col", [_vm._v("\n          Jurisdiction\n        ")]),
                  _c("b-col", { staticClass: "text-right" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.jurisdiction) + "\n        "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "body-row" },
                [
                  _c("b-col", [_vm._v("\n          Rate\n        ")]),
                  _c("b-col", { staticClass: "text-right" }, [
                    _vm._v(
                      "\n          $" +
                        _vm._s(_vm.calculateRate.toFixed(2)) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
              _vm.canRefund
                ? _c(
                    "b-row",
                    { staticClass: "body-row refund-row" },
                    [
                      _c("b-col", [_vm._v("\n          Refund\n        ")]),
                      _c(
                        "b-col",
                        { staticClass: "text-right" },
                        [
                          _c("b-img", {
                            staticClass: "mr-2",
                            attrs: {
                              src: _vm.cardLogo(_vm.invoice.brand),
                              alt: "Card Logo",
                            },
                          }),
                          _vm._v(
                            "\n          **" +
                              _vm._s(_vm.invoice.last4) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                {
                  class: [
                    "body-row",
                    { "no-border": _vm.selectedReason === "other" },
                  ],
                },
                [
                  _c("b-col", [
                    _vm._v("\n          Reason for Cancellation\n        "),
                  ]),
                  _c("b-col", { staticClass: "text-right" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedReason,
                            expression: "selectedReason",
                          },
                        ],
                        staticClass: "form-control select-box",
                        attrs: { "aria-label": "Reason for cancellation" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedReason = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "" } }, [
                          _vm._v("Please select a reason"),
                        ]),
                        _vm._l(_vm.reasons, function (reason) {
                          return _c(
                            "option",
                            { key: reason, domProps: { value: reason } },
                            [_vm._v(_vm._s(reason))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm.selectedReason === "Other"
                    ? _c(
                        "b-col",
                        { staticClass: "text-box", attrs: { cols: "12" } },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.otherNote,
                                expression: "otherNote",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              maxlength: "250",
                              "aria-label": "Enter reason for cancellation",
                            },
                            domProps: { value: _vm.otherNote },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.otherNote = $event.target.value
                              },
                            },
                          }),
                          _c("div", { staticClass: "text-right text-muted" }, [
                            _vm._v(
                              _vm._s(_vm.remainingCharacters) +
                                " characters left"
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "action-buttons" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-left" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "grey-button-outlined",
                          attrs: {
                            variant: "outline-secondary",
                            "aria-label": "Keep Service Button",
                            href: _vm.loginUrl,
                          },
                        },
                        [_vm._v("\n            Keep Service\n          ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "custom-cancel-button",
                          attrs: { "aria-label": "Cancel Service Button" },
                          on: { click: _vm.confirmCancellation },
                        },
                        [_vm._v("\n            Cancel Service\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm.loaded
      ? _c("div", { staticClass: "cancel-confirmed" }, [
          _c("span", [
            _vm._v(
              "No filings or active compliance service available for refund / cancel"
            ),
          ]),
        ])
      : _c(
          "div",
          { staticClass: "nav-spinner-container" },
          [_c("ct-centered-spinner")],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "warning-message" }, [
      _c("h5", [
        _vm._v(
          "\n        Heads up! Cancelling Compliance will result in...\n      "
        ),
      ]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "\n          You filing your own reports with the state.\n        "
          ),
        ]),
        _c("li", [
          _vm._v(
            "\n          Potential fines if you miss the deadline or file incorrectly.\n        "
          ),
        ]),
        _c("li", [
          _vm._v("\n          One more thing you have to complete.\n        "),
        ]),
      ]),
      _c("span", [
        _vm._v("Our service is hassle free and makes your life easier. "),
        _c("strong", [_vm._v("Do you wish to continue?")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }