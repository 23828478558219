<template>
  <b-container class="container-no-padding">
    <b-modal v-model="modalVisibility.addOwnedItem" hide-footer :title="titleText">
      <b-form @submit.prevent="addItem">
        <b-form-group>
          <b-form-select v-model="newItem.value" :options="ownedData" />
        </b-form-group>
        <b-button type="submit" variant="primary" class="mr-2" aria-label="Add button">Add</b-button>
        <b-button 
          variant="danger" 
          aria-label="Cancel button"
          @click="toggleModal('addOwnedItem', { newItem: {} })"
        >
          Cancel
        </b-button>
      </b-form>
    </b-modal>

    <div v-if="tableData.length" class="monitoring-identities-card">
      <b-table :items="tableData" :fields="fields" class="table">
        <template v-slot:head(value)>
          {{ identityTypeStringPlural }}
        </template>
        <template v-slot:cell(active)="data">
          <div class="active-button">
            <b-form-checkbox v-model="data.item.active" name="check-button" switch size="lg" :disabled="!data.item.isEditMode" />
          </div>
        </template>
        <template v-slot:cell(value)="data">
          <b-form-input v-model="data.item.value" :disabled="!data.item.isEditMode" />
        </template>
        <template v-slot:cell(actions)="data">
          <div class="manage-buttons">
            <b-button 
              aria-label="Save button" 
              variant="primary" 
              size="sm" 
              class="save-button"
              @click="saveChanges(data.item)"
            >
              {{ buttonText(data.item) }}
            </b-button>
            <b-button 
              aria-label="Delete button" 
              variant="danger" 
              size="sm" 
              class="delete-button"
              @click="toggleModal('confirmDelete', { itemToDelete: data.item })"
            >
              Remove
            </b-button>
          </div>
        </template>
      </b-table>

      <div class="add-identities">
        <div v-if="ownedData?.length" class="add-owned-identity">
          <b-button variant="success" @click="toggleModal('addOwnedItem', refreshNewItem)">
            Add {{ identityTypeStringSingular }} from your account
          </b-button>
        </div>
        <div class="add-new-identity">
          <b-button variant="success" @click="toggleModal('addAction', refreshNewItem)">
            Add external {{ identityTypeStringSingular }} 
          </b-button>
        </div>
      </div>
    </div>
    <monitoring-no-identity v-else :identity-type="identityTypeStringPlural.toLowerCase()">
      <div class="add-buttons">
        <div v-if="ownedData?.length" class="add-owned-item-button">
          <b-button variant="success" @click="toggleModal('addOwnedItem', refreshNewItem)">
            Add {{ identityTypeStringSingular }} from your account
          </b-button>
        </div>
        <b-button variant="success" @click="toggleModal('addAction', refreshNewItem)">
          Add external {{ identityTypeStringSingular }}
        </b-button>
      </div>
    </monitoring-no-identity>

    <b-modal v-model="modalVisibility.addAction" hide-footer :title="titleText">
      <b-form @submit.prevent="addItem">
        <b-form-group>
          <b-form-input v-model="newItem.value" :placeholder="placholderText" />
        </b-form-group>
        <b-button type="submit" variant="primary" class="mr-2" aria-label="Add button">Add</b-button>
        <b-button 
          variant="danger" 
          aria-label="Cancel button"
          @click="toggleModal('addAction', { saveItem: {} })"
        >
          Cancel
        </b-button>
      </b-form>
    </b-modal>

    <b-modal v-model="modalVisibility.confirmDelete" hide-footer title="Are you sure you want to delete?">
      <b-button 
        class="mt-3" 
        variant="secondary" 
        block aria-label="Cancel button"
        @click="toggleModal('confirmDelete', { itemToDelete: {} })"
      >
        Cancel
      </b-button>
      <b-button class="mt-2" variant="danger" block aria-label="Confirm button" @click="deleteItem">Confirm</b-button>
    </b-modal>
  </b-container>
</template>

<script>
import MonitoringNoIdentity from './MonitoringNoIdentity.vue'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { mapGetters, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    MonitoringNoIdentity,
  },
  mixins: [makeToastMixin],
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    identityTypeStringSingular: {
      type: String,
      required: true,
    },
    identityTypeStringPlural: {
      type: String,
      required: true,
    },
    itemType: {
      type: Number,
      required: true,
    },
    ownedData: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      fields: ['active', 'value', 'actions'],
      newItem: { value: '', active: true },
      ownedSelection: '',
      modalVisibility: {
        addAction: false,
        confirmDelete: false,
        addOwnedItem: false,
      },
      refreshNewItem: { newItem: { value: '', active: true, type: this.itemType } },
    }
  },
  computed: {
    ...mapGetters('account', ['actualAccountID']),
    placholderText() {
      return `Enter ${this.identityTypeStringSingular.toLowerCase()}`
    },
    titleText() {
      return `Add ${this.identityTypeStringPlural.toLowerCase()}`
    },
  },
  methods: {
    ...mapActions('monitoring', [
      'removeMonitoringIdentity',
      'updateMonitoringIdentity',
    ]),
    buttonText(item) {
      return item.isEditMode ? 'Save' : 'Edit'
    },
    toggleModal(modalKey, options = {}) {
      if (this.modalVisibility.hasOwnProperty(modalKey)) {
        this.modalVisibility[modalKey] = !this.modalVisibility[modalKey]
        Object.keys(options).forEach(key => this[key] = options[key])
      }
    },
    checkDomainsInput(value) {
      // prevent users from entering http(s):// or www.
      return value.replace(/^(https?:\/\/)?(www\.)?/, '')
    },
    async addItem() {
      try {
        this.newItem.account_id = this.actualAccountID
        this.newItem.id = uuidv4()
        this.newItem.isEditMode = false

        if (this.identityTypeStringSingular.toLowerCase() == 'domain') {
          this.newItem.value = this.checkDomainsInput(this.newItem.value)
        }

        await this.updateMonitoringIdentity(this.newItem)
        this.tableData.push({ ...this.newItem })
        this.successToast('Success', `${this.identityTypeStringSingular} added`)
      } catch (error) {
        this.errorToast('Error', `Failed to add ${this.identityTypeStringSingular.toLowerCase()} - ${error.error}`)
      } finally {
        // Reset any open modals and reset data
        const resetItemList = ['addOwnedItem', 'addAction']
        const resetNewItem = { newItem: {} }

        resetItemList.map(item => {
          if (this.modalVisibility[item]) {
            this.toggleModal(item, resetNewItem)
          }
        })
      }
    },
    async saveChanges(item) {
      if (item.isEditMode) {
        item.isEditMode = false
        try {
          if (this.identityTypeStringSingular.toLowerCase() == 'domain') {
            item.value = this.checkDomainsInput(item.value)
          }
          await this.updateMonitoringIdentity(item)
          this.successToast('Success', `${this.identityTypeStringSingular} saved`)
        } catch (error) {
          item.active = false
          this.errorToast('Error', `Failed to save ${this.identityTypeStringSingular.toLowerCase()} - ${error.error}`)
        }
      } else {
        item.isEditMode = true
      }
    },
    async deleteItem() {
      let item = this.itemToDelete
      try {
        await this.removeMonitoringIdentity({ monitoringIdentityID: item.id, accountID: this.actualAccountID })
        const index = this.tableData.findIndex(d => d.id === item.id)
        if (index > -1) {
          this.tableData.splice(index, 1)
        }
        this.successToast('Success', `${this.identityTypeStringSingular} deleted`)
      } catch {
        this.errorToast('Error', `Failed to delete ${this.identityTypeStringSingular.toLowerCase()}, please try again`)
      } finally {
        this.toggleModal('confirmDelete', { itemToDelete: {} })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.monitoring-identities-card {
  .table {
    border: 1px solid #cccee0;
    border-radius: 0.5em;
    background-color: white;
  }

  .table .custom-switch {
    padding-bottom: 0.4em;
  }

  .add-identities {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 1em;

    .add-new-identity {
      margin-right: 0.5em;
      margin-left: 0.5em;
    }
  }

  .manage-buttons {
    flex-direction: row;
    max-width: auto;
  }

  .manage-buttons>button {
    width: 7rem;
    margin: 0 !important;
  }

}

.add-buttons {
  display: flex;
  justify-content: space-between;
  margin: 2em;
  gap: 2em;
}

.add-buttons button {
  width: 22rem;
}

.add-buttons>.add-new-identity,
.add-buttons>.add-owned-item-button {
  flex: 1;
  margin-right: 1em;
}

.active-button {
  margin-top: .6rem;
  margin-left: .5rem;
}

.expand-link {
  display: inline-block;
  color: rgb(0, 123, 255) !important;
  cursor: pointer;
}

.add-owned-item-button {
  text-align: right;
}

.manage-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-right: 1rem;
  max-width: 7rem;
}

.manage-buttons>button {
  width: 100%;
  margin: 0 !important;
}

.container-no-padding {
  padding: 0;
}
</style>
