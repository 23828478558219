<template>
  <div id="domain-management-panel">
    <b-tabs pills>
      <!-- Website Hosting ---------------------------------------------------------------------------->
      <b-tab :active="activeTab === 'website'">
        <template #title>
          <strong>Website</strong>
          <b-icon
            v-if="isDomainAddonServiceInGracePeriod(businessWebsiteHostingService) || isDomainAddonServiceInReactivationFailed(businessWebsiteHostingService)"
            v-b-tooltip.hover="tooltipText(businessWebsiteHostingService)"
            icon="exclamation-triangle-fill"
            :class="`warning-icon ml-1 vac-vertical-center ${getDomainAddonServiceClass(businessWebsiteHostingService)}`"
          />
        </template>
        <domain-website-hosting
          v-if="showDomainAddonServiceTab(businessWebsiteHostingService)"
          :subtitle="getDomainHostingSubtitle()"
          :service="businessWebsiteHostingService"
          @webmail-visit-interaction="logWebmailVisitInteraction"
        />
        <domain-addon-service-offer
          v-else
          :offer-product-title="businessWebsiteHostingOfferData.title"
          :offer-content="businessWebsiteHostingOfferData.content"
          :plan="getUpgradePlan('business-website-hosting')"
          product-type="business-website-hosting"
          :other-product-type="showDomainAddonServiceTab(businessEmailService) ? null: 'business-email'"
          :has-active-ssl-service="showDomainAddonServiceTab(domainSslService)"
          @domain-addon-interaction="logDomainAddonInteraction"
        />
      </b-tab>

      <!-- Emails --------------------------------------------------------------------------------->
      <b-tab :active="activeTab === 'email'">
        <template #title>
          <strong>Emails</strong>
          <b-icon
            v-if="isDomainAddonServiceInGracePeriod(businessEmailService) || isDomainAddonServiceInReactivationFailed(businessEmailService)"
            v-b-tooltip.hover="tooltipText(businessEmailService)"
            icon="exclamation-triangle-fill"
            :class="`warning-icon ml-1 vac-vertical-center ${getDomainAddonServiceClass(businessEmailService)}`"
          />
        </template>
        <domain-emails
          v-if="showDomainAddonServiceTab(businessEmailService)"
          :service="businessEmailService"
          @webmail-visit-interaction="logWebmailVisitInteraction"
        />
        <domain-addon-service-offer
          v-else
          :offer-product-title="businessEmailOfferData.title"
          :offer-content="businessEmailOfferData.content"
          :plan="getUpgradePlan('business-email')"
          product-type="business-email"
          :other-product-type="showDomainAddonServiceTab(businessWebsiteHostingService) ? null: 'business-website-hosting'"
          :has-active-ssl-service="showDomainAddonServiceTab(domainSslService)"
          @domain-addon-interaction="logDomainAddonInteraction"
        />
      </b-tab>

      <!-- SSL ------------------------------------------------------------------------------------>
      <b-tab v-if="isDomainAddonServiceActive(businessWebsiteHostingService) || isDomainAddonServiceActive(businessEmailService)" :active="activeTab === 'ssl'">
        <template #title>
          <strong>SSL Certificate</strong>
          <b-icon
            v-if="isDomainAddonServiceInGracePeriod(domainSslService) || isDomainAddonServiceInReactivationFailed(domainSslService)"
            v-b-tooltip.hover="tooltipText(domainSslService)"
            icon="exclamation-triangle-fill"
            :class="`warning-icon ml-1 vac-vertical-center ${getDomainAddonServiceClass(domainSslService)}`"
          />
        </template>
        <domain-ssl-certificate
          v-if="showDomainAddonServiceTab(domainSslService)"
          :service="domainSslService"
        />
        <domain-addon-service-offer
          v-else
          :offer-product-title="domainSslOfferData.title"
          :offer-content="domainSslOfferData.content"
          product-type="domain-ssl"
          :other-product-type="getDomainSslOtherProductType()"
          @domain-addon-interaction="logDomainAddonInteraction"
        />
      </b-tab>

      <!-- Settings Tab -------------------------------------------------------------------------------->
      <b-tab>
        <template #title><strong>Settings</strong></template>
        <b-tabs pills vertical>
          <!-- DNS Management ------------------------------------------------------------------------->
          <b-tab>
            <template #title><strong>DNS Management</strong></template>
            <b-tabs pills vertical>
              <b-tab>
                <template #title><strong>External Hosts</strong> (A, AAAA)</template>
                <domain-external-hosts />
              </b-tab>
              <b-tab>
                <template #title><strong>TXT Records</strong> (TXT)</template>
                <domain-txt-records />
              </b-tab>
              <b-tab>
                <template #title><strong>Subdomains</strong> (CNAME)</template>
                <domain-subdomains />
              </b-tab>
              <b-tab>
                <template #title><strong>NS Records</strong> (NS)</template>
                <domain-ns-records />
              </b-tab>
              <b-tab>
                <template #title><strong>SRV Records</strong> (SRV)</template>
                <domain-srv-records />
              </b-tab>
              <b-tab>
                <template #title><strong>CAA Records</strong> (CAA)</template>
                <domain-caa-records />
              </b-tab>
              <b-tab>
                <template #title><strong>Email Service</strong> (MX)</template>
                <domain-mx-records />
              </b-tab>
            </b-tabs>
          </b-tab>

          <!-- WHOIS ---------------------------------------------------------------------------------->
          <b-tab>
            <template #title><strong>WHOIS</strong></template>
            <b-tabs pills vertical variant="default">
              <b-tab>
                <template #title><strong>Registrant</strong></template>
                <domain-whois-registrant />
              </b-tab>
              <b-tab>
                <template #title><strong>Technical</strong></template>
                <domain-whois-technical />
              </b-tab>
              <b-tab>
                <template #title><strong>Admin</strong></template>
                <domain-whois-admin />
              </b-tab>
            </b-tabs>
          </b-tab>

          <!-- Nameservers ---------------------------------------------------------------------------->
          <b-tab>
            <template #title><strong>Nameservers</strong></template>
            <b-tabs pills vertical>
              <b-tab>
                <template #title><strong>Nameservers</strong></template>
                <domain-nameservers />
              </b-tab>
              <b-tab>
                <template #title><strong>Advanced Settings</strong></template>
                <domain-glue-records />
              </b-tab>
            </b-tabs>
          </b-tab>
        </b-tabs>
      </b-tab>

      <b-tab v-if="showAdminTools" disabled inactive>
        <template #title>
          <div class="tab-divider">
            <div class="vertical-rule" />
          </div>
        </template>
      </b-tab>

      <!-- Admin ---------------------------------------------------------------------------------->
      <b-tab v-if="showAdminTools">
        <template #title><strong>Admin Control Panel</strong></template>
        <b-tabs pills vertical>
          <b-tab class="force-padding" active>
            <template #title>
              <strong>Domain Hosting Login</strong><br>
              <span>({{ hostingPlatform }})</span>
            </template>
            <domain-admin-hosting-login :subtitle="`(${hostingPlatform})`" />
          </b-tab>
          <b-tab class="force-padding">
            <template #title>
              <strong>Domain Hosting</strong><br>
              <span>{{ getDomainHostingSubtitle() }}</span>
            </template>
            <domain-admin-hosting :subtitle="getDomainHostingSubtitle()" />
          </b-tab>
          <b-tab class="force-padding">
            <template #title>
              <strong>Domain Swap</strong><br>
              <span>(Domains)</span>
            </template>
            <domain-admin-swap subtitle="(Domains)" />
          </b-tab>
          <b-tab class="force-padding">
            <template #title>
              <strong>Authorization Code</strong><br>
              <span>(EPP)</span>
            </template>
            <domain-admin-auth-code subtitle="(EPP)" />
          </b-tab>
        </b-tabs>
      </b-tab>
    </b-tabs>

    <domain-record-delete-modal />
  </div>
</template>

<script>

// DNS Management
import DomainExternalHosts  from '@/components/Domains/panels/DnsManagement/DomainRecordList/DomainExternalHosts.vue'
import DomainMxRecords      from '@/components/Domains/panels/DnsManagement/DomainRecordList/DomainMxRecords.vue'
import DomainNsRecords      from '@/components/Domains/panels/DnsManagement/DomainRecordList/DomainNsRecords.vue'
import DomainSrvRecords      from '@/components/Domains/panels/DnsManagement/DomainRecordList/DomainSrvRecords.vue'
import DomainCaaRecords      from '@/components/Domains/panels/DnsManagement/DomainRecordList/DomainCaaRecords.vue'
import DomainSubdomains     from '@/components/Domains/panels/DnsManagement/DomainRecordList/DomainSubdomains.vue'
import DomainTxtRecords     from '@/components/Domains/panels/DnsManagement/DomainRecordList/DomainTxtRecords.vue'

// Nameservers
import DomainNameservers        from '@/components/Domains/panels/DnsManagement/DomainRecordList/DomainNameservers.vue'
import DomainGlueRecords        from '@/components/Domains/panels/DnsManagement/DomainRecordList/DomainGlueRecords.vue'

// Whois
import DomainWhoisAdmin         from '@/components/Domains/panels/Whois/DomainWhoisAdmin.vue'
import DomainWhoisRegistrant    from '@/components/Domains/panels/Whois/DomainWhoisRegistrant.vue'
import DomainWhoisTechnical     from '@/components/Domains/panels/Whois/DomainWhoisTechnical.vue'

// Emails
import DomainEmails             from '@/components/Domains/panels/Emails/DomainEmails.vue'

import DomainRecordDeleteModal  from '@/components/Domains/DomainRecordDeleteModal.vue'
import DomainSslCertificate from '@/components/Domains/panels/Ssl/DomainSslCertificate.vue'
import { mapActions, mapGetters } from 'vuex'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

// Admin
import DomainAdminHosting from '@/components/Domains/panels/Admin/DomainAdminHosting.vue'
import DomainAdminHostingLogin from '@/components/Domains/panels/Admin/DomainAdminHostingLogin.vue'
import DomainAdminAuthCode from '@/components/Domains/panels/Admin/DomainAdminAuthCode.vue'
import DomainWebsiteHosting
  from '@/components/Domains/panels/WebsiteHosting/DomainWebsiteHosting.vue'
import DomainAdminSwap from '@/components/Domains/panels/Admin/DomainAdminSwap.vue'
import DomainAddonServiceOffer from '@/components/Domains/panels/DomainAddonServiceOffer.vue'

export default {
  name: 'DomainManagementPanel',
  components: {
    DomainAddonServiceOffer,
    DomainWebsiteHosting,
    DomainAdminHostingLogin,
    DomainAdminHosting,
    DomainAdminSwap,
    DomainAdminAuthCode,
    DomainSslCertificate,
    // DNS Management
    DomainExternalHosts,
    DomainTxtRecords,
    DomainSubdomains,
    DomainNsRecords,
    DomainSrvRecords,
    DomainCaaRecords,
    DomainMxRecords,

    // Nameservers
    DomainNameservers,
    DomainGlueRecords,

    // Whois
    DomainWhoisAdmin,
    DomainWhoisRegistrant,
    DomainWhoisTechnical,

    // Emails
    DomainEmails,

    DomainRecordDeleteModal,
  },
  props: {
    domain: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      activeTab: null,
      businessWebsiteHostingService: this.getDomainAddonService('business-website-hosting'),
      businessEmailService: this.getDomainAddonService('business-email'),
      domainSslService: this.getDomainAddonService('domain-ssl'),
      businessWebsiteHostingOfferData: {
        title: 'Business Website Hosting Service',
        content: `
          <p>
            Our <strong>Business Website Hosting</strong> service is free for 90 days for first time
            customers and after that, only $9 a month!
          </p>
          <p>
            It comes pre-built with a Homepage, About Us, and Contact
            Us page–no coding experience needed. We even use open-source software so you can change your website and
            make it your own. You can add your own videos, photos, and text to your website to make it truly showcase
            your business identity.
          </p>
        `,
      },
      businessEmailOfferData: {
        title: 'Business Email Service',
        content: `
          <p>
            Our <strong>Business Email</strong> service is free for 90 days for first time customers, then only $9 a
            month—and you can cancel any time!
          </p>
          <p>
            You get secure, ad-free email service for your business with up to 10 professional email addresses for a
            shared domain.
          </p>
        `,
      },
      domainSslOfferData: {
        title: 'Domain SSL Certificate',
        content: `
          <p>
            Our <strong>Domain SSL</strong> service is free for 90 days for first time customers, then only $9 a month—and you can cancel any time.
          </p>
          <p>
            You get your own SSL certificate hosted & managed by us that will provide you with a secure website for your users with 0 hassle!
          </p>
        `,
      },
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domainSsl',
      'showAdminTools',
      'reactivateService',
      'domainHostingInfo',
    ]),
    ...mapGetters('account', [
      'activeServicesByType',
    ]),
    hasInActiveDomainAddonServices() {
      return this.isDomainAddonServiceInActive(this.businessWebsiteHostingService) ||
        this.isDomainAddonServiceInActive(this.businessEmailService) ||
        this.isDomainAddonServiceInActive(this.domainSslService)
    },
    isExternal(){
      return domain => domain.status === 'external'
    },
    isDeleted(){
      return domain => domain.status === 'deleted'
    },
    hostingPlatform() {
      return this.domainHostingInfo?.platform || "No Platform"
    },
  },
  async mounted() {
    this.activeTab = this.$route.query.activeTab || this.$route.params.activeTab

    // Set company and checkout context for offer pages when client has an inactive addon service.
    if (this.hasInActiveDomainAddonServices) {
      await this.setCurrentCompany({ id: this.domain.company_id, force: false, preserveStageline: true })
      await this.setupContext({ companyId: this.domain.company_id })
      await this.fetchCompanyVouchers(this.domain.company_id)
    }
  },
  methods: {
    ...mapActions('companies', [
      'setCurrentCompany',
    ]),
    ...mapActions('checkout', [
      'loadCartItems',
      'setupContext',
    ]),
    ...mapActions('vouchers', [
      'fetchCompanyVouchers',
    ]),
    async logDomainAddonInteraction(name, servicesAdded) {
      const interaction = { name, action: 'added', servicesAdded }
      await this.logInteraction('domain-addon-service', interaction)
    },
    async logWebmailVisitInteraction() {
      const interaction = { name: 'business-webmail-visit', action: 'visit' }
      await this.logInteraction('business-webmail', interaction)
    },
    async logInteraction(subCategory, interaction) {
      try {
        this.clientInteractionLog = await createOrFindClientInteractionLog({
          category: 'business-identity',
          subCategory,
          objectId: this.domain.id,
          objectTable: 'Domain',
          companyId: this.domain.company_id,
          interaction: { type: 'button', ...interaction },
        })
      } catch (error) {
        return error
      }
    },
    getDomainHostingSubtitle() {
      return this.activeServicesByType('business-website-hosting').length ?
        '(Wordpress)' :
        '(Email Only)'
    },
    getDomainAddonService(serviceType) {
      return this.domain?.services.find((service) => service.type === serviceType)
    },
    isDomainAddonServiceActive(service) {
      if (!service) { return false }

      return ['active', 'trial-active', 'waiting on payment'].includes(service.status)
    },
    isDomainAddonServiceInActive(service) {
      if (!service) { return true }

      return !this.isDomainAddonServiceActive(service) && !this.isDomainAddonServiceInGracePeriod(service)
    },
    isDomainAddonServiceInGracePeriod(service) {
      if (!service) { return false }

      return service.cancellation_status === 'new' || service.cancellation_status === 'suspended'
    },
    isDomainAddonServiceInReactivationFailed(service) {
      if (!service) { return false }

      return service.cancellation_status === 'reactivation-failed'
    },
    getDomainAddonServiceClass(service) {
      if (!service || !service.cancellation_status) {
        return ''
      }

      return service.cancellation_status === 'new' ? 'text-warning' : 'text-danger'
    },
    showDomainAddonServiceTab(service) {
      if (!service) { return false }

      return this.isDomainAddonServiceActive(service) || this.isDomainAddonServiceInGracePeriod(service)
    },
    getUpgradePlan(serviceType) {
      const webHostingIsInActive = this.isDomainAddonServiceInActive(this.businessWebsiteHostingService)
      const emailIsInActive = this.isDomainAddonServiceInActive(this.businessEmailService)
      const isWebOnly = !webHostingIsInActive && emailIsInActive
      const isEmailOnly = webHostingIsInActive && !emailIsInActive
      const isWebHosting = serviceType === 'business-website-hosting'

      if (isWebOnly || isEmailOnly) {
        return 'business-identity-web'
      } else {
        return isWebHosting ? 'business-identity-webonly' : 'business-identity-email'
      }
    },
    getDomainSslOtherProductType() {
      if (this.isDomainAddonServiceActive(this.businessWebsiteHostingService) && this.isDomainAddonServiceActive(this.businessEmailService)) {
        return null
      } else {
        return this.isDomainAddonServiceActive(this.businessWebsiteHostingService) ? 'business-email' : 'business-website-hosting'
      }
    },
    tooltipText(service){
      return service.cancellation_status === 'reactivation-failed'
        ? 'Service reactivation failed. Please contact support.'
        : ''
    },
  },
}
</script>

<style lang="scss" scoped>

#domain-management-panel {
  // TODO disable mobile support, can implement in a later phase, if ever.
  min-width: 1100px;
  ::v-deep .btn {
    border-radius: .25rem;
  }
  ::v-deep .btn-primary {
    background-color: #2E3798;
    border-color: #2E3798;
  }
  ::v-deep .btn-outline-primary {
    color: #2E3798;
    border-color: #2E3798;

    &:hover, &:active {
      background-color: #2E3798 !important;
      border-color: #2E3798 !important;
      color: #FFFFFF !important;
    }
  }
  > .tabs {
    ::v-deep ul[role=tablist] {
      padding: .8em 0;
    }
    ::v-deep .nav-link {
      &:not(.active) {
        color: #4E4E52;

        &:hover {
          color: black !important;
        }
      }
      &.active {
        border-bottom: 2px solid #2E3798;
        color: #2E3798;
        background: none;
        border-radius: 0;
      }
    }
    .tabs.row {
      margin: 0 !important;
      ::v-deep .col-auto {
        .nav-pills {
          border-top: none;
        }
        background: #e9e9ea;
        border: 1px solid $ct-ui-border-color-light;
        border-radius: 8px 0 0 8px;
        width: 252px;
        .nav-link {
          padding: 1rem;
          color: black !important;
          font-size: .9rem;
          font-weight: 400;
          strong {
            font-weight: 700;
          }
        }
        .nav-link.active {
          background-color: $gray-100 !important;
        }
      }
      ::v-deep .tab-content {
        padding: 0;
        border-radius: 0 8px 8px 0;
        border: 1px solid $ct-ui-border-color-light;
        border-left: none;
        .force-padding {
          padding: 0 2.875rem 2rem 2.875rem !important;
        }
        .tabs.row {
          .col-auto {
            border-radius: 0;
            background: #f1f1f1;
          }
          .tab-content {
            padding: 0 2.875rem 2rem 2.875rem !important;
          }
        }
      }
    }
  }

  .warning-icon {
    font-size: 100%;
    vertical-align: middle;
    margin-bottom: 0.25rem;
  }

  .tab-divider {
    width: 100%;
    position: relative;
    .vertical-rule {
      position: absolute;
      height: 46px;
      border-left: solid lightgrey 1px !important;
      top: -10px;
    }
  }
}

</style>
