var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "b-card",
            { staticClass: "mb-1" },
            [
              _c("b-form-group", {
                attrs: { label: "Greeting type:" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ voicemailGreeting }) {
                      return [
                        _c("b-form-radio-group", {
                          attrs: {
                            id: "voicemailGreeting",
                            options: _vm.optionsForGreeting,
                            "aria-describedby": voicemailGreeting,
                            name: "radio-options-greeting",
                            size: "md",
                            stacked: "",
                          },
                          model: {
                            value: _vm.greetingTypeSelected,
                            callback: function ($$v) {
                              _vm.greetingTypeSelected = $$v
                            },
                            expression: "greetingTypeSelected",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm.greetingTypeSelected === "custom"
                ? _c(
                    "div",
                    [
                      _c("b-button", { on: { click: _vm.showRecorder } }, [
                        _vm._v("\n          Manage Custom Greeting\n        "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-card",
            { staticClass: "mb-1" },
            [
              _c("b-form-group", {
                attrs: { label: "Voicemail retention period: " },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ voicemailRetention }) {
                      return [
                        _c("b-form-radio-group", {
                          attrs: {
                            id: "voicemailRetention",
                            options: _vm.optionsForVoicemailRetention,
                            "aria-describedby": voicemailRetention,
                            name: "radio-options-retention",
                            size: "md",
                            stacked: "",
                          },
                          model: {
                            value: _vm.retentionPeriodSelected,
                            callback: function ($$v) {
                              _vm.retentionPeriodSelected = $$v
                            },
                            expression: "retentionPeriodSelected",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-button",
        {
          staticClass: "btn-primary button-settings",
          on: { click: _vm.submit },
        },
        [_vm._v("\n    Save Settings\n  ")]
      ),
      _c("virtual-phone-voicemail-recorder-modal", {
        ref: "virtual-phone-voicemail-recorder-modal",
        attrs: {
          "custom-voicemail-url": _vm.customVoicemailUrl,
          "upload-url": _vm.uploadUrl,
        },
        on: { success: _vm.successfulUpload, failure: _vm.failedUpload },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }