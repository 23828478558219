<template>
  <b-modal
    id="email-change-password-modal"
    ref="email-change-password-modal"
    no-close-on-backdrop
    centered
    title="Change Password"
  >
    <template v-slot:default>
      <ct-centered-spinner v-if="loading" />
      <b-container v-else>
        <b-form-group label="Password" label-for="password">
          <validation-provider v-slot="{ validate, errors }" name="password" rules="required|domainEmailPasswordValidator">
            <b-input-group>
              <b-form-input
                id="password"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                @input="validateField(validate, 'isPasswordValid')"
              />

              <b-input-group-append class="password-visibility">
                <fa-icon
                  :icon="showPassword ? 'eye' : 'eye-slash'"
                  style="cursor: pointer;"
                  @click="toggleField('showPassword')"
                />
              </b-input-group-append>
            </b-input-group>
            <span class="text-danger small">
              {{ errors.length > 0 ? errors[0] : '' }}
            </span>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Confirm Password" label-for="password">
          <validation-provider v-slot="{ validate, errors }" name="password" :rules="`required|passwordConfirmationMatches:${password}`">
            <b-input-group>
              <b-form-input
                id="password"
                v-model="passwordConfirmation"
                :type="showPasswordConfirmation ? 'text' : 'password'"
                @input="validateField(validate, 'isPasswordConfirmationValid')"
              />

              <b-input-group-append class="password-visibility">
                <fa-icon
                  :icon="showPasswordConfirmation ? 'eye' : 'eye-slash'"
                  style="cursor: pointer;"
                  @click="toggleField('showPasswordConfirmation')"
                />
              </b-input-group-append>
            </b-input-group>
            <span class="text-danger small">
              {{ errors.length > 0 ? errors[0] : '' }}
            </span>
          </validation-provider>
        </b-form-group>
      </b-container>
    </template>

    <template v-slot:modal-footer>
      <b-button
        variant="primary"
        block
        :disabled="!isPasswordValid || loading || !isPasswordConfirmationValid"
        @click="confirmEmailChangePassword"
      >
        Update Password
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { makeToastMixin } from '@/mixins/makeToastMixin.js'
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'EmailChangePasswordModal',
  components: {
    CtCenteredSpinner,
    ValidationProvider,
  },
  mixins: [makeToastMixin],
  props: {
    email: Object,
  },
  data() {
    return {
      loading: false,
      showPassword: false,
      showPasswordConfirmation: false,
      password: null,
      passwordConfirmation: null,
      errors: [],
      isPasswordValid: false,
      isPasswordConfirmationValid: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
    ]),
  },
  methods: {
    ...mapActions('domainEmails', [
      'changeEmailPassword',
    ]),
    async confirmEmailChangePassword() {
      this.loading = true
      const vm = this

      function afterFunction(result) {
        if (result?.success) {
          vm.successToast('Success', 'Updated email password')
        } else {
          vm.errorToast('Error', 'Unable to update email password')
        }

        vm.$emit('hide')
        vm.clearPasswords()
        vm.loading = false
      }

      await this.changeEmailPassword({
        domainId: this.email.domain_id,
        emailId: this.email.id,
        password: this.password,
        afterFunction: afterFunction,
      })
    },
    toggleField(field) {
      this[field] = !this[field]
    },
    async validateField(validation, field) {
      this[field] = (await validation()).valid
    },
    clearPasswords() {
      this.password = null
      this.passwordConfirmation = null
    },
  },
}
</script>

<style lang="scss" scoped>
#email-change-password-modal {
  input#password {
    height: 46px !important;
    min-height: 46px !important;
    border-radius: 4px !important;
  }

  .password-visibility {
    right: 1rem;
    position: absolute;
    top: 1rem;
    z-index: 1000;
  }
}

</style>
