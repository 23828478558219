<template>
  <div class="srv-record">
    <b-form>
      <b-row>
        <b-col cols="2">
          <b-form-group label="Name" label-for="name">
            <b-form-input
              id="name"
              v-model="record.name"
              v-b-tooltip.hover
              :disabled="disabled"
              placeholder="@"
              :title="fullHostname"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col cols="1">
          <b-form-group label="Priority" label-for="priority">
            <b-form-input
              id="priority"
              v-model="record.priority"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col cols="1">
          <b-form-group label="Weight" label-for="weight">
            <b-form-input
              id="weight"
              v-model="weight"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col cols="1">
          <b-form-group label="Port" label-for="port">
            <b-form-input
              id="port"
              v-model="port"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Target" label-for="target">
            <b-form-input
              id="target"
              v-model="target"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="TTL" label-for="ttl">
            <b-form-select
              id="ttl"
              v-model="record.ttl"
              :options="ttlOptions"
              :disabled="disabled"
              @input="input"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { TTL_OPTIONS } from '@/common/modules/domains.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Srv',
  components: {},
  props: {
    record: {
      type: Object,
      default: () => {},
      required: false,
    },
    disable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      disabled: true,
      ttlOptions: TTL_OPTIONS,
      weight: "",
      port: "",
      target: "",
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
      'recordHostname',
    ]),
    fullHostname() {
      return this.recordHostname(this.record)
    },
  },
  watch: {
    disable(value) {
      this.disabled = value
    },
    record() {
      this.setData()
    },
  },
  mounted() {
    this.setData()
  },
  created() {
    this.disabled = !this.record.temp ? this.disable : false
  },
  methods: {
    input() {
      this.record.data = `${this.weight || ""} ${this.port || ""} ${this.target || ""}`
      this.$emit('input')
    },
    setData() {
      if (this.record.data) {
        let data = this.record.data?.split(" ")
        this.weight = data[0]
        this.port = data[1]
        this.target = data[2]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
