<template>
  <div>
    <b-modal id="create-whitelisted-ip" hide-footer
             title="Add Whitelisted Ip"
             :size="size"
    >
      <b-form @submit.prevent="createWhitelistedIp">
        <b-form-group>
          <label for="ip" class="label-sm">
            IP address
          </label>
          <b-form-input
            v-model="ip"
            name="ip"
            placeholder="127.0.0.1"
            :state="touched ? ipIsValid : null"
            :aria-invalid="!ipIsValid"
            required
            trim
            autofocus
            @blur="touched = true"
          />
          <b-form-invalid-feedback>
            Please enter a valid ipv4 or ipv6 address
          </b-form-invalid-feedback>
          <label for="description" class="label-sm mt-4">
            Give your IP address a description (optional)
          </label>
          <b-form-textarea
            v-model="description"
            class="mb-4"
            name="description"
            placeholder="Enter a description..."
            rows="3"
            max-rows="6"
            maxlength="500"
            trim
          />
        </b-form-group>
        <b-button
          class="mt-4 submit-btn-width"
          variant="primary"
          type="submit"
          aria-label="Submit button"
          :disabled="submitLoading"
        >
          Submit
        </b-button>
      </b-form>
    </b-modal>
    <b-modal
      id="edit-whitelisted-ip"
      hide-footer
      title="Edit Whitelisted Ip"
      :size="size"
    >
      <b-form @submit.prevent="editWhitelistedIp">
        <b-form-group>
          <label for="ip" class="label-sm">
            IP address
          </label>
          <b-form-input
            v-model="ip"
            class="mb-4"
            name="ip"
            readonly
            aria-readonly="true"
          />
          <label for="description" class="label-sm">
            Edit description for ip {{ ip }}
          </label>
          <b-form-textarea
            v-model="description"
            class="mb-3"
            name="description"
            placeholder="Enter a description..."
            rows="3"
            max-rows="6"
            maxlength="500"
            autofocus
          />
        </b-form-group>
        <b-button
          class="submit-btn-width"
          variant="primary"
          type="submit"
          aria-label="Save button"
          :disabled="submitLoading"
        >
          Save
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import ipRegex from 'ip-regex'
import { mapActions, mapGetters } from 'vuex'
import { copyClipboardMixin } from '@/mixins/copyClipboardMixin'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'AccountWhitelistedIpModal',
  mixins: [copyClipboardMixin, makeToastMixin],
  props: {
    bus: {
      type: Vue,
      required: true,
    },
  },
  data() {
    return {
      description: '',
      ip: '',
      size: 'xl',
      submitLoading: false,
      touched: false,
    }
  },
  computed: {
    ...mapGetters('account', ['actualAccountID']),
    ipIsValid() {
      if (!this.ip) return false
      return ipRegex({ exact: true }).test(this.ip) || ipRegex.v6({ exact: true }).test(this.ip)
    },
  },
  mounted() {
    this.bus.$on('show-create-whitelisted-ip', () => {
      this.reset()
      this.$bvModal.show('create-whitelisted-ip')
    })
    this.bus.$on('show-edit-whitelisted-ip', (ipAddress) => {
      this.reset(ipAddress)
      this.$bvModal.show('edit-whitelisted-ip')
    })
  },
  methods: {
    ...mapActions('accountWhitelistedIp', ['create', 'update']),
    reset(ipAddress) {
      this.description = ipAddress?.description || ''
      this.currentKeyId = ipAddress?.id || ''
      this.ip = ipAddress?.ip || ''
      this.touched = false
    },
    async createWhitelistedIp() {
      if (this.submitLoading) {
        return
      }
      if (!this.ipIsValid) {
        this.errorToast(`Invalid IP`, 'Please enter a valid IP address.')
        return
      }
      try {
        this.submitLoading = true
        await this.create({
          record: {
            accountId: this.actualAccountID,
            description: this.description,
            ip: this.ip,
          },
        })
        this.$bvModal.hide('create-whitelisted-ip')
      } catch (error) {
        this.errorToast('Error', 'Failed to create api key')
      } finally {
        this.submitLoading = false
      }
    },
    async editWhitelistedIp() {
      if (this.submitLoading) {
        return
      }
      try {
        this.submitLoading = true
        await this.update({
          record: {
            id: this.currentKeyId,
            accountId: this.actualAccountID,
            description: this.description,
          },
        })
        this.$bvModal.hide('edit-whitelisted-ip')
      } catch (error) {
        this.errorToast('Error', 'Failed to update api key')
      } finally {
        this.submitLoading = false
      }
    },
  },
}
</script>
<style scoped></style>
