import { GET } from '@/api/client'

export const moduleSchema = {
  account: {
    uuid: 'id',
    attributes: {
      id: String,
      websiteId: String,
      type: String,
      signupDetails: Object,
      ownerId: String,
      people: Array,
    },
    createOrReplaceAttributes: ['id'],
    hasOne: ['website', 'person'],
    hasMany: [
      { invite: 'accountId' },
      { draft: 'accountId' },
      { card: 'accountId' },
      { shoppingBasketItem: 'accountId' },
    ],
    belongsTo: [{ website: 'websiteId' }],
  },
  accountAccessKey: {
    uuid: 'id',
    attributes: {
      id: String,
      accountId: String,
      createdAt: String,
      updatedAt: String,
      deletedAt: String,
      accessKey: String,
      secretKey: String,
      environment: String,
      description: String,
    },
    createOrReplaceAttributes: ['id'],
    belongsTo: [{ account: 'accountId' }],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/account_access_keys',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/account_access_keys?enabled=all',
      },
    },
  },
  accountWhitelistedIp: {
    uuid: 'id',
    attributes: {
      id: String,
      accountId: String,
      createdAt: String,
      updatedAt: String,
      ip: String,
      description: String,
    },
    createOrReplaceAttributes: ['id'],
    belongsTo: [{ account: 'accountId' }],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/account_whitelisted_ips',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/account_whitelisted_ips',
      },
    },
  },
  agency: {
    uuid: 'id',
    attributes: {
      id: String,
      createdAt: Date,
      updatedAt: Date,
      name: String,
      description: String,
      locality: String,
      dependentFilingNames: Array,
      dependentFilingsInfo: Array,
    },
    hasMany: [
      { agency_phone: 'agencyId' },
      { agency_email: 'agencyId' },
      { agency_address: 'agencyId' },
    ],
    createOrReplaceAttributes: ['id'],
  },
  agencyAddress: {
    uuid: 'id',
    attributes: {
      id: String,
      createdAt: Date,
      updatedAt: Date,
      agencyId: String,
      line1: String,
      line2: String,
      line3: String,
      city: String,
      stateProvinceRegion: String,
      county: String,
      zipPostalCode: String,
      country: String,
      formatted: String,
      fromGoogle: Boolean,
      type: String,
      kind: String,
      label: String,
      description: String,
      public: Boolean,
      timezone: String,
    },
    belongsTo: [{ agency: 'agencyId' }],
    createOrReplaceAttributes: ['id'],
  },
  agencyEmail: {
    uuid: 'id',
    attributes: {
      id: String,
      createdAt: Date,
      updatedAt: Date,
      type: String,
      label: String,
      description: String,
      agencyId: String,
      emailAddress: String,
      public: Boolean,
    },
    belongsTo: [{ agency: 'agencyId' }],
    createOrReplaceAttributes: ['id'],
  },
  agencyPhone: {
    uuid: 'id',
    attributes: {
      id: String,
      createdAt: Date,
      updatedAt: Date,
      kind: String,
      label: String,
      description: String,
      agencyId: String,
      phoneNumber: String,
    },
    belongsTo: [{ agency: 'agencyId' }],
    createOrReplaceAttributes: ['id'],
  },
  card: {
    uuid: 'id',
    attributes: {
      id: String,
      accountId: String,
      number: Number,
      name: String,
      expiration: Date,
      createdAt: Date,
      reset: Function,
    },
    belongsTo: [{ account: 'accountId' }],
    createOrReplaceAttributes: ['id'],
  },
  companies: {
    uuid: 'id',
    attributes: {
      id: String,
      name: String,
      jurisdictions: Array,
      entityType: String,
      ownerId: String,
      details: Object,
      entityTypeId: String,
      homeJurisdiction: Object,
    },
    createOrReplaceAttributes: ['id'],
    belongsTo: [{ account: 'ownerId' }],
    hasMany: [
      { invite: 'companyId' },
      { registration: 'companyId' },
      { invoice: 'companyId' },
      { service: 'companyId' },
      { order: 'companyId' },
      { sharingAccessRule: 'companyId' },
      { complianceEvent: 'companyId' },
      { shoppingBasketItem: 'companyId' },
    ],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/companies',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/companies',
      },
    },
  },
  complianceEvent: {
    uuid: 'id',
    attributes: {
      id: String,
      registrationId: String,
      companyId: String,
      ruleType: String,
      dueDate: String,
      filingId: String,
      companyName: String,
      jurisdictionId: String,
    },
    belongsTo: [
      { registration: 'registrationId' },
      { company: 'companyId' },
      { filing: 'filingId' },
    ],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/compliance_events',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/compliance_events',
      },
    },
  },
  documents: {
    uuid: 'id',
    attributes: {
      id: String,
      title: String,
      status: String,
      companyId: String,
      createdAt: String,
      updatedAt: String,
      type: String,
      source: String,
      jurisdiction: String,
      companyName: Object,
      paymentLocked: Boolean,
      pages: Array,
      invoices: Array,
      priceToUnlock: Number,
      documentCanBeRequested: Boolean,
      clientDocumentRequestFee: Object,
      isPackage: Boolean,
      isOversized: Boolean,
      approvedAt: String,
    },
    createOrReplaceAttributes: ['id'],
    belongsTo: [{ company: 'companyId' }],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/client_documents',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/client_documents',
      },
    },
  },
  draft: {
    uuid: 'id',
    attributes: {
      id: String,
      accountId: String,
      companyId: String,
      category: String,
      productId: String,
      productType: String,
      filingMethodId: String,
      flow: String,
      currentStep: Number,
      currentComponent: Number,
      steps: Array,
      data: Object,
      createdAt: Date,
      reset: Function,
      tos: Boolean,
      cardId: String,
    },
    belongsTo: [{ account: 'accountId' }],
    createOrReplaceAttributes: ['id'],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/drafts',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/drafts',
      },
    },
  },
  filingMethods: {
    uuid: 'id',
    attributes: {
      id: String,
      agencyId: String,
      name: String,
      locality: String,
      description: String,
      dependantFilingNames: Array,
      dependantFilingsInfo: Array,
    },
    hasMany: [{ complianceEvent: 'complianceEventId' }],
    // belongsTo: [{ agency: 'agencyId' }],
    // belongsTo: [{ agency: 'agencyId' }],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/filings',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/filings',
      },
    },
  },
  filingStep: {
    uuid: 'id',
    attributes: {
      id: String,
      accountId: String,
      name: String,
      updatedAt: Date,
      createdAt: Date,
      priority: String,
      instructions: String,
      filingMethodId: String,
      forStaff: Boolean,
      requiredResourceId: String,
      resource: Array,
    },
    hasOne: ['resource'],
    createOrReplaceAttributes: ['id'],
    endpoints: {
      fetchAll: {
        method: 'get',
        path: '/api/v3/client/filing-steps',
      },
    },
  },
  invite: {
    uuid: 'id',
    attributes: {
      companyId: String,
      companyOwnerAccountId: String,
      createdAt: Date,
      emailAddress: String,
      id: String,
      inviteStatus: String,
      invitedAccountId: String,
      role: Number,
      updatedAt: Date,
      websiteId: String,
    },
    createOrReplaceAttributes: ['id'],
    belongsTo: [{ account: 'companyOwnerAccountId', company: 'companyId' }],
  },
  invoices: {
    uuid: 'id',
    attributes: {
      id: String,
      invoiceNumber: String,
      status: String,
      companyId: String,
      createdAt: String,
      updatedAt: String,
      dueDate: String,
      contact: Object,
      recipient: Object,
      paymentInfo: Object,
      companyName: String,
      paidDate: String,
      total: Number,
      type: String,
      invoiceLineItems: Array,
    },
    createOrReplaceAttributes: ['id'],
    belongsTo: [{ company: 'companyId' }],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/invoices',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/invoices',
      },
    },
  },
  jurisdictions: {
    uuid: 'id',
    attributes: {
      id: String,
      createdAt: String,
      country: String,
      jurisdictionId: String,
      stateProvinceRegion: Object,
      abbreviation: String,
      formatted: String,
    },
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/jurisdictions',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/jurisdictions',
      },
    },
    createOrReplaceAttributes: ['id'],
    hasMany: [{ registrations: 'jurisdictionId' }, { filings: 'jurisdictionId' }],
  },
  notificationSetting: {
    uuid: 'id',
    attributes: {
      id: String,
      created_at: Date,
      updated_at: Date,
      account_id: String,
      person_email_id: String,
      service_of_process: Boolean,
      documents: Boolean,
      invoices: Boolean,
      compliance: Boolean,
    },
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/account/account_notification_preferences',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/account/account_notification_preferences',
      },
    },
  },
  orders: {
    uuid: 'id',
    attributes: {
      id: String,
      orderNumber: String,
      status: String,
      companyId: String,
      invoiceId: String,
      createdAt: Date,
      updatedAt: Date,
      orderItems: Array,
    },
    createOrReplaceAttributes: ['id'],
    belongsTo: [{ company: 'companyId' }],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/orders',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/orders',
      },
    },
  },
  person: {
    uuid: 'id',
    attributes: { id: String, firstName: String, lastName: String, accountId: String },
    createOrReplaceAttributes: ['id'],
    belongsTo: [{ account: 'accountId' }],
    hasMany: [{ personEmail: 'personId' }],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/person',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/person',
      },
    },
  },
  personAddress: {
    uuid: 'id',
    attributes: {
      id: String,
      personId: String,
      createdAt: Date,
      updatedAt: Date,
      agencyId: String,
      line1: String,
      line2: String,
      line3: String,
      city: String,
      stateProvinceRegion: String,
      county: String,
      zipPostalCode: String,
      country: String,
      formatted: String,
      fromGoogle: Boolean,
      type: String,
      kind: String,
      label: String,
      description: String,
      public: Boolean,
      timezone: String,
    },
    belongsTo: [{ person: 'personId' }],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/person_addresses',
      },
      fetchAll: {
        method: 'get',
        path: '/api/v3/client/person_addresses',
      },
    },
  },
  personEmail: {
    uuid: 'id',
    attributes: {
      id: String,
      personId: String,
      createdAt: Date,
      updatedAt: Date,
      emailAddress: String,
    },
    belongsTo: [{ person: 'personId' }],
    endpoints: {
      fetchId: {
        method: 'get',
        path: '/api/v3/client/person_emails',
      },
      fetchAll: {
        method: 'get',
        path: '/api/v3/client/person_emails',
      },
    },
  },
  personPhone: {
    uuid: 'id',
    attributes: {
      id: String,
      personId: String,
      createdAt: Date,
      updatedAt: Date,
      phoneNumber: String,
    },
    belongsTo: [{ person: 'personId' }],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/person_phones',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/person_phones',
      },
    },
  },
  products: {
    uuid: 'id',
    attributes: {
      id: String,
      name: String,
      filingName: String,
      price: String,
      filingMethods: Array,
      kind: String,
      jurisdictions: Object,
    },
    createOrReplaceAttributes: ['id'],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/products/filing_products',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/products/filing_products',
      },
    },
  },
  registration: {
    uuid: 'id',
    attributes: {
      id: String,
      createdAt: Number,
      updatedAt: Number,
      legalName: String,
      companyId: String,
      jurisdictionId: String,
      agencyId: String,
      entityId: String,
      fileDate: Date,
      fakeFileDate: Number,
      overrideFileDate: Date,
      locality: String,
      fiscalYearEnd: Number,
      hasComplianceService: Boolean,
      details: Object,
      validatedAt: Boolean,
      jurisdiction: Object,
    },
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/registrations',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/registrations',
      },
    },
    createOrReplaceAttributes: ['id'],
    belongsTo: [{ company: 'companyId' }, { jurisdiction: 'jurisdictionId' }],
    hasMany: [{ complianceEvents: 'registrationId' }],
  },
  resource: {
    uuid: 'id',
    attributes: {
      id: String,
      createdAt: Date,
      updatedAt: Date,
      name: String,
      url: String,
      pages: Array,
      kind: String,
      allowedOperations: String,
      valid: Boolean,
      priority: String,
    },
    belongsTo: [{ filingStep: 'requiredResourceId' }],
    createOrReplaceAttributes: ['id'],
  },
  services: {
    uuid: 'id',
    attributes: {
      id: String,
      autopayPayableId: String,
      duration: Number,
      data: Object,
      companyId: String,
      start: Number,
      stop: Number,
      status: String,
      type: String,
      jurisdiction: Array,
      product: Object,
    },
    createOrReplaceAttributes: ['id'],
    belongsTo: [{ account: 'companyId' }],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/services',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/services',
      },
    },
  },
  session: {
    uuid: 'id',
    attributes: { token: String, expiration: String, account: String },
    hasOne: ['account'],
  },
  sharingAccessRule: {
    uuid: 'id',
    attributes: {
      id: String,
      companyId: String,
      accountId: String,
      role: Number,
      permissions: Array,
    },
    createOrReplaceAttributes: ['id'],
    belongsTo: [{ company: 'companyId' }],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/companies/:companyId/access_rules/:id',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/companies/:companyId/access_rules',
      },
    },
  },
  shoppingBasket: {
    uuid: 'id',
    attributes: { id: String, createdAt: Date, updatedAt: Date },
    hasMany: [{ shoppingBasketItem: 'shoppingBasketId' }],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/shopping_baskets',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/shopping_baskets',
      },
    },
  },
  shoppingBasketItem: {
    uuid: 'id',
    attributes: {
      id: String,
      createdAt: Date,
      updatedAt: Date,
      data: Object,
      companyId: String,
      accountId: String,
      productId: String,
      productKind: String,
      total: Number,
      title: String,
      dynamicPrice: Boolean,
      isCompleted: Boolean,
      shoppingBasketId: String,
    },
    belongsTo: [
      { company: 'companyId' },
      { account: 'accountId' },
      { shoppingBasket: 'shoppingBasketId' },
    ],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/shopping_baskets/:shoppingBasketId/shopping_basket_items',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/shopping_baskets/:shoppingBasketId/shopping_basket_items',
      },
    },
  },
  website: {
    uuid: 'id',
    attributes: { id: String, name: String, subdomain: String },
    createOrReplaceAttributes: ['id'],
    hasMany: [{ account: 'websiteId' }],
    endpoints: {
      fetchId: {
        method: GET,
        path: '/api/v3/client/websites',
      },
      fetchAll: {
        method: GET,
        path: '/api/v3/client/websites',
      },
    },
  },
}
