var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-lg-6 col-md-12" }, [
    _c(
      "div",
      [
        _c(
          "div",
          [
            !_vm.unredeemedDomainVoucher
              ? _c(
                  "b-button",
                  {
                    staticClass: "action-button",
                    attrs: {
                      disabled: _vm.loading,
                      variant: "primary",
                      "aria-label": "Register a New Domain button",
                    },
                    on: { click: _vm.registerClick },
                  },
                  [
                    _vm.loading
                      ? _c("b-spinner", { staticClass: "spinner" })
                      : _c(
                          "div",
                          [
                            _c("b-icon", {
                              attrs: { scale: "1.5", icon: "plus" },
                            }),
                            _vm._v(" Register a New Domain\n        "),
                          ],
                          1
                        ),
                  ],
                  1
                )
              : _c(
                  "b-button",
                  {
                    staticClass: "action-button",
                    attrs: {
                      disabled: _vm.loading,
                      variant: "primary",
                      "aria-label": "Register Your New Domain Now button",
                    },
                    on: { click: _vm.redeemVoucher },
                  },
                  [
                    _vm.loading
                      ? _c("b-spinner", { staticClass: "spinner" })
                      : _c("div", [
                          _vm._v(
                            "\n          Register Your New Domain Now!\n        "
                          ),
                        ]),
                  ],
                  1
                ),
          ],
          1
        ),
        _c(
          "b-modal",
          {
            attrs: {
              title: "Select Company",
              "no-close-on-backdrop": "",
              size: "lg",
              "ok-disabled": !_vm.currentCompany,
            },
            on: { ok: _vm.determineRoute },
            model: {
              value: _vm.showCompanySelect,
              callback: function ($$v) {
                _vm.showCompanySelect = $$v
              },
              expression: "showCompanySelect",
            },
          },
          [
            _c("div", [
              _vm._v(
                "Please select the company you wish to register a new domain under."
              ),
            ]),
            _c("company-select", { on: { input: _vm.setSelectedCompany } }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }