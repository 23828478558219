import * as t from '../mutations'
import { createConsumer } from '@rails/actioncable'
import store from '@/store'

const STATE = {
  actionCable: null,
  requests: [],
}

const GETTERS = {
  actionCable: state => state.actionCable,
  requests: state => state.requests,
}

const ACTIONS = {
  async createActionCable({ commit, dispatch, getters }) {
    let cable = getters.actionCable || await createConsumer(`/api/v3/cable?account_id=${store.state.session.accountId}`)

    cable.subscriptions.create({ channel: 'CallbackChannel' }, {
      received(data) {
        dispatch('consumeRequest', data)
      },
    })

    commit(t.SET_ACTION_CABLE, cable)
  },
  async addRequestCallbackFunction({ getters, commit }, { requestId, callbackFunction, requestName, showResponseToast = false, showRequestToast = false }) {
    if (showRequestToast) {
      this._vm.$bvToast.toast(`Waiting for ${requestName} response. Feel free to continue using the website.`,
        {
          title: "Event",
          variant: "secondary",
          solid: true,
        })
    }

    const requests = getters.requests

    requests[requestId] = { callbackFunction: callbackFunction, requestName: requestName, showToast: showResponseToast }

    commit(t.SET_REQUESTS, requests)
  },
  async consumeRequest({ getters, commit }, data) {
    const requests = await getters.requests

    if (!requests[data.request_id]) return

    const func = requests[data.request_id]?.callbackFunction
    const requestName = requests[data.request_id]?.requestName
    const showToast = requests[data.request_id]?.showToast

    delete requests[data.request_id]

    if (data.error && requestName) {
      this._vm.$bvToast.toast(`${requestName} request failed.`,
        {
          title: "Error",
          variant: "danger",
          solid: true,
        })
      return
    }
    else if (!data.error && showToast) {
      this._vm.$bvToast.toast(`${requestName} successful.`,
        {
          title: "Success",
          variant: "primary",
          solid: true,
        })
    }

    await func(data)

    commit(t.SET_REQUESTS, requests)
  },
}

const MUTATIONS = {
  [t.SET_ACTION_CABLE](state, actionCable) {
    state.actionCable = actionCable
  },
  [t.SET_REQUESTS](state, requests) {
    state.requests = requests
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
