var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", { staticClass: "mb-4" }, [_vm._v(_vm._s(_vm.title))]),
      _c("p", { staticClass: "mb-4" }, [
        _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
      ]),
      _c(
        "b-button",
        {
          staticClass:
            "mr-2 border border-primary text-primary secondary-button",
          attrs: {
            href: _vm.learnMoreURL,
            variant: "link",
            "aria-label": "Learn More button",
          },
        },
        [_vm._v("\n    Learn More\n  ")]
      ),
      _c(
        "b-button",
        {
          staticClass: "primary-button ml-2",
          attrs: { variant: "primary", "aria-label": "Add IP button" },
          on: { click: _vm.ctaClicked },
        },
        [_vm._v("\n    " + _vm._s(_vm.ctaText) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }