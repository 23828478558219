<template>
  <div>
    <h2 class="mb-4">{{ title }}</h2>
    <p class="mb-4">
      {{ text }}
    </p>
    <b-button
      :href="learnMoreURL"
      class="mr-2 border border-primary text-primary secondary-button"
      variant="link"
      aria-label="Learn More button"
    >
      Learn More
    </b-button>
    <b-button
      class="primary-button ml-2"
      variant="primary"
      aria-label="Add IP button"
      @click="ctaClicked"
    >
      {{ ctaText }}
    </b-button>
  </div>
</template>
<script>
export default {
  name: 'ApiManagementEmptyState',
  props: {
    title: { type: String, required: true },
    text: { type: String, required: true },
    ctaText: { type: String, required: true },
    ctaClicked: { type: Function, required: true },
  },
  data() {
    return {
      learnMoreURL: 'https://docs.corporatetools.com/',
    }
  },
}
</script>
<style scoped lang="scss">
@import 'api-management-shared-styles';
</style>
