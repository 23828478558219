var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "domain-admin-auth-code" },
    [
      _c("h4", [
        _c("span", [
          _vm._v("Domain Auth Code"),
          _vm.subtitle
            ? _c("span", { staticClass: "subtitle" }, [
                _vm._v(" " + _vm._s(_vm.subtitle)),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary", disabled: _vm.fetchingAuthCode },
                  on: { click: _vm.showConfirmationModal },
                },
                [_vm._v("\n        Generate Domain Auth Code\n      ")]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "5" } },
            [
              _c(
                "b-input-group",
                { attrs: { size: "small" } },
                [
                  _c("b-form-input", {
                    staticClass: "auth-code-form",
                    attrs: {
                      l: "",
                      type: _vm.showAuthCode ? "text" : "password",
                      readonly: "",
                    },
                    model: {
                      value: _vm.domain.authCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.domain, "authCode", $$v)
                      },
                      expression: "domain.authCode",
                    },
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c("fa-icon", {
                        staticClass: "mt-3 ml-2",
                        attrs: {
                          icon: _vm.showAuthCode ? "eye" : "eye-slash",
                          type: "button",
                        },
                        on: { click: _vm.toggleShowAuthCode },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.fetchingAuthCode
        ? _c("ct-centered-spinner", [
            _c("p", { staticClass: "centered-spinner-text" }, [
              _vm._v("\n      Generating auth code...\n    "),
            ]),
          ])
        : _vm._e(),
      _c("auth-code-modal", {
        on: {
          ok: _vm.generateDomainAuthCode,
          cancel: _vm.hideConfirmationModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }