var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "auth-code-modal",
      attrs: {
        id: "auth-code-modal",
        title: "Generate Auth Code",
        centered: "",
        "hide-header-close": "",
        size: "md",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "default", "aria-label": "cancel button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary", "aria-label": "ok button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("ok")
                    },
                  },
                },
                [_vm._v("\n      Generate Auth Code\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_c("div", { domProps: { innerHTML: _vm._s(_vm.modalText) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }