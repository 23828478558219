<template>
  <component :is="tagType" class="step" :class="{ active: isActive, blocking: isBlocking }" v-bind="tagAttributes">
    <div class="icon">
      <b-img :src="`images/${icon}.svg`" />
    </div>
    <span class="title">{{ title }}</span>
    <div class="details" :class="{ inSecondHalf: isInSecondHalf }">
      <h5 class="quip">{{ quip }}</h5>
      <h4 class="blurb">{{ blurb }}</h4>
      <p class="description">{{ description }}</p>
    </div>
  </component>
</template>

<script>
export default {
  name: "FilingTrackerStep",
  props: {
    title: String,
    icon: String,
    quip: String,
    blurb: String,
    description: String,
    to: Object,
    isActive: Boolean,
    isBlocking: Boolean,
    isInSecondHalf: Boolean,
  },
  computed: {
    tagType() {
      return this.to && this.isActive ? 'router-link' : 'div'
    },
    tagAttributes() {
      return this.tagType === 'router-link' ? { to: this.to } : {}
    },
  },
}
</script>

<style scoped lang="scss">
// variables
$border-color: darken(#283d96, 10%);
$active-color: #5dcac6;
$blocking-color: #e85834;
$font-color: #212529;
$muted-font-color: #494949;
$road-color: #e6e6ed;
$road-width: 3px;
$details-arrow-width: 2rem;
$details-arrow-offset: 3rem;
$details-border-width: 3px;
$details-translate-x: calc($details-arrow-offset + $details-border-width + ($details-arrow-width / 2));

// desktop styles
@media (min-width: 736px) {
  .step {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;

    // inactive state
    .details {
      display: none;
    }

    .icon {
      display: none;
    }

    // active state
    &.active {
      .title {
        color: $active-color;
      }

      .icon {
        display: flex;
      }

      &:hover {
        .details {
          display: flex;
        }
      }
    }

    // blocking state
    &.blocking {
      .quip {
        color: $blocking-color
      }
    }

    // active + blocking state
    &.active.blocking {
      .title {
        color: $blocking-color;
      }
    }
  }

  .icon {
    position: absolute;
    top: -4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: 4rem;
    margin-bottom: 1rem;

    img {
      max-height: 4rem;
    }
  }

  .title {
    font-size: 0.875rem;
    font-weight: 600;
    color: $muted-font-color;
  }

  .details {
    position: absolute;
    top: 100%;
    left: 50%;
    display: flex;
    flex-direction: column;
    width: 23rem;
    padding: 2rem;
    background: white;
    border: $details-border-width solid $border-color;
    color: $font-color;
    text-align: left;
    transform: translateX(calc($details-translate-x * -1));
    z-index: 9999;

    &:before {
      content: "";
      position: absolute;
      top: calc((($details-arrow-width / 2) - ($details-border-width / 2)) * -1);
      left: $details-arrow-offset;
      width: $details-arrow-width;
      height: $details-arrow-width;
      background: white;
      box-shadow: $details-border-width $details-border-width 0 0 $border-color;
      transform: rotate(225deg);
      transform-origin: center;
    }

    &.inSecondHalf {
      left: unset;
      right: 50%;
      transform: translateX($details-translate-x);

      &:before {
        left: unset;
        right: $details-arrow-offset;
      }
    }
  }

  .quip {
    font-size: 0.825rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $active-color;
    margin: 0;
  }

  .blurb {
    font-size: 1.75rem;
    font-weight: 400;
    margin: 0.5rem 0 1rem 0;
  }

  .description {
    margin: 0;
    color: #212529;
    font-size: 0.875rem;
    font-weight: 400;
  }
}

// mobile styles
@media (max-width: 735px) {
  .step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 6rem;
    margin-left: 1rem;
    padding-bottom: 2rem;
    padding-left: 3.75rem;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;

    // vertical lines
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 3px;
      background: $road-color;
    }

    &:first-child {
      &::before {
        height: calc(100% - 1rem);
      }
    }

    &:last-child {
      &::before {
        display: none;
      }
    }

    // inactive state
    .details {
      display: none;
    }

    // active state
    &.active {
      .details {
        display: flex;
      }

      .title {
        color: $active-color;
      }

      .icon {
        background: none;
        border: none;

        &:after {
          display: none;
        }

        img {
          display: block;
        }
      }

      // subsequent steps
      & ~ .step {
        .icon {
          background: white;

          &:after {
            display: none;
          }
        }
      }
    }

    // active + blocking state
    &.active.blocking {
      .title {
        color: $blocking-color;
      }
    }
  }

  .icon {
    position: absolute;
    top: 0;
    left: -1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border: $road-width solid $road-color;
    border-radius: 3rem;
    background: $road-color;

    &:after {
      content: "";
      background-image: url("/images/tracker/icon-completed-inactive.svg");
      min-width: 3rem;
      height: 3rem;
    }

    img {
      display: none;
      max-width: 3rem;
      max-height: 3rem;
    }
  }

  .title {
    font-size: 1.25rem;
    font-weight: 700;
    text-align: left;
    color: $road-color;
  }

  .details {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .quip {
    display: none;
  }

  .blurb {
    font-size: 1.5rem;
    font-weight: 400;
    color: #212529;
    margin: 0.5rem 0 1rem 0;
  }

  .description {
    font-size: 0.875rem;
    font-weight: 400;
    color: #212529;
    margin: 0;
  }
}
</style>
