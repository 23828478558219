import store from '@/store'
import stagelineV1Module from '@/store/modules/lazyLoaded/stageline/stagelineV1.store'
import stagelineV2Module from '@/store/modules/lazyLoaded/stageline/stagelineV2.store'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

// Create initializers for your components that don't need to make
// excessive requests over and over

// Put initialize options in the router that requires specific requests
// Session will always be called
const initializeOptions = {
  session: {
    call: async () => {
      await store.dispatch('session/updateAccount')
      const websiteId = store.getters['session/getWebsiteId']
      await store.dispatch('website/getWebsite', {
        id: websiteId,
      })

      await store.dispatch('actionCable/createActionCable')
      await store.dispatch('session/setInitialized', { storeName: 'session', value: true })

      return [
        store.dispatch('jurisdictions/load'),
        store.dispatch('virtualPhones/loadVirtualPhones'),
        store.dispatch('virtualPhones/loadSuspendedPhones'),
        store.dispatch('account/setActiveServicesCount'),
        store.dispatch('invitations/fetchReceivedInvitationsCount'),
        store.dispatch('virtualPhones/setVirtualPhonesPendingRemoval'),
        store.dispatch('virtualPhones/fetchPhoneNotificationCount'),
        store.dispatch('verifyOrder/loadLatestOrderRequiringVerification', {}),
        store.dispatch('monitoring/fetchMonitoringService', store.getters['account/account']['id']),
        store.dispatch('paymentMethods/refreshPaymentMethods'),
        store.dispatch('dashpanel/setIsEntityTypeDomains'),
      ]
    },
  },
  monitoringSession: {
    call: async () => {
      await store.dispatch('monitoring/storeSessionToken')
      return []
    },
  },
  companies: {
    call: async () => {
      await store.dispatch('session/setInitialized', { storeName: 'companies', value: true })
      return [
        store.dispatch('companies/loadMore'),
      ]
    },
  },
  stagelineCompanies: {
    call: async () => {
      await store.dispatch('session/setInitialized', { storeName: 'companies', value: true })
      return [
        store.dispatch('stageline/getAccountCompanies'),
      ]
    },
  },
}

export const initialize = async (stores = []) => {
  const promises = []
  stores = ['session', ...stores]
  for(const s of stores) {
    if (store.getters['session/isInitialized'](s)) continue
    promises.push(...await initializeOptions[s].call())
  }

  await Promise.all(promises)
}

// Can't call this in beforeEach due to the current route not being defined yet
export const registerStagelineModules = (version) => {
  switch (version) {
    case 2:
      store.registerModule('stageline', stagelineV2Module)
      break
    default:
      store.registerModule('stageline', stagelineV1Module)
  }
}

// Redirect user to something that is requiring attention on initial load
// VTO, ORA, redirect in company config, etc
export const redirect = async (website, isRegularAccount, next) => {
  // Redirect only once
  if (!store.getters['session/isInitialized']('redirect')) {
    await store.dispatch('session/setInitialized', { storeName: 'redirect', value: true })

    if (!store.getters['verifyOrder/ordersRequiringVerificationLoaded']) {
      await store.dispatch('verifyOrder/loadLatestOrderRequiringVerification', {})
    }

    if (!store.getters['account/redirectLoaded']) {
      await store.dispatch('account/loadRedirects')
    }
    const redirect = store.getters['account/redirect']?.path

    if (redirect) {
      if (!sessionStorage.getItem('admin-logged-in')) {
        await store.dispatch('account/updateCompanyRedirect', null)
      }
      next(redirect)
    } else if (
      !store.getters['verifyOrder/redirected'] &&
      store.getters['verifyOrder/orderRequiringVerification']
    ) {
      store.commit('verifyOrder/set_verify_order_redirected', true)
      next({ name: 'verify-order' })
    } else if (
      website.stageline_enabled &&
      !store.getters['account/stagelineRedirected'] &&
      store.getters['account/companyRequiringStagelineRedirect']
    ) {
      store.commit('account/set_stageline_redirected', true)
      next({ name: 'stageline-v2-start', params: { companyId: store.getters['account/companyRequiringStagelineRedirect'] } })
    } else {
      return false
    }
    return true
  }

  return false
}

export const logRouterTransition = async (to, from) => {
  const account = store.getters['account/account']
  const noCompanies = !store.getters['stageline/accountCompanies'].length

  if (!account) return

  if ([from?.name, to?.name].includes('stageline-v2-landing-page') && noCompanies) {
    await finalizeInteractionLog(
      'stageline-zero-companies',
      'stageline-v2-landing-page',
      'stageline-v2-landing-page',
      to,
      from
    )
  }
  else if (from?.name && [from?.name, to?.name].includes('orderItemsRequiringAttention')) {
    await finalizeInteractionLog(
      'order-requiring-attention',
      'orderRequiringAttention',
      'order-requiring-attention-page',
      to,
      from
    )
  }
}

const finalizeInteractionLog = async (name, destinationName, subCategory, to, from) => {
  const adminAccount = sessionStorage.getItem('admin-logged-in')

  const interaction = {
    action: to?.name === destinationName ? 'view' : 'navigate',
    adminUser: adminAccount,
    name: name,
    to: to?.path,
    from: from?.name || '',
  }

  await createOrFindClientInteractionLog({
    category: 'page-interaction',
    subCategory: subCategory,
    interaction: interaction,
    incompleteLogFromToday: true,
  })
}
