const MATOMO_SITE_ID_MAP = {
  'corporatetools.com': 1,
  'louisianaregisteredagent.com': 2,
  'massachusettsregisteredagent.com': 3,
  'marylandresidentagent.com': 4,
  'staging': 5,
  'northwestregisteredagent.com': 7,
  'georgiaregisteredagent.com': 8,
  'registeredagentsinc.com': 9,
  'wyomingagents.com': 10,
  '49dollarmontanaregisteredagent.com': 11,
  'floridaregisteredagent.com': 12,
  'texasregisteredagent.net': 13,
  'delawareregisteredagent.com': 14,
  'wyregisteredagent.net': 15,
  'floridaregisteredagent.net': 16,
  'californiaregisteredagents.net': 17,
  'epik.com': 18,
  'coloradoregisteredagent.com': 19,
  'texasregisteredagents.com': 20,
  'newyorkregisteredagent.com': 21,
  'wyomingregisteredagent.com': 22,
  'nevadaresidentagent.com': 23,
}

export function getMatomoSiteIdMap() {
  return MATOMO_SITE_ID_MAP
}


