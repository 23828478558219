<template>
  <b-container class="dashboard">
    <h2>Trademark Monitoring</h2>
    <div v-if="monitoringServiceActive">
      <monitoring-tab-bar />

      <div class="content">
        <router-view />
      </div>
    </div>
    <div v-else>
      You do not currently have active Monitoring service. Please go to <router-link to="/hire-us"> Hire Us </router-link> to purchase the service
    </div>
  </b-container>
</template>

<script>
import MonitoringTabBar from "@/components/Monitoring/MonitoringTabBar.vue"
import { mapGetters } from "vuex";

export default {
  name: "MonitoringDashboard",
  components: {
    MonitoringTabBar,
  },
    computed: {
    ...mapGetters('monitoring', [
      'monitoringServiceActive',
    ]),
    },
}
</script>

<style lang="scss">
.dashboard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .content {
    background-color: #f4f4f4;
    width: 100%;
    padding-top: 1em;
  }
}
</style>
