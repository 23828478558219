<template>
  <div class="domain-admin-hosting-login">
    <!-- Heading ---------------------------------------------------------------------------------->
    <h4>
      <span>Domain Hosting Login<span v-if="subtitle" class="subtitle"> {{ subtitle }}</span></span>
    </h4>

    <div>
      <b-button
        variant="primary"
        v-b-tooltip.hover="showTooltipText()"
        :disabled="disablePlatformLogin"
        @click="launchHostingPlatform()"
      >
        {{ hostingPlatform }} Login
      </b-button>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DomainAdminHostingLogin',
  components: {

  },
  props: {
    subtitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      wpStatus: null,
      domainUrl: "",
      cPanelUrl: "",
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
      'domainHostingInfo',
    ]),
    hostingPlatform() {
      return this.domainHostingInfo?.platform === 'cpanel' ? 'Cpanel' : 'Pterodactyl'
    },
    disablePlatformLogin() {
      return this.domainHostingInfo === null
    },
  },
  watch: {
    'domain': {
      async handler(value) {
        if (value) {
          this.wpStatus = await this.fetchWpStatus()
          this.domainUrl = `https://www.${value.domain_name}`

          let rawUrl = window.location.href
          const apiCall = `api/v3/client/domains/${value.id}/hosting/login`
          this.cPanelUrl = rawUrl.replace(/#\/.*/, apiCall)
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('domains', [
      'fetchHostingStatus',
      'fetchMagicLink',
    ]),
    async launchHostingPlatform() {
      this.hostingPlatform === 'Cpanel' ? await this.launchCPanel() : await this.launchPterodactyl()
    },
    async launchCPanel(){
      const magicLink = await this.fetchMagicLink(this.cPanelUrl)

      // Attempt to open webmail in a new window, if it fails just redirect the current page instead
      const newWindow = window.open(magicLink, '_blank')

      if(!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        window.location = magicLink
      }
    },
    async launchPterodactyl() {
      const pterodactylLink = "https://panel.businessidentity.llc/auth/login"

      let newWindow = window.open(pterodactylLink, '_blank')

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        window.location = pterodactylLink
      }
    },
    async fetchWpStatus() {
      const result = await this.fetchHostingStatus(this.domain.id.toString())
      const hostingStatus = result.hosting_status

      if (hostingStatus >= 200 && hostingStatus < 300) {
        return {
          status: hostingStatus,
          icon: "check-circle",
          color: "green",
          message: "<p>Domain hosting is functioning normally. Reinstall not allowed without approval.</p>",
        }
      } else if (hostingStatus >= 300 && hostingStatus < 400) {
        return {
          status: hostingStatus,
          icon: "times-circle",
          color: "orange",
          message: "<p>Hosting Redirection:<br>Either the clients account has been suspended or there's a redirection on the WP site.<br>Usually it's a non-issue as long as everything works correctly but reinstalling will fix any issues usually.</p>",
        }
      } else if (hostingStatus >= 400 && hostingStatus < 500) {
        return {
          status: hostingStatus,
          icon: "times-circle",
          color: "red",
          message: "<p>Request Error, hosting is not functioning properly.<br>There is likely an issue with the wordpress setup.<br>Reinstall required.</p>",
        }
      } else {
        return {
          status: hostingStatus,
          icon: "times-circle",
          color: "red",
          message: "<p>Hosting Failure, hosting is not functioning at all and needs to be reset.<br> Reinstall required.</p>",
        }
      }
    },
    showTooltipText() {
      return this.disablePlatformLogin ? 'there account has no hosting' : ''
    },
  },
}
</script>

<style lang="scss" scoped>
  h4 {
    padding: unset;
    margin: unset;
    height: 5.5rem;
    font-weight: 900;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .subtitle {
      font-weight: 200;
    }
  }
</style>
