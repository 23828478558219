<template>
  <b-navbar-nav
    class="nav-feed-dropdown-component"
    type="dark"
    variant="dark"
    style="display: flex; justify-content: center; width: 100%;"
  >
    <div class="nav-icon-label" @click="toggleFeed">
      <div class="feed-items-icon">
        <b-img src="/images/navFeed/rssFeed.svg" />
        <div v-if="feedUnreadCount > 0"
             ref="feed-count"
             class="feed-count"
        >
          {{ feedUnreadCount }}
        </div>
      </div>
    </div>

    <div v-if="isFeedVisible" class="feed-popup">
      <nav-feed />
    </div>
  </b-navbar-nav>
</template>

<script>
import { mapGetters } from 'vuex'
import { setTimeout } from 'timers'
import NavFeed from '@/components/navs/NavFeed'

export default {
  name: 'NavFeedDropdown',

  components: {
    NavFeed,
  },
  data() {
    return {
      unreadFeedNotifications: 0,
      isFeedVisible: false,
    }
  },
  computed: {
    ...mapGetters('feed', ['feedUnreadCount']),
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async animateFeed() {
      this.$refs['feed-count']?.classList?.add('bounce')
      await this.sleep(1000)
      this.$refs['feed-count']?.classList?.remove('bounce')
    },
    toggleFeed() {
      this.isFeedVisible = !this.isFeedVisible
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep(.dropdown-toggle::after) {
  content: none !important;
}

.feed-popup {
  position: fixed;
  top: 5%;
  right: 10%;
  width: auto;
  z-index: 1050;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;

  @media (max-width: 768px) {
    right: 50%;
    transform: translateX(50%);
    padding: 15px;
  }

  @media (max-width: 480px) {
    right: 50%;
    transform: translateX(50%);
    padding: 10px;
  }
}

.nav-feed-dropdown-component {
  margin-right: 0 !important;
  position: relative;
  display: flex;
  justify-content: center; /* Center the dropdown button */
  width: 100%; /* Ensure full width */

  .dropdown-toggle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); /* Center the dropdown button */
  }

  .dropdown-item {
    padding: 0;
  }
  .feed-items-icon {
    position: relative;
    width: 42px;
    top: 1px;
    .feed-icon {
      height: 34px;
      width: 34px;
    }
    .feed-count {
      border: 2px solid $ct-ui-background-2;
      position: absolute;
      left: 12px;
      bottom: 15px;
      font-size: 1em;
      line-height: 1.4em;
      text-align: center;
      font-weight: bolder;
      background: $ct-ui-color-12;
      color: $ct-ui-color-16;
      border-radius: 100px;
      height: 27px;
      padding: 0 7px;
    }
  }
}

.bounce {
  animation: bounce 1s;
}

@keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: scale(1);
  }
  40% {
    transform: scale(2.8);
  }
  60% {
    transform: scale(1.7);
  }
}
</style>
