<template>
  <div>
    <b-card>
      <label>Current Phone Name:</label>
      <div>
        <b-input v-model="phoneName" />
      </div>
    </b-card>
    <b-card class="mt-2">
      <b-form-checkbox
        :checked="forwardType === 'number-only'"
        name="forward-type"
        @change="toggleCallForwarding"
      >
        Enable Call Forwarding
      </b-form-checkbox>
      <div v-if="forwardType === 'number-only'" class="pt-2">
        <span id="call-forward-text" class="pb-2">
          We'll route your inbound calls to this phone number:
        </span>
        <fa-icon v-b-popover.hover.top="toolTip" class="question-circle pl-1 mr-2" icon="question-circle" size="1x" />
        <div class="mt-2">
          <vue-phone-number-input
            v-model="forwardTo"
            class="form-control"
            :only-countries="['US']"
            default-country-code="US"
            :required="true"
            :clearable="true"
            @update="validateNumberOnEvent"
          />
        </div>
      </div>
    </b-card>
    <b-card class="mt-2">
      <b-form-group>
        <label>Message attachment retention period: </label>
        <fa-icon v-b-popover.hover.top="mediaToolTip" class="question-circle pl-1 mr-2" icon="question-circle" size="1x" />
        <b-form-radio-group
          id="media-lifespan"
          v-model="mediaLifespan"
          :options="mediaLifespanOptions"
          :aria-describedby="mediaLifespan"
          name="radio-options-media-duration"
          size="md"
          stacked
        />
      </b-form-group>
    </b-card>
    <b-card class="mt-2">
      Blocked Phone Numbers
      <ct-centered-spinner v-if="removingBlockedNumber" />
      <b-list-group v-else class="mt-2 blocked-number-list">
        <template v-for="number in blockedNumbers">
          <b-list-group-item :key="number.id">
            <div class="d-flex flex-row">
              <fa-icon
                icon="ban"
                class="ml-2 mt-1 mr-2 ban-icon"
                @click="removeIconClicked(number.id)"
              />
              <p v-phone-number="number.blocked_number" />
            </div>
          </b-list-group-item>
        </template>
      </b-list-group>
    </b-card>
    <b-button
      class="phone-name-button btn-primary button-settings"
      @click="saveSettings"
    >
      Save Settings
    </b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formattedNumber, generateFriendlyNumber, formatPhoneToCountryCodeStyle } from '@/common/modules/formatters'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { CALL_FORWARD_TYPES } from '@/common/modules/constants'
import { phoneNumber } from '@/directives/directives'
import CtCenteredSpinner from './../shared/CtCenteredSpinner'

export default {
  name: "VirtualPhonePhoneSettings",
  components: {
    VuePhoneNumberInput,
    CtCenteredSpinner,
  },
  directives: {
    phoneNumber,
  },
  data(){
    return {
      phoneName: '',
      forwardType: '',
      forwardTo: '',
      validNumber: false,
      isDisplayingDestinationField: false,
      mediaLifespan: this.$store.getters["virtualPhones/selectedPhone"].media_lifespan_in_days || 14,
      mediaLifespanOptions: [
        { text: '1 week', value: 7 },
        { text: '2 weeks', value: 14 },
        { text: '4 weeks', value: 28 },
      ],
      ringtone: null,
      removingBlockedNumber: false,
    }
  },

  computed: {
    ...mapGetters('virtualPhones', ['selectedPhone', 'forwardToNumber', 'blockedNumbers']),

    selectedSettings() {
      return {
        name: this.phoneName || this.selectedPhone.name || '',
        forward_type: this.forwardType,
        forward_to_number: this.forwardingDestination,
        media_lifespan_in_days: this.mediaLifespan,
      }
    },

    forwardingDestination() {
      return this.forwardTo !== null
          ? formatPhoneToCountryCodeStyle(this.forwardTo)
          : ""
    },

    doNotForward() {
      return CALL_FORWARD_TYPES.BROWSER_ONLY
    },

    forward() {
      return CALL_FORWARD_TYPES.NUMBER_ONLY
    },

    forwardNumberMatchesServiceNumber() {
      if (!this.forwardTo) {
        return false
      }

      return this.selectedPhone['number'] === this.selectedSettings['forward_to_number'] ||
        (this.selectedSettings['forward_to_number'].length >= 13 &&
          this.selectedPhone['number'].substring(1) === this.selectedSettings['forward_to_number'].substring(2))
    },
  },

  async mounted() {
    await this.loadVirtualPhones()

    // Assign the stored value to our local variable. On submit, we write back to the store.
    this.phoneName = this.selectedPhone.name
    this.forwardTo = this.selectedPhone.forward_to_number
    this.forwardType = this.selectedPhone.forward_type
    this.ringCountSelected = this.selectedPhone.ring_count
    this.ringtone = this.selectedPhone.ringtone
  },

  methods: {
    ...mapActions('virtualPhones', ['setPhoneSettings', 'loadVirtualPhones', 'unblockNumber', 'getBlockedNumbers']),

    formattedNumber: formattedNumber,
    generateFriendlyNumber: generateFriendlyNumber,
    formatPhoneToCountryCodeStyle: formatPhoneToCountryCodeStyle,

    saveSettings() {
      if(this.forwardNumberMatchesServiceNumber) {
        this.validNumber = false
        this.$bvToast.toast('Forward To Number cannot be the same as the service number', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }
      this.setPhoneSettings({ phoneSettings: this.selectedSettings, vm: this })
    },

    validateNumberOnEvent(event) {
      this.validNumber = event.isValid
    },

    async removeIconClicked(id) {
      try {
        this.removingBlockedNumber = true
        await this.unblockNumber(id)

        await this.getBlockedNumbers()
        this.removingBlockedNumber = false
      } catch {
        this.$bvToast.toast('An problem occurred removing blocked number', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        this.removingBlockedNumber = false
      }
    },

    toggleCallForwarding(value) {
      if(value) {
        this.forwardType = 'number-only'
      } else {
        this.forwardType = 'browser-only'
      }
    },

    toolTip() {
      return 'Calls forwarded to this number will have a caller id matching your phone service number.'
    },

    mediaToolTip() {
      return 'Attachments that are both sent and received will be deleted from the system after the given amount of time.'
    },
  },
}
</script>

<style lang="scss" scoped>
.phone-name-button {
  margin-top: 10px;
}

.ban-icon {
  color: $red;
}

.blocked-number-list {
  overflow-y: scroll;
  max-height: 12em;
}


</style>
