var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "line" }),
    _c(
      "div",
      { staticClass: "steps" },
      [
        _vm._l(_vm.processedSteps, function (step, index) {
          return [
            _c("filing-tracker-step", {
              key: step.title,
              attrs: {
                to: step.to,
                icon: step.icon,
                title: step.title,
                quip: step.quip,
                blurb: step.blurb,
                description: step.description,
                "is-active": step.isActive,
                "is-blocking": step.isBlocking,
                "is-in-second-half": index >= _vm.processedSteps.length / 2,
              },
            }),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }