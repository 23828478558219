var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-container", { staticClass: "dashboard" }, [
    _c("h2", [_vm._v("Trademark Monitoring")]),
    _vm.monitoringServiceActive
      ? _c(
          "div",
          [
            _c("monitoring-tab-bar"),
            _c("div", { staticClass: "content" }, [_c("router-view")], 1),
          ],
          1
        )
      : _c(
          "div",
          [
            _vm._v(
              "\n    You do not currently have active Monitoring service. Please go to "
            ),
            _c("router-link", { attrs: { to: "/hire-us" } }, [
              _vm._v(" Hire Us "),
            ]),
            _vm._v(" to purchase the service\n  "),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }