var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "create-whitelisted-ip",
            "hide-footer": "",
            title: "Add Whitelisted Ip",
            size: _vm.size,
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.createWhitelistedIp.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "label",
                    { staticClass: "label-sm", attrs: { for: "ip" } },
                    [_vm._v("\n          IP address\n        ")]
                  ),
                  _c("b-form-input", {
                    attrs: {
                      name: "ip",
                      placeholder: "127.0.0.1",
                      state: _vm.touched ? _vm.ipIsValid : null,
                      "aria-invalid": !_vm.ipIsValid,
                      required: "",
                      trim: "",
                      autofocus: "",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.touched = true
                      },
                    },
                    model: {
                      value: _vm.ip,
                      callback: function ($$v) {
                        _vm.ip = $$v
                      },
                      expression: "ip",
                    },
                  }),
                  _c("b-form-invalid-feedback", [
                    _vm._v(
                      "\n          Please enter a valid ipv4 or ipv6 address\n        "
                    ),
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "label-sm mt-4",
                      attrs: { for: "description" },
                    },
                    [
                      _vm._v(
                        "\n          Give your IP address a description (optional)\n        "
                      ),
                    ]
                  ),
                  _c("b-form-textarea", {
                    staticClass: "mb-4",
                    attrs: {
                      name: "description",
                      placeholder: "Enter a description...",
                      rows: "3",
                      "max-rows": "6",
                      maxlength: "500",
                      trim: "",
                    },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4 submit-btn-width",
                  attrs: {
                    variant: "primary",
                    type: "submit",
                    "aria-label": "Submit button",
                    disabled: _vm.submitLoading,
                  },
                },
                [_vm._v("\n        Submit\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "edit-whitelisted-ip",
            "hide-footer": "",
            title: "Edit Whitelisted Ip",
            size: _vm.size,
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.editWhitelistedIp.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "label",
                    { staticClass: "label-sm", attrs: { for: "ip" } },
                    [_vm._v("\n          IP address\n        ")]
                  ),
                  _c("b-form-input", {
                    staticClass: "mb-4",
                    attrs: {
                      name: "ip",
                      readonly: "",
                      "aria-readonly": "true",
                    },
                    model: {
                      value: _vm.ip,
                      callback: function ($$v) {
                        _vm.ip = $$v
                      },
                      expression: "ip",
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "label-sm", attrs: { for: "description" } },
                    [
                      _vm._v(
                        "\n          Edit description for ip " +
                          _vm._s(_vm.ip) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c("b-form-textarea", {
                    staticClass: "mb-3",
                    attrs: {
                      name: "description",
                      placeholder: "Enter a description...",
                      rows: "3",
                      "max-rows": "6",
                      maxlength: "500",
                      autofocus: "",
                    },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "submit-btn-width",
                  attrs: {
                    variant: "primary",
                    type: "submit",
                    "aria-label": "Save button",
                    disabled: _vm.submitLoading,
                  },
                },
                [_vm._v("\n        Save\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }