<template>
  <b-modal
    id="auth-code-modal"
    ref="auth-code-modal"
    title="Generate Auth Code"
    centered
    hide-header-close
    size="md"
  >
    <div v-html="modalText" />
    <template v-slot:modal-footer>
      <b-button variant="default" aria-label="cancel button" @click="$emit('cancel')">
        Cancel
      </b-button>
      <b-button variant="primary" aria-label="ok button" @click="$emit('ok')">
        Generate Auth Code
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'AuthCodeModal',
  props: {},
  data() {
    return {}
  },
  computed: {
    modalText() {
      return '<p><strong>You are about to generate a new auth code.</strong><br/><br/>This will replace the previous auth code for the domain.</p>'
    },
  },
}
</script>
