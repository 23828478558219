<template>
  <div class="tabbed-page">
    <b-row class="m-0 pt-1">
      <b-col class="pr-0 pl-0 d-flex align-items-center">
        <div class="d-flex w-100">
          <div
            ref="navToggle"
            class="nav-toggle nav-toggle-left py-2 mr-3 my-auto"
            @click="toggleNavMobile"
          >
            <fa-icon class="toggle-button m-auto" icon="bars" size="lg" />
          </div>
          <h1 class="title py-2 pr-2 pl-3 my-auto">API Management</h1>
          <div
            ref="buttonToggle"
            class="nav-toggle d-flex my-auto ml-auto"
            @click="toggleShowButtonsOnMobile"
          >
            <fa-icon class="toggle-button m-auto" icon="ellipsis-v" size="lg" />
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="auto" class="d-flex flex-wrap align-items-center justify-content-end">
        <div
          class="d-flex align-items-center justify-content-end flex-wrap w-auto py-2"
          :class="mobileButtonClass"
        >
          <div class="d-flex w-auto py-2" :class="mobileButtonClass">
            <b-button
              :class="[
                loading ? 'disabled-button-grey' : 'primary-button',
                'ml-1',
                'mr-0'
              ]"
              style="margin-right: 2.5em"
              variant="primary"
              aria-label="Create API Key button"
              :disabled="loading"
              @click="create"
            >
              {{ createText }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr class="m-0">
    <div class="content-body">
      <div class="side-nav" :class="navClass">
        <button
          class="nav-button"
          :class="selectedTab === tabs.key ? 'selected' : null"
          aria-label="API Keys tab button"
          @click="tabChange(tabs.key)"
        >
          <div class="d-flex flex-column align-items-center text-center m-auto pa-2">
            <fa-icon class="mt-1" icon="key" size="lg" />
            <span class="mt-1">API Keys</span>
          </div>
        </button>
        <button
          class="nav-button"
          :class="selectedTab === tabs.ip ? 'selected' : null"
          aria-label="Permitted IPs tab button"
          @click="tabChange(tabs.ip)"
        >
          <div class="d-flex flex-column align-items-center text-center m-auto pa-2">
            <fa-icon class="mt-1" icon="globe" size="lg" />
            <span class="mt-1">Permitted IPs</span>
          </div>
        </button>
      </div>
      <div class="content">
        <div class="scrollable-content">
          <div v-if="!loading">
            <div v-show="selectedTab === tabs.key">
              <account-access-key-list
                :account-access-keys="getAllKeys"
                :create-access-key="createAccessKey"
                :update-access-key="openUpdateAccessKeyModal"
                :destroy-access-key="showDeleteAccessKeyModal"
              />
            </div>
            <div v-show="selectedTab === tabs.ip">
              <account-whitelisted-ips-list
                :whitelisted-ips="getAllIps"
                :create-whitelisted-ip="createWhitelistedIp"
                :update-whitelisted-ip="openUpdateWhitelistedIpModal"
                :destroy-whitelisted-ip="showDeleteIpModal"
              />
            </div>
          </div>
          <ct-centered-spinner v-else />
        </div>
      </div>
    </div>
    <account-access-key-modal :bus="bus" />
    <account-whitelisted-ip-modal :bus="bus" />
    <learn-more-button tabindex="0" @onclick="learnMore" @onKeyDown="learnMore()" />
    <confirmation-modal
      v-for="key in getAllKeys"
      :id="`confirm-delete-key-${key.id}`"
      :key="key.id"
      :title="'Delete API Key?'"
      :ok-button-text="'Delete'"
      :cancel-button-text="'Cancel'"
      @ok="destroyAccessKey(key.id)"
      @cancel="$bvModal.hide(`confirm-delete-key-${key.id}`)"
    >
      <div>This cannot be undone.</div>
    </confirmation-modal>
    <confirmation-modal
      v-for="ip in getAllIps"
      :id="`confirm-delete-ip-${ip.id}`"
      :key="ip.id"
      :title="'Remove IP?'"
      :ok-button-text="'Delete'"
      :cancel-button-text="'Cancel'"
      @ok="destroyIp(ip.id)"
      @cancel="$bvModal.hide(`confirm-delete-ip-${ip.id}`)"
    >
      <div>This cannot be undone.</div>
    </confirmation-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { copyClipboardMixin } from '@/mixins/copyClipboardMixin'

export default {
  components: {
    AccountAccessKeyList: () => import('@/pages/APIManagement/AccountAccessKeysList.vue'),
    AccountAccessKeyModal: () => import('@/pages/APIManagement/AccountAccessKeyModal.vue'),
    AccountWhitelistedIpsList: () => import('@/pages/APIManagement/AccountWhitelistedIpsList.vue'),
    AccountWhitelistedIpModal: () => import('@/pages/APIManagement/AccountWhitelistedIpModal.vue'),
    ConfirmationModal: () => import('@/components/ConfirmationModal.vue'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner.vue'),
    LearnMoreButton: () => import('@/components/shared/LearnMoreButton.vue'),
  },
  mixins: [copyClipboardMixin, makeToastMixin],
  data() {
    return {
      selectedTab: 0,
      loading: true,
      showButtonsOnMobile: false,
      showNavMobile: false,
      bus: new Vue(),
      maxApiKeys: 3,
      maxWhitelistedIps: 5,
      learnMoreURL: 'https://docs.corporatetools.com/',
      tabs: {
        key: 0,
        ip: 1,
      },
    }
  },
  computed: {
    ...mapGetters('accountAccessKey', { getAllKeys: 'getAll' }),
    ...mapGetters('accountWhitelistedIp', { getAllIps: 'getAll' }),
    createText() {
      return this.selectedTab === this.tabs.key ? 'Create API Key' : 'Add IP'
    },
    mobileButtonClass() {
      return this.showButtonsOnMobile ? 'display-buttons' : 'hide-buttons'
    },
    navClass() {
      return this.showNavMobile ? null : 'hidden'
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.fetchAllKeys().catch(() => {
          this.errorToast("Error", "Failed to load api keys")
        }),
        this.fetchAllIps().catch(() => {
          this.errorToast("Error", "Failed to load ips")
        }),
      ])
    } catch (error) {
      this.errorToast('Error', 'Failed to fetch api keys')
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions('accountAccessKey', { fetchAllKeys: 'fetchAll', deleteAccessKey: 'destroy' }),
    ...mapActions('accountWhitelistedIp', { fetchAllIps: 'fetchAll', deleteIp: 'destroy' }),
    create() {
      this.selectedTab === this.tabs.key ? this.createAccessKey() : this.createWhitelistedIp()
    },
    createAccessKey() {
      if (this.getAllKeys?.length >= this.maxApiKeys) {
        this.errorToast(`Error`, `You can only create ${this.maxApiKeys} API keys`)
      } else {
        this.bus.$emit('show-create-account-access-key')
      }
    },
    createWhitelistedIp() {
      if (this.getAllIps?.length >= this.maxWhitelistedIps) {
        this.errorToast(`Error`, `You can only add ${this.maxWhitelistedIps} ips`)
      } else {
        this.bus.$emit('show-create-whitelisted-ip')
      }
    },
    async destroyAccessKey(id) {
      try {
        await this.deleteAccessKey({ id })
      } catch (err) {
        this.errorToast('Error', 'Failed to delete api key')
      }
    },
    async destroyIp(id) {
      try {
        await this.deleteIp({ id })
      } catch (err) {
        this.errorToast('Error', 'Failed to delete ip')
      }
    },
    showDeleteAccessKeyModal(id) {
      this.$bvModal.show(`confirm-delete-key-${id}`)
    },
    showDeleteIpModal(id) {
      this.$bvModal.show(`confirm-delete-ip-${id}`)
    },
    openUpdateAccessKeyModal(key) {
      this.bus.$emit('show-edit-account-access-key', key)
    },
    openUpdateWhitelistedIpModal(whitelistedIp) {
      this.bus.$emit('show-edit-whitelisted-ip', whitelistedIp)
    },
    tabChange(tabNumber) {
      this.selectedTab = tabNumber
    },
    toggleNavMobile() {
      this.showNavMobile = !this.showNavMobile
    },
    toggleShowButtonsOnMobile() {
      this.showButtonsOnMobile = !this.showButtonsOnMobile
    },
    learnMore(event) {
      // Check if Ctrl (Windows/Linux) or Command (Mac) key is held or if middle mouse button is clicked
      const isCtrlOrCmd = event?.ctrlKey || event?.metaKey
      const isMiddleClick = event?.button === 1
      if (isCtrlOrCmd || isMiddleClick) {
        window.open(this.learnMoreURL, '_blank')
      } else {
        window.location.href = this.learnMoreURL
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '/src/pages/APIManagement/api-management-shared-styles';

.tabbed-page {
  min-width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: -20px -15px;
  display: flex;
  flex-direction: column;

  .hover-pointer {
    :hover {
      cursor: pointer;
    }
  }

  .nav-toggle {
    display: none;

    * {
      display: none;
    }
  }

  button {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .title {
    font-size: 2rem;
    font-weight: bold;
  }

  hr {
    border-top: 1px solid #BDBDBD;
    margin: 0;
  }

  .content-body {
    flex: 1;
    display: flex;

    .side-nav {
      background-color: #F8F8F8;
      min-height: 100%;
      min-width: 125px;
      width: 125px;
      margin-bottom: .25rem;

      .nav-button {
        display: flex;
        width: 100%;
        height: 100px;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        outline: none;

        .shared {
          padding: 0 1.5rem;
        }

        &:hover {
          cursor: pointer;
        }

        &.selected {
          background-color: #EAE9FC;
        }
      }

      @media (min-width: 768px) {
        * {
          display: flex !important;
        }
      }
    }

    .scrollable-content {
      flex: 1;
      overflow-y: auto;
      height: 80vh;
      padding: 15px;
    }

    .content {
      min-height: 100%;
      flex: 1;
    }
  }

  @media only screen and (max-width: 767px) {
    .nav-toggle {
      display: flex;
      border: 1px solid #BDBDBD;
      border-radius: 8px 0 0 8px;
      cursor: pointer;
      width: 50px;
      height: 50px;

      * {
        display: block;
      }
    }

    .nav-toggle-left {
      border-radius: 0 8px 8px 0;
    }

    .d-flex {
      flex-direction: row !important;
    }

    .display-buttons {
      margin: 0 auto !important;
    }

    .hide-buttons {
      display: none !important;

      * {
        display: none !important;
      }
    }

    .content-body {
      flex-direction: column;

      .side-nav {
        min-height: 0;
        width: 100%;
        display: flex;
        flex-direction: row;

        .nav-button {
          flex: 1;
          height: 75px;
          padding: 0;

          svg {
            margin-right: 0.5rem;
          }
        }

        &.hidden {
          display: none;

          * {
            display: none;
          }
        }
      }

      .content {
        min-height: 100%;
        flex: 1;
      }
    }
  }
}

</style>
