<template>
  <div class="whois-contact-info">
    <b-form validated @submit.prevent="onSubmit" @reset.prevent="cancel">
      <h4>Contact Information</h4>
      <b-form-group class="mt-4">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Organization" label-for="organization">
              <b-form-input
                id="organization"
                v-model="whois.org"
                :disabled="!editing"
                trim
                required
                :state="isFieldValid(whois.org)"
              />
              <b-form-invalid-feedback>
                Organization is required.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Name" label-for="name">
              <b-form-input
                id="name"
                v-model="whois.name"
                :disabled="!editing"
                trim
                required
                :state="isFieldValid(whois.name)"
              />
              <b-form-invalid-feedback>
                Name is required.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>
      <h4>Address</h4>
      <b-form-group class="mt-4">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Address" label-for="address">
              <b-form-input
                id="address"
                v-model="whois.address"
                :disabled="!editing"
                trim
                required
                :state="isFieldValid(whois.address)"
              />
              <b-form-invalid-feedback>
                Address is required.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="City" label-for="city">
              <b-form-input
                id="city"
                v-model="whois.city"
                :disabled="!editing"
                trim
                required
                :state="isFieldValid(whois.city)"
              />
              <b-form-invalid-feedback>
                City is required.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="State" label-for="state">
              <b-form-input
                id="state"
                v-model="whois.state"
                :disabled="!editing"
                trim
                required
                :state="isFieldValid(whois.state)"
              />
              <b-form-invalid-feedback>
                State is required.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="ZIP" label-for="zip">
              <b-form-input
                id="zip"
                v-model="whois.postal_code"
                :disabled="!editing"
                trim
                required
                :state="isFieldValid(whois.postal_code)"
              />
              <b-form-invalid-feedback>
                ZIP is required.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Country" label-for="country">
              <b-form-input
                id="country"
                v-model="whois.country"
                :disabled="!editing"
                trim
                required
                :state="isFieldValid(whois.country)"
              />
              <b-form-invalid-feedback>
                Country is required.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            <b-form-group label="Country Code" label-for="country-code">
              <b-form-input
                id="country-code"
                v-model="whois.country_code"
                :disabled="!editing"
              />
            </b-form-group>
          </b-col>
          <b-col cols="9">
            <b-form-group label="Phone" label-for="phone">
              <b-form-input
                id="phone"
                v-model="whois.voice"
                :disabled="!editing"
                trim
                required
                :state="isFieldValid(whois.voice)"
              />
              <b-form-invalid-feedback>
                Phone is required.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Email Address" label-for="email">
              <b-form-input
                id="email"
                v-model="whois.email"
                :disabled="!editing"
                trim
                required
                :state="isFieldValid(whois.email)"
              />
              <b-form-invalid-feedback>
                Email is required.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
<!--        <b-row class="mt-2">
          <b-col cols="12">
            <b-form-group>
              <b-checkbox v-model="shouldUseForAll" inline :disabled="domainPrivacy">
                Use for all contact types
              </b-checkbox>
            </b-form-group>
          </b-col>
        </b-row>-->
        <b-row v-if="editing">
          <b-col cols="3">
            <b-button type="submit" variant="primary">Save</b-button>
          </b-col>
          <b-col cols="3">
            <b-button type="reset" variant="outline-danger">Cancel</b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
    <div v-if="!editing">
      <b-button variant="primary" :disabled="disableEdit" @click="edit()">Edit</b-button>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'WhoisContactInfo',
  components: {
  },
  mixins: [
    makeToastMixin,
  ],
  props: {
    whoisKey: null,
  },
  data: function() {
    return {
      checkbox: [],
      whois: {},
      editing: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
      'domainWhois',
      'isCpanelHostingAccount',
      'isPterodactylHostingAccount',
    ]),
    disableEdit() {
      return this.isCpanelHostingAccount || (!this.isPterodactylHostingAccount && this.domain.created_at < '2024-12-18 11:50:54')
    },
  },
  watch: {
    // Update local component state if vuex state changes.
    domainWhois(newVal) {
      this.whois = JSON.parse(JSON.stringify(newVal[this.whoisKey]))
    },
  },
  mounted: function() {
    // Use JSON parse/stringify to deep clone.
    this.whois = JSON.parse(JSON.stringify(this.domainWhois[this.whoisKey]))
  },
  methods: {
    ...mapActions('domains', [
      'setWhois',
    ]),
    async onSubmit() {
      try {
        await this.setWhois({
          id: this.domain.id,
          newWhois: this.whois,
          type: this.whoisKey,
        })
        this.editing = false
        this.successToast('Success', 'Contact information updated')
      } catch (e) {
        this.cancel()
        this.errorToast('Error', 'Failed to update contact information')
      }
    },
    cancel() {
      this.whois = JSON.parse(JSON.stringify(this.domainWhois[this.whoisKey]))
      this.editing = false
    },
    edit() {
      this.editing = true
    },
    isFieldValid(field) {
      return field && field.length > 1
    },
  },
}
</script>

<style lang="scss" scoped>
.whois-contact-info {
  max-width: 524px;
  .row,
  .form-group {
    margin-bottom: 8px;
  }
  ::v-deep .body-text label {
    font-size: 1rem !important;
  }
}
</style>

<style lang="scss">
.vue-country-select {
  .dropdown {
    min-height: 44px;
    padding: .375rem .75rem !important;
    border-color: rgb(206, 212, 218);
  }
}
</style>
